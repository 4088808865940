import { call, put } from 'redux-saga/effects';

import { Creators as AuthCreators } from '../ducks/auth';
import { Creators as AccessGroupsCreators } from '../ducks/accessGroups';
import { Creators as ApplicationsCreators } from '../ducks/applications';
import { Creators as BranchesCreators } from '../ducks/branches';
import { Creators as CompaniesCreators } from '../ducks/companies';
import { Creators as ContractsCreators } from '../ducks/contracts';
import { Creators as PermissionsCreators } from '../ducks/permissions';
import { Creators as UsersCreators } from '../ducks/users';

import api from '~/services/api';
import errorHandler from '~/utils/errorHandler';
import {
  validateAuthFirstSignIn,
  validateAuthForgotPassword,
} from '~/validators/auth';
import { toastWarning, toastSuccess } from '~/utils/Toast';

export function* firstSignIn({ email, password, history }) {
  try {
    yield validateAuthFirstSignIn({ email, password });

    const response = yield call(api.post, 'sessions', {
      email,
      password,
    });

    const { user, accesses } = response.data;
    const companies = [];
    const branches = [];

    accesses.forEach(application =>
      application.companies.forEach(company => {
        company.branches.forEach(branch => {
          const auxBranch = branch;
          delete auxBranch.permission;
          delete auxBranch.roles;
          branches.push(auxBranch);
        });

        const auxCompany = company;
        delete auxCompany.branches;
        companies.push(auxCompany);
      })
    );

    const parsedCompanies = [];

    companies.forEach(company => {
      const findedCompany = parsedCompanies.find(
        parsedCompany => parsedCompany.company_key === company.company_key
      );

      if (!findedCompany) parsedCompanies.push(company);
    });

    const parsedBranches = [];

    branches.forEach(branch => {
      const findedBranch = parsedBranches.find(
        parsedBranch => parsedBranch.branch_key === branch.branch_key
      );

      if (!findedBranch) parsedBranches.push(branch);
    });

    yield put(
      AuthCreators.firstSignInSuccess(
        user,
        email,
        password,
        parsedCompanies.sort((res, nextRes) => {
          if (res.name < nextRes.name) {
            return -1;
          }
          if (res.name > nextRes.name) {
            return 1;
          }
          return 0;
        }),
        parsedBranches.sort((res, nextRes) => {
          if (res.last_name < nextRes.last_name) {
            return -1;
          }
          if (res.last_name > nextRes.last_name) {
            return 1;
          }
          return 0;
        })
      )
    );

    history.push('/company');
  } catch (err) {
    const errors = errorHandler(err, false);

    yield put(AuthCreators.firstSignInFailure(errors));
  }
}

export function* signIn({ email, password, branchKey, history }) {
  try {
    const response = yield call(api.post, 'sessions', {
      email,
      password,
      branch_key: branchKey,
    });

    const { user, token, logged_branch: loggedBranch } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token.token}`;

    yield put(AuthCreators.signInSuccess(token, loggedBranch, user));

    history.push('/dashboard');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(AuthCreators.signInFailure(errors));
  }
}

export function* signOut() {
  try {
    yield (api.defaults.headers.Authorization = null);

    yield put(AccessGroupsCreators.accessGroupsClear(true));
    yield put(ApplicationsCreators.applicationsClear(true));
    yield put(BranchesCreators.branchesClear(true));
    yield put(CompaniesCreators.companiesClear(true));
    yield put(ContractsCreators.contractsClear(true));
    yield put(PermissionsCreators.permissionsClear(true));
    yield put(UsersCreators.usersClear(true));
  } catch (err) {
    toastWarning(err.message);
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (Object.keys(token).length) {
    api.defaults.headers.Authorization = `Bearer ${token.token}`;
  }
}

export function* changeCompany({ branchKey, history }) {
  try {
    const response = yield call(api.post, 'change-branch', {
      branch_key: branchKey,
    });

    const { token, logged_branch: loggedBranch } = response.data;
    api.defaults.headers.Authorization = `Bearer ${token.token}`;

    yield put(AuthCreators.changeCompanySuccess(token, loggedBranch));

    history.push('/dashboard');
  } catch (err) {
    errorHandler(err, true);

    yield put(AuthCreators.changeCompanyFailure());
  }
}

export function* forgotPassword({ email, history }) {
  try {
    yield validateAuthForgotPassword({ email });

    yield call(api.post, 'passwords', {
      email,
    });

    yield put(AuthCreators.authForgotPasswordSuccess());

    toastSuccess('Nova senha enviada por E-mail.');

    history.push('/');
  } catch (err) {
    const errors = errorHandler(err, false);

    yield put(AuthCreators.authFailure(errors));
  }
}
