import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';

import { FiAlertCircle } from 'react-icons/fi';

import { Creators as AccessGroupsCreators } from '~/store/ducks/accessGroups';

import Header from '~/components/Header';
import ButtonIn from '~/components/ButtonIn';
import Input from '~/components/Input';

import Permissions from './Permissions';
import Branches from './Branches';
import Users from './Users';
import Roles from './Roles';

import {
  Container,
  Content,
  Tabs,
  Tab,
  Error,
  Data,
  FirstLine,
  InputContent,
  ButtonContent,
} from './styles';

const AccessGroups = () => {
  const accessGroup = useSelector(
    state => state.accessGroups.accessGroup.access_group
  );
  const errAccessGroups = useSelector(
    state => state.accessGroups.errAccessGroups
  );
  const actionButton = useSelector(state => state.accessGroups.actionButton);
  const contract = useSelector(state => state.contracts.contract);

  const [tab, setTab] = useState('1');
  const [permission_key, setPermission_key] = useState({});
  const [branch_key, setBranch_key] = useState({});
  const [user_key, setUser_key] = useState({});
  const [role_key, setRole_key] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);

  function selectedPermissions(nameKey) {
    return Object.keys(nameKey).filter(key => nameKey[key] === true);
  }

  function handleUpdateAccessGroup(data) {
    const { name, description } = data;
    const permission_keys = selectedPermissions(permission_key);
    const branch_keys = selectedPermissions(branch_key);
    const user_keys = selectedPermissions(user_key);
    const role_keys = selectedPermissions(role_key);

    const updateAccessGroup = {
      name,
      description,
      permission_keys,
      branch_keys,
      user_keys,
      role_keys,
    };

    dispatch(
      AccessGroupsCreators.accessGroupsUpdateRequest(
        updateAccessGroup,
        accessGroup.uuid,
        history
      )
    );
  }

  function handleSubmit(data) {
    const { name, description } = data;
    const { contract_key } = contract;

    const permission_keys = selectedPermissions(permission_key);
    const branch_keys = selectedPermissions(branch_key);
    const user_keys = selectedPermissions(user_key);
    const role_keys = selectedPermissions(role_key);

    const newAccessGroup = {
      name,
      description,
      contract_key,
      permission_keys,
      branch_keys,
      user_keys,
      role_keys,
    };

    dispatch(
      AccessGroupsCreators.accessGroupsCreateRequest(newAccessGroup, history)
    );
  }

  useEffect(() => {
    if (errAccessGroups) {
      formRef.current.setErrors(errAccessGroups);
    }
  }, [errAccessGroups]);

  useEffect(() => {
    if (accessGroup) {
      formRef.current.setData(accessGroup);
    }

    if (accessGroup && accessGroup.permission_access_groups) {
      const newPermission = {};
      accessGroup.permission_access_groups.forEach(permission => {
        newPermission[permission.permission_key] = true;
      });

      setPermission_key(newPermission);
    }

    if (accessGroup && accessGroup.branch_access_groups) {
      const newBranch = {};
      accessGroup.branch_access_groups.forEach(branch => {
        newBranch[branch.branch_key] = true;
      });
      setBranch_key(newBranch);
    }

    if (accessGroup && accessGroup.user_access_groups) {
      const newUserGroup = {};
      accessGroup.user_access_groups.forEach(user => {
        newUserGroup[user.user_key] = true;
      });

      setUser_key(newUserGroup);
    }

    if (accessGroup && accessGroup.role_access_groups) {
      const newRoleGroup = {};
      accessGroup.role_access_groups.forEach(role => {
        newRoleGroup[role.role_key] = true;
      });

      setRole_key(newRoleGroup);
    }
  }, [accessGroup]);

  useEffect(() => {
    if (!contract.uuid) history.push('/contracts');
  }, [contract, history]);

  return (
    <>
      <Header />
      <Container>
        <Unform
          ref={formRef}
          onSubmit={
            accessGroup && accessGroup.uuid
              ? handleUpdateAccessGroup
              : handleSubmit
          }
        >
          <Data>
            <h1>
              {accessGroup && accessGroup.uuid ? 'Dados' : 'Cadastro'} do Grupo
              de Acesso
            </h1>
            <FirstLine>
              <InputContent>
                <strong>Nome *</strong>
                <Input
                  name="name"
                  placeholder="Digite o nome"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent>
                <strong>Descrição *</strong>
                <Input
                  name="description"
                  placeholder="Digite uma descrição"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
            </FirstLine>

            <ButtonContent>
              {actionButton === 'toView' ? null : (
                <ButtonIn type="submit">
                  {accessGroup && accessGroup.uuid ? 'Salvar' : 'Cadastrar'}
                </ButtonIn>
              )}
            </ButtonContent>
          </Data>
          <Tabs>
            <nav className="nav-tabs">
              <Tab
                type="button"
                checked={tab === '1'}
                isErrored={!!errAccessGroups?.permission_keys}
                onClick={() => setTab('1')}
              >
                <p>Permissões</p>
                {errAccessGroups && errAccessGroups.permission_keys && (
                  <Error title={errAccessGroups.permission_keys}>
                    <FiAlertCircle size={20} color="#c53030" />
                  </Error>
                )}
              </Tab>
              <Tab
                type="button"
                checked={tab === '2'}
                isErrored={!!errAccessGroups?.role_keys}
                onClick={() => setTab('2')}
              >
                <p>Papéis</p>
                {errAccessGroups && errAccessGroups.role_keys && (
                  <Error title={errAccessGroups.role_keys}>
                    <FiAlertCircle size={20} color="#c53030" />
                  </Error>
                )}
              </Tab>
              <Tab
                type="button"
                checked={tab === '3'}
                isErrored={!!errAccessGroups?.branch_keys}
                onClick={() => setTab('3')}
              >
                <p>Filiais</p>
                {errAccessGroups && errAccessGroups.branch_keys && (
                  <Error title={errAccessGroups.branch_keys}>
                    <FiAlertCircle size={20} color="#c53030" />
                  </Error>
                )}
              </Tab>
              <Tab
                type="button"
                checked={tab === '4'}
                isErrored={!!errAccessGroups?.user_keys}
                onClick={() => setTab('4')}
              >
                <p>Usuários</p>
                {errAccessGroups && errAccessGroups.user_keys && (
                  <Error title={errAccessGroups.user_keys}>
                    <FiAlertCircle size={20} color="#c53030" />
                  </Error>
                )}
              </Tab>
            </nav>
          </Tabs>
          <Content>
            {tab === '1' && (
              <Permissions
                value={permission_key}
                setValue={setPermission_key}
              />
            )}
            {tab === '2' && <Roles value={role_key} setValue={setRole_key} />}
            {tab === '3' && (
              <Branches value={branch_key} setValue={setBranch_key} />
            )}
            {tab === '4' && <Users value={user_key} setValue={setUser_key} />}
          </Content>
        </Unform>
      </Container>
    </>
  );
};

export default AccessGroups;
