import styled from 'styled-components';
import { shade } from 'polished';
import Tooltip from '~/components/Tooltip';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #c9d3dd;

    > div {
      display: flex;
      align-items: flex-start;
      strong {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #92a6bb;
        text-align: start;
      }
    }

    @media (max-width: 950px) {
      div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Data = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  h1 {
    font-size: 22px;
    color: #65ade8;
    text-align: center;
    font-weight: bold;
  }

  .topLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 950px) {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ButtonContent = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  button {
    padding: 15px 30px 15px 30px;
    border: 0;
    font-weight: bold;
    background: #1fcc85;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(-0.2, '#1FCC85')};
    }
  }
`;

export const TitlePermission = styled.div`
  display: flex;
  flex-direction: row;
  strong {
    margin-right: 5px;
  }
`;

export const ContentRadio = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    margin: 5px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    text-align: center;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const InputContent = styled.div`
  width: 100%;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #e2eaf2;
  margin-bottom: 10px;
  margin-left: 5px;

  > button {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
  }

  > div {
    margin-top: 10px;
  }
`;

export const TableContainer = styled.section`
  margin-top: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;

  .table-wrapper {
    overflow-x: hidden;
    height: ${props => (props.isLength ? '230px' : '100%')};

    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: #96d9ff;
      border-radius: 4px;
    }
  }

  table {
    max-width: 100%;
    background: #fff;
    margin: 10px 10px;
    margin-bottom: 20px;

    thead {
      background: #c9d3dd;
    }

    tr {
      margin: 20px;
      border: 1px solid #c9d3dd;
    }

    th {
      color: #92a6bb;
      font-weight: bold;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      padding: 10px 22px;
    }

    td {
      padding: 20px 32px;
      border: 0;
      font-weight: normal;
      text-align: center;
      justify-content: center;

      svg {
        cursor: pointer;
        transition: color 0.2s;
        margin-left: 10px;

        &:hover {
          color: #c53030;
        }
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const UnlinkButton = styled.button`
  background: #c53030;
  padding: 8px 10px;
  border: 0;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.2s;

  :hover {
    background: ${shade(-0.2, '#c53030')};
  }
`;

export const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center auto;
  justify-content: center;
  width: 70%;
  height: 100%;

  @media (max-width: 960px) {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center auto;
    justify-content: center;
    width: 70%;
    height: 100%;
  }
`;
