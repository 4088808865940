import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;

  h1 {
    font-size: 14px;
    text-align: start;
    justify-content: center;
    font-weight: bold;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #c9d3dd;

    > div {
      display: flex;
      align-items: flex-start;
      strong {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #92a6bb;
        text-align: start;
      }
    }

    @media (max-width: 950px) {
      div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  .topLine {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TableContainer = styled.section`
  background: #fff;
  height: 100%;
  margin-top: 30px;
  width: 70%;

  table {
    width: 100%;

    thead {
      background: #c9d3dd;
    }

    tr {
      margin: 20px;
      border: 1px solid #c9d3dd;
    }

    th {
      color: #92a6bb;
      font-weight: bold;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      padding: 10px 22px;
    }

    td {
      padding: 20px 32px;
      border: 0;
      font-weight: normal;
      text-align: center;
      justify-content: center;

      svg {
        cursor: pointer;
        transition: color 0.2s;
        margin-left: 10px;
        width: 100%;
      }

      .iconToView {
        &:hover {
          color: #1f7ecc;
        }
      }

      .iconToEdit {
        &:hover {
          color: #1fcc85;
        }
      }

      .iconDelete {
        &:hover {
          color: #c53030;
        }
      }
    }

    .action {
      width: 160px;
      display: flex;
      flex-direction: row;
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const ButtonContent = styled.button`
  padding: 15px 30px 15px 30px;
  border: 0;
  font-weight: bold;
  background: #1fcc85;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background: ${shade(-0.2, '#1fcc85')};
  }
`;
