import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #e2eaf2;
  border-radius: 4px;

  height: 100%;
  width: 100%;
`;

export const TableContainer = styled.section`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  table {
    max-width: 100%;
    width: 70%;

    background: #fff;

    align-items: center;
    margin-bottom: 20px;

    thead {
      background: #c9d3dd;
    }
    tr {
      margin: 10px;
      border: 1px solid #c9d3dd;
    }

    th {
      color: #92a6bb;
      font-weight: bold;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      padding: 10px;
    }

    td {
      padding: 10px;
      border: 0;
      font-weight: normal;
      text-align: center;
      justify-content: center;
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;
