/* eslint-disable camelcase */
import { call, put } from 'redux-saga/effects';
import { Creators as UsersCreators } from '../ducks/users';
import { Creators as UsersCompaniesCreators } from '../ducks/usersCompanies';
import { Creators as UsersAccessGroupsCreators } from '../ducks/usersAccessGroups';
import { Creators as AuthCreators } from '../ducks/auth';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

import validateUsersCreate from '~/validators/usersCreate';
import validateUsersUpdateDate from '~/validators/usersUpdateDate';
import validateUsersUpdatePassword from '~/validators/usersUpdatePassword';

export function* getAllUsers() {
  try {
    const response = yield call(api.get, 'users');

    const usersList = response.data;
    yield put(UsersCreators.usersGetAllSuccess(usersList));
  } catch (err) {
    errorHandler(err);
    yield put(UsersCreators.usersFailure());
  }
}

export function* createUser({ user, history }) {
  try {
    yield validateUsersCreate(user);

    const response = yield call(api.post, 'users', user);

    const newUser = response.data;
    yield put(UsersCreators.usersCreateSuccess(newUser));
    toastSuccess(`Usuário ${user.name} criado com sucesso.`);

    history.goBack();
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(UsersCreators.usersCreateFailure(errors));
  }
}

export function* deleteUser({ user }) {
  try {
    yield call(api.delete, `users/${user.uuid}`);

    yield put(UsersCreators.usersDeleteSuccess(user));
    toastSuccess(`Usuário ${user.name} deletado.`);
  } catch (err) {
    const errors = errorHandler(err, true);

    yield put(UsersCreators.usersFailure(errors));
  }
}

export function* showUser({ uuid, history }) {
  try {
    const response = yield call(api.get, `users/${uuid}`);
    const { user_access_groups, user_companies, ...user } = response.data;

    yield put(UsersCreators.usersShowSuccess(user));
    yield put(UsersCompaniesCreators.usersCompaniesList(user_companies));
    yield put(
      UsersAccessGroupsCreators.usersAccessGroupsList(user_access_groups)
    );
    if (history) history.push('/users/form');
  } catch (err) {
    errorHandler(err, true);
    yield put(UsersCreators.usersFailure());
  }
}

export function* updateUser({ user, uuid, isPassword }) {
  try {
    if (
      isPassword &&
      user.password !== '' &&
      user.password_confirmation !== ''
    ) {
      yield validateUsersUpdatePassword(user);
      yield call(api.put, `users/${uuid}`, user);
      toastSuccess(`Senha salva com sucesso!`);
    } else {
      const { email, image, name, is_admin } = user;
      yield validateUsersUpdateDate(user);
      const response = yield call(api.put, `users/${uuid}`, {
        email,
        image,
        name,
        is_admin,
      });

      const auxUpdateUser = response.data;
      yield put(AuthCreators.authUpdateUser(auxUpdateUser));
      toastSuccess(`Dados salvo com sucesso!`);
    }
    yield put(UsersCreators.usersUpdateSuccess());
  } catch (err) {
    const errors = errorHandler(err);
    yield put(UsersCreators.usersUpdateFailure(errors));
  }
}
