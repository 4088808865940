import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Radio = ({ value, checked, label, type, onChange, ...rest }) => {
  return (
    <Container>
      <input
        type={type}
        value={value}
        checked={checked}
        onChange={onChange}
        {...rest}
      />
      <p>{label}</p>
    </Container>
  );
};

export default Radio;

Radio.propTypes = {
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

Radio.defaultProps = {
  onChange: () => {},
  checked: false,
  type: '',
};
