/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  applicationsGetAllRequest: [],
  applicationsGetAllSuccess: ['applications'],
  applicationsGetAllFailure: [],
  applicationsCreateRequest: ['application', 'history'],
  applicationsSuccess: [],
  applicationsCreateFailure: ['errAplications'],
  applicationsDeleteRequest: ['application'],
  applicationsDeleteSuccess: ['application'],
  applicationsUpdateRequest: ['application', 'uuid', 'history'],
  applicationsUpdateFailure: ['errAplications'],
  applicationsShowRequest: ['uuid', 'actionButton', 'history'],
  applicationsShowSuccess: ['application', 'actionButton'],
  applicationsClear: ['signOut'],
});

export const INITIAL_STATE = {
  applications: [],
  application: {},
  uuid: null,
  actionButton: null,
  loadingAplications: false,
  errAplications: {},
};

const getAllRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAplications = true;
  });

const getAllSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.applications = action.applications;
    draft.loadingAplications = false;
  });

const getAllFailure = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAplications = false;
  });

const createRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.application = action.application;
    draft.loadingAplications = true;
  });

const AplicationsSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAplications = false;
  });

const createFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errAplications = action.errAplications;
    draft.loadingAplications = false;
  });

const deleteRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.application = action.application;
    draft.loadingAplications = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const deleteApplication = state.applications.find(
      app => app.uuid === action.application.uuid
    );
    const deleteIndex = state.applications.indexOf(deleteApplication);
    draft.applications.splice(deleteIndex, 1);
    draft.loadingAplications = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.actionButton = action.actionButton;
    draft.loadingAplications = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.application = action.application;
    draft.loadingAplications = false;
  });

const updateRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.loadingAplications = true;
  });

const updateFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errAplications = action.errAplications;
    draft.loadingAplications = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.applications = [];
    draft.application = {};
    draft.uuid = null;
    draft.errAplications = {};
    draft.loadingAplications = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.APPLICATIONS_GET_ALL_REQUEST]: getAllRequest,
  [Types.APPLICATIONS_GET_ALL_SUCCESS]: getAllSuccess,
  [Types.APPLICATIONS_GET_ALL_FAILURE]: getAllFailure,
  [Types.APPLICATIONS_CREATE_REQUEST]: createRequest,
  [Types.APPLICATIONS_SUCCESS]: AplicationsSuccess,
  [Types.APPLICATIONS_CREATE_FAILURE]: createFailure,
  [Types.APPLICATIONS_DELETE_REQUEST]: deleteRequest,
  [Types.APPLICATIONS_DELETE_SUCCESS]: deleteSuccess,
  [Types.APPLICATIONS_SHOW_REQUEST]: showRequest,
  [Types.APPLICATIONS_SHOW_SUCCESS]: showSuccess,
  [Types.APPLICATIONS_UPDATE_REQUEST]: updateRequest,
  [Types.APPLICATIONS_UPDATE_FAILURE]: updateFailure,
  [Types.APPLICATIONS_CLEAR]: toClear,
});
