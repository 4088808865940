import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`

  from {
    opacity: 0;
    transform: translateX(0px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }

`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;

  h1 {
    font-size: 14px;
    text-align: start;
    justify-content: center;
    font-weight: bold;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    background: #fff;
    border: 1px solid #c9d3dd;

    > div {
      display: flex;
      align-items: flex-start;
      strong {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #92a6bb;
        text-align: start;
      }
    }

    @media (max-width: 950px) {
      div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Data = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  h1 {
    font-size: 22px;
    color: #65ade8;
    text-align: center;
  }

  @media (max-width: 950px) {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const TitleContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space;
    > p {
      text-align: center;
      margin: 0;
    }
  }
`;

export const ApplicationsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  > div {
    width: 100%;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const CompanyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  > div {
    width: 100%;
  }
`;

export const ButtonContent = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  button {
    padding: 15px 30px 15px 30px;
    border: 0;
    font-weight: bold;
    background: #1fcc85;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.2s;

    ${props =>
      props.disabled &&
      css`
        cursor: no-drop;
        opacity: 0.3;
      `}

    &:hover {
      background: ${shade(-0.2, '#1FCC85')};
    }
  }
`;

export const AccessContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #e2eaf2;
  margin-bottom: 10px;
  margin-right: 5px;

  > button {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
  }

  > div {
    margin-top: 10px;
  }
`;

export const TableContainer = styled.section`
  height: 100%;
  margin-top: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  animation: ${appearFromLeft} 1s;

  .byProduct {
    margin-top: 40px;
    width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #e2eaf2;
    border-radius: 4px;
    padding: 4px;

    > strong {
      font-weight: bold;
      font-size: 18px;
      color: #000;
    }

    @media (max-width: 960px) {
      width: 70%;
    }
  }

  .accessGroup {
    > button {
      margin-bottom: 10px;
    }
  }

  > div {
    > button {
      padding: 5px 10px 5px 10px;
      border: 0;
      font-weight: bold;
      background: #1fcc85;
      color: #fff;
      border-radius: 4px;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(-0.2, '#1FCC85')};
      }
    }
  }

  table {
    width: 800px;
    background: #fff;
    margin-bottom: 20px;

    @media (max-width: 960px) {
      width: 70%;
    }

    thead {
      background: #c9d3dd;
    }

    tr {
      margin: 20px;
      border: 1px solid #c9d3dd;
    }

    th {
      color: #92a6bb;
      font-weight: bold;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      padding: 10px 22px;
    }

    td {
      padding: 20px 32px;
      border: 0;
      font-weight: normal;
      text-align: center;
      justify-content: center;

      @media (max-width: 960px) {
        padding: 10px 22px;
      }

      svg {
        cursor: pointer;
        transition: color 0.2s;
        margin-left: 10px;

        &:hover {
          color: #c53030;
        }
      }
    }
    .action {
      display: flex;
      flex-direction: row;
    }
    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center auto;
  justify-content: center;
  width: 70%;
  height: 100%;

  @media (max-width: 960px) {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center auto;
    justify-content: center;
    width: 70%;
    height: 100%;
  }
`;

export const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center auto;
  justify-content: start;
  width: 100%;
  margin-top: 10px;

  .date {
    width: 33%;
    margin-left: 10px;
  }

  .dataContract {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin-left: 10px;
    margin-top: 10px;

    > p {
      margin: 0;
      text-align: start;
    }
  }

  .dataRadio {
    width: 33%;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      padding: 0;
    }
  }

  input {
    height: 20px;
  }
`;
export const SecondLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  margin-top: 10px;

  .branch {
    margin-left: 10px;
  }

  .dataContract {
    display: flex;
    flex-direction: column;
    width: 33%;

    margin-left: 10px;

    > p {
      margin: 0;
      text-align: start;
    }
  }
`;

export const Error = styled.div`
  > p {
    margin-top: 3px;
    color: #a80505;
    font-size: 12px;
  }
`;
