import { all, takeLeading } from 'redux-saga/effects';

import { Types as AuthTypes } from '../ducks/auth';
import { Types as AplicationsTypes } from '../ducks/applications';
import { Types as permissionsTypes } from '../ducks/permissions';
import { Types as companiesTypes } from '../ducks/companies';
import { Types as branchesTypes } from '../ducks/branches';
import { Types as statesTypes } from '../ducks/states';
import { Types as usersTypes } from '../ducks/users';
import { Types as usersCompaniesTypes } from '../ducks/usersCompanies';
import { Types as contractsTypes } from '../ducks/contracts';
import { Types as accessGroupsTypes } from '../ducks/accessGroups';
import { Types as usersAccessGroupsTypes } from '../ducks/usersAccessGroups';
import { Types as rolesTypes } from '../ducks/roles';
import { Types as rolesAccessGroupsTypes } from '../ducks/rolesAccessGroups';
import { Types as permissionsAccessGroupsTypes } from '../ducks/permissionsAccessGroups';
import { Types as branchesAccessGroupsTypes } from '../ducks/branchesAccessGroups';
import { Types as companiesContractsTypes } from '../ducks/companiesContracts';

import * as authSaga from './auth';
import * as aplicationsSaga from './applications';
import * as permissionsSaga from './permissions';
import * as companiesSaga from './companies';
import * as branchesSaga from './branches';
import * as statesSaga from './states';
import * as usersSaga from './users';
import * as usersCompaniesSaga from './usersCompanies';
import * as contractsSaga from './contracts';
import * as accessGroupsSaga from './accessGroups';
import * as usersAccessGroupsSaga from './usersAccessGroups';
import * as rolesSaga from './roles';
import * as rolesAccessGroupsSaga from './rolesAccessGroups';
import * as permissionsAccessGroupsSaga from './permissionsAccessGroups';
import * as branchesAccessGroupsSaga from './branchesAccessGroups';
import * as companiesContractsSaga from './companiesContracts';

export default function* rootSaga() {
  return yield all([
    takeLeading(AuthTypes.FIRST_SIGN_IN_REQUEST, authSaga.firstSignIn),
    takeLeading(AuthTypes.SIGN_IN_REQUEST, authSaga.signIn),
    takeLeading(AuthTypes.SIGN_OUT, authSaga.signOut),
    takeLeading(AuthTypes.CHANGE_COMPANY_REQUEST, authSaga.changeCompany),
    takeLeading(
      AuthTypes.AUTH_FORGOT_PASSWORD_REQUEST,
      authSaga.forgotPassword
    ),

    takeLeading(
      AplicationsTypes.APPLICATIONS_GET_ALL_REQUEST,
      aplicationsSaga.getAllAplications
    ),
    takeLeading(
      AplicationsTypes.APPLICATIONS_CREATE_REQUEST,
      aplicationsSaga.createAplication
    ),
    takeLeading(
      AplicationsTypes.APPLICATIONS_DELETE_REQUEST,
      aplicationsSaga.deleteAplication
    ),
    takeLeading(
      AplicationsTypes.APPLICATIONS_SHOW_REQUEST,
      aplicationsSaga.showAplication
    ),
    takeLeading(
      AplicationsTypes.APPLICATIONS_UPDATE_REQUEST,
      aplicationsSaga.upDateAplication
    ),

    takeLeading(
      permissionsTypes.PERMISSIONS_CREATE_REQUEST,
      permissionsSaga.create
    ),
    takeLeading(
      permissionsTypes.PERMISSIONS_SHOW_REQUEST,
      permissionsSaga.showById
    ),
    takeLeading(
      permissionsTypes.PERMISSIONS_UPDATE_REQUEST,
      permissionsSaga.update
    ),
    takeLeading(
      permissionsTypes.PERMISSIONS_DELETE_REQUEST,
      permissionsSaga.remove
    ),

    takeLeading(
      companiesTypes.COMPANIES_GET_ALL_REQUEST,
      companiesSaga.getAllCompanies
    ),
    takeLeading(
      companiesTypes.COMPANIES_CREATE_REQUEST,
      companiesSaga.createCompanies
    ),
    takeLeading(
      companiesTypes.COMPANIES_DELETE_REQUEST,
      companiesSaga.deleteCompany
    ),
    takeLeading(
      companiesTypes.COMPANIES_SHOW_REQUEST,
      companiesSaga.showCompany
    ),
    takeLeading(
      companiesTypes.COMPANIES_UPDATE_REQUEST,
      companiesSaga.upDateCompany
    ),

    takeLeading(
      branchesTypes.BRANCHES_CREATE_REQUEST,
      branchesSaga.createBranch
    ),
    takeLeading(
      branchesTypes.BRANCHES_DELETE_REQUEST,
      branchesSaga.deleteBranch
    ),
    takeLeading(branchesTypes.BRANCHES_SHOW_REQUEST, branchesSaga.showBranch),
    takeLeading(
      branchesTypes.BRANCHES_UPDATE_REQUEST,
      branchesSaga.upDateBranch
    ),

    takeLeading(statesTypes.STATES_GET_ALL_REQUEST, statesSaga.getAllStates),
    takeLeading(
      statesTypes.STATES_GET_ALL_CITIES_REQUEST,
      statesSaga.getAllCities
    ),

    takeLeading(usersTypes.USERS_GET_ALL_REQUEST, usersSaga.getAllUsers),
    takeLeading(usersTypes.USERS_CREATE_REQUEST, usersSaga.createUser),
    takeLeading(usersTypes.USERS_DELETE_REQUEST, usersSaga.deleteUser),
    takeLeading(usersTypes.USERS_SHOW_REQUEST, usersSaga.showUser),
    takeLeading(usersTypes.USERS_UPDATE_REQUEST, usersSaga.updateUser),

    takeLeading(
      usersCompaniesTypes.USERS_COMPANIES_CREATE_REQUEST,
      usersCompaniesSaga.createUserCompany
    ),
    takeLeading(
      usersCompaniesTypes.USERS_COMPANIES_DELETE_REQUEST,
      usersCompaniesSaga.deleteUserCompany
    ),

    takeLeading(
      contractsTypes.CONTRACTS_GET_ALL_REQUEST,
      contractsSaga.getAllContracts
    ),
    takeLeading(
      contractsTypes.CONTRACTS_CREATE_REQUEST,
      contractsSaga.createContract
    ),
    takeLeading(
      contractsTypes.CONTRACTS_DELETE_REQUEST,
      contractsSaga.deleteContract
    ),
    takeLeading(
      contractsTypes.CONTRACTS_SHOW_REQUEST,
      contractsSaga.showContract
    ),
    takeLeading(
      contractsTypes.CONTRACTS_UPDATE_REQUEST,
      contractsSaga.updateContract
    ),

    takeLeading(
      accessGroupsTypes.ACCESS_GROUPS_CREATE_REQUEST,
      accessGroupsSaga.create
    ),
    takeLeading(
      accessGroupsTypes.ACCESS_GROUPS_SHOW_REQUEST,
      accessGroupsSaga.show
    ),
    takeLeading(
      accessGroupsTypes.ACCESS_GROUPS_UPDATE_REQUEST,
      accessGroupsSaga.update
    ),
    takeLeading(
      accessGroupsTypes.ACCESS_GROUPS_NEW_REQUEST,
      accessGroupsSaga.getNewAccessGroup
    ),
    takeLeading(
      accessGroupsTypes.ACCESS_GROUPS_DELETE_REQUEST,
      accessGroupsSaga.deleteAccessGroup
    ),

    takeLeading(
      usersAccessGroupsTypes.USERS_ACCESS_GROUPS_DELETE_REQUEST,
      usersAccessGroupsSaga.deleteUser
    ),

    takeLeading(rolesTypes.ROLES_CREATE_REQUEST, rolesSaga.create),
    takeLeading(rolesTypes.ROLES_SHOW_REQUEST, rolesSaga.showById),
    takeLeading(rolesTypes.ROLES_UPDATE_REQUEST, rolesSaga.update),
    takeLeading(rolesTypes.ROLES_DELETE_REQUEST, rolesSaga.remove),

    takeLeading(
      rolesAccessGroupsTypes.ROLES_ACCESS_GROUPS_DELETE_REQUEST,
      rolesAccessGroupsSaga.remove
    ),

    takeLeading(
      permissionsAccessGroupsTypes.PERMISSIONS_ACCESS_GROUPS_DELETE_REQUEST,
      permissionsAccessGroupsSaga.remove
    ),

    takeLeading(
      branchesAccessGroupsTypes.BRANCHES_ACCESS_GROUPS_DELETE_REQUEST,
      branchesAccessGroupsSaga.remove
    ),

    takeLeading(
      companiesContractsTypes.COMPANIES_CONTRACTS_DELETE_REQUEST,
      companiesContractsSaga.remove
    ),

    takeLeading('persist/REHYDRATE', authSaga.setToken),
  ]);
}
