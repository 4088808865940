/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  accessGroupsList: ['accessGroups'],
  accessGroupsCreateRequest: ['accessGroup', 'history'],
  accessGroupsCreateSuccess: ['accessGroup'],
  accessGroupsClear: ['signOut'],
  accessGroupsShowRequest: ['uuid', 'actionButton', 'history'],
  accessGroupsShowSuccess: ['accessGroup'],
  accessGroupsFailure: ['errAccessGroups'],
  accessGroupsUpdateRequest: ['updateAccessGroup', 'uuid', 'history'],
  accessGroupsUpdateSuccess: ['accessGroup'],
  accessGroupsNewRequest: ['uuid', 'history'],
  accessGroupsNewSuccess: ['accessGroup'],
  accessGroupsDeleteRequest: ['accessGroup'],
  accessGroupsDeleteSuccess: ['accessGroup'],
});

export const INITIAL_STATE = {
  accessGroups: [],
  accessGroup: {},
  actionButton: null,
  loadingAccessGroups: false,
  errAccessGroups: {},
};

const accessGroupsList = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.accessGroups = action.accessGroups;
    draft.loadingAccessGroups = false;
  });

const createRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAccessGroups = true;
  });
const createSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.accessGroups = [action.accessGroup, ...draft.accessGroups];
    draft.loadingAccessGroups = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.actionButton = action.actionButton;
    draft.loadingAccessGroups = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.accessGroup = action.accessGroup;
    draft.loadingAccessGroups = false;
  });

const failure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errAccessGroups = action.errAccessGroups;
    draft.loadingAccessGroups = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.accessGroups = [];
    draft.accessGroup = {};
    draft.errAccessGroups = {};
    draft.loadingAccessGroups = false;
  });

const updateRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAccessGroups = true;
  });

const updateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.accessGroups = [
      action.accessGroup,
      ...draft.accessGroups.filter(
        accessGroup => accessGroup.uuid !== action.accessGroup.uuid
      ),
    ];
    draft.loadingAccessGroups = false;
  });

const newRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAccessGroups = true;
  });

const newSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.accessGroup = action.accessGroup;
    draft.loadingAccessGroups = false;
  });

const deleteRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAccessGroups = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const deleteAccessGroup = state.accessGroups.find(
      accessGroup => accessGroup.uuid === action.accessGroup.uuid
    );
    const deleteIndex = state.accessGroups.indexOf(deleteAccessGroup);
    draft.accessGroups.splice(deleteIndex, 1);
    draft.loadingContracts = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACCESS_GROUPS_LIST]: accessGroupsList,
  [Types.ACCESS_GROUPS_CREATE_REQUEST]: createRequest,
  [Types.ACCESS_GROUPS_CREATE_SUCCESS]: createSuccess,
  [Types.ACCESS_GROUPS_CLEAR]: toClear,
  [Types.ACCESS_GROUPS_SHOW_REQUEST]: showRequest,
  [Types.ACCESS_GROUPS_SHOW_SUCCESS]: showSuccess,
  [Types.ACCESS_GROUPS_FAILURE]: failure,
  [Types.ACCESS_GROUPS_UPDATE_REQUEST]: updateRequest,
  [Types.ACCESS_GROUPS_UPDATE_SUCCESS]: updateSuccess,
  [Types.ACCESS_GROUPS_NEW_REQUEST]: newRequest,
  [Types.ACCESS_GROUPS_NEW_SUCCESS]: newSuccess,
  [Types.ACCESS_GROUPS_DELETE_REQUEST]: deleteRequest,
  [Types.ACCESS_GROUPS_DELETE_SUCCESS]: deleteSuccess,
});
