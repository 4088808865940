import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, TableContainer } from './styles';

const Users = ({ value, setValue }) => {
  const accessGroupUsers = useSelector(
    state => state.accessGroups.accessGroup.users
  );
  const actionButton = useSelector(state => state.accessGroups.actionButton);
  const accessGroup = useSelector(state => state.accessGroups.accessGroup);

  function handleAddPermission(e, user) {
    const { checked } = e.target;

    setValue({
      ...value,
      [user.user_key]: checked,
    });
  }

  function handleSelectAll(e) {
    const { checked } = e.target;

    const auxObj = {};
    accessGroupUsers.forEach(auxUser => {
      auxObj[auxUser.user_key] = checked;
    });

    setValue(auxObj);
  }

  return (
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              <th>Nome</th>
              <th>Email</th>
            </tr>
          </thead>
          {accessGroupUsers && accessGroupUsers[0] ? (
            <tbody>
              {accessGroupUsers.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddPermission(e, user)}
                      checked={!!value[user.user_key]}
                      disabled={actionButton === 'toView'}
                    />
                  </td>
                  <td> {user.name}</td>
                  <td>{user.user_key}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {accessGroup.users?.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddPermission(e, user)}
                    />
                  </td>
                  <td> {user.name}</td>
                  <td>{user.user_key}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </TableContainer>
    </Container>
  );
};

export default Users;

Users.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setValue: PropTypes.func,
};

Users.defaultProps = {
  setValue: () => {},
};
