import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form as Unform } from '@unform/web';
import { Link } from 'react-router-dom';

import { MdCameraAlt, MdArrowBack } from 'react-icons/md';
import { Creators as UsersCreators } from '~/store/ducks/users';

import convertImage from '~/utils/convertImage';
import { toastWarning } from '~/utils/Toast';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';

import imageProfile from '~/assets/imageProfile';

import { Container, Content, ButtonContent, Avatar } from './styles';

const Profile = () => {
  const userAuth = useSelector(state => state.auth.user);

  const [image, setImage] = useState(userAuth.image);

  const formRef = useRef(null);
  const dispatch = useDispatch();

  function handleSubmit(data) {
    const { is_admin } = userAuth;
    const updateUser = { ...data, image, is_admin };

    dispatch(UsersCreators.usersUpdateRequest(updateUser, userAuth.uuid));

    formRef.current.setData({
      password: '',
      password_confirmation: '',
    });
  }

  async function handleAvatarChange(e) {
    const base64Data = await convertImage(e.target.files[0]).catch(err =>
      alert(JSON.stringify(err && err.message))
    );

    if (base64Data) {
      const validatorImage = base64Data.startsWith('data:image');
      if (!validatorImage) {
        toastWarning('O formato do arquivo é inválido. Selecione uma imagem.');
      }
    }

    setImage(base64Data || image);
  }

  function onErrorImage(e) {
    e.target.src = imageProfile;
    setImage(imageProfile);
  }

  useEffect(() => {
    if (userAuth.uuid && formRef.current) {
      formRef.current.setData(userAuth);
      setImage(userAuth.image ? userAuth.image : imageProfile);
    }
  }, [dispatch, userAuth]);

  return (
    <>
      <Header />
      <Container>
        <header>
          <div>
            <Link to="/dashboard">
              <MdArrowBack />
            </Link>
          </div>
        </header>
        <Unform ref={formRef} onSubmit={handleSubmit}>
          <Avatar>
            <img src={image} alt="profile" onError={onErrorImage} />
            <label htmlFor="avatar">
              <MdCameraAlt />
              <input
                type="file"
                id="avatar"
                accept="image/png, image/jpeg"
                onChange={handleAvatarChange}
              />
            </label>
          </Avatar>
          <Content>
            <h1>Meu perfil</h1>
          </Content>
          <Content>
            <strong>Nome</strong>
            <Input name="name" />
          </Content>
          <Content>
            <strong>Email</strong>
            <Input name="email" />
          </Content>
          <Content>
            <strong>Nova senha</strong>
            <Input
              name="password"
              type="password"
              placeholder="Digite a nova senha"
            />
          </Content>
          <Content>
            <strong>Confirmar nova senha</strong>
            <Input
              name="password_confirmation"
              type="password"
              placeholder="Confirme a nova senha"
            />
          </Content>
          <ButtonContent>
            <ButtonIn type="submit">Salvar</ButtonIn>
          </ButtonContent>
        </Unform>
      </Container>
    </>
  );
};

export default Profile;
