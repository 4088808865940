import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { AiFillBell } from 'react-icons/ai';
import { BsGearFill, BsHouse } from 'react-icons/bs';

import { Creators as AuthCreators } from '../../store/ducks/auth';

import ImageProfile from '~/assets/imageProfile';

import { Typography } from '../Typography';

import { Container, ButtonSignOut, ButtonCompany } from './styles';

const Header = () => {
  const userAuth = useSelector(state => state.auth.user);

  const [image, setImage] = useState(userAuth.image);

  const history = useHistory();
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(AuthCreators.signOut(history));
  }

  function handleChangeCompany() {
    history.push('/company');
  }

  function onErrorImage(e) {
    e.target.src = ImageProfile;
    setImage(ImageProfile);
  }

  useEffect(() => {
    setImage(userAuth.image || ImageProfile);
  }, [userAuth]);

  return (
    <Container>
      <Navbar expand="lg">
        <Navbar.Brand>C O D E</Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto home">
            <Link to="/">
              <BsHouse className="mr-1" color="white" size={18} />
              Início
            </Link>
          </Nav>
          <Nav>
            <ButtonCompany type="button" onClick={handleChangeCompany}>
              Trocar de Empresa
            </ButtonCompany>

            <ButtonSignOut type="button" onClick={handleSignOut}>
              Sair
            </ButtonSignOut>
          </Nav>

          <Nav className="notification-settings">
            <Link className="pr-2" to="/">
              <AiFillBell color="#FF921B" size={20} />
            </Link>
            {(userAuth.is_admin || userAuth.is_manager) && (
              <Link to="/settings">
                <BsGearFill size={18} />
              </Link>
            )}
          </Nav>

          <Nav className="user-panel">
            <Link to="/profile">
              <Typography size="sm">{userAuth.name}</Typography>
              <Typography className="pt-1" thin size="xs">
                Meu Perfil
              </Typography>
            </Link>
          </Nav>

          <Link to="/profile">
            <Nav className="d-none d-lg-block">
              <Image src={image} alt="profile" onError={onErrorImage} />
            </Nav>
          </Link>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default Header;
