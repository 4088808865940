import * as Yup from 'yup';

export const validateAuthFirstSignIn = async data => {
  const schemaValid = Yup.object().shape({
    email: Yup.string()
      .email('O formato do campo é E-mail.')
      .required('Este campo é obrigatório.'),
    password: Yup.string()
      .min(6, 'A senha deve conter no mínimo 6 caracteres.')
      .required('Este campo é obrigatório.'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
};

export const validateAuthForgotPassword = async data => {
  const schemaValid = Yup.object().shape({
    email: Yup.string()
      .email('O formato do campo é E-mail.')
      .required('Este campo é obrigatório.'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
};
