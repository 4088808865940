import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';

import { MdCameraAlt, MdKeyboardArrowDown } from 'react-icons/md';

import { Creators as CompaniesCreators } from '~/store/ducks/companies';
import { Creators as usersCompaniesCreators } from '~/store/ducks/usersCompanies';
import { Creators as CompaniesContractsCreators } from '~/store/ducks/companiesContracts';

import convertImage from '~/utils/convertImage';
import { toastWarning } from '~/utils/Toast';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';

import imageCompany from '~/assets/imageCompany';

import {
  Container,
  Data,
  BranchsButton,
  Logo,
  InputContent,
  ButtonContent,
  FirstLine,
  Content,
  Card,
  TableContainer,
  UnlinkButton,
} from './styles';

const FormCompany = () => {
  const errCompanies = useSelector(state => state.companies.errCompanies);
  const company = useSelector(state => state.companies.company);
  const actionButton = useSelector(state => state.companies.actionButton);
  const companiesContracts = useSelector(
    state => state.companiesContracts.companiesContracts
  );
  const usersCompanies = useSelector(
    state => state.usersCompanies.userCompanies
  );

  const [image, setImage] = useState('');
  const [cardContract, setCardContract] = useState(false);
  const [cardUsers, setCardUsers] = useState(false);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleUpdate(data) {
    const updateCompany = { ...data, image };
    dispatch(
      CompaniesCreators.companiesUpdateRequest(
        updateCompany,
        company.uuid,
        history
      )
    );
  }

  function handleSubmit(data) {
    const newCompany = { ...data, image };
    dispatch(CompaniesCreators.companiesCreateRequest(newCompany, history));
  }

  async function handleAvatarChange(e) {
    const base64Data = await convertImage(e.target.files[0]).catch(err =>
      alert(JSON.stringify(err && err.message))
    );

    if (base64Data) {
      const validatorImage = base64Data.startsWith('data:image');
      if (!validatorImage) {
        toastWarning('O formato do arquivo é inválido. Selecione uma imagem.');
      }
    }
    setImage(base64Data || image);
  }

  function handleUnlinkContract(contract) {
    dispatch(
      CompaniesContractsCreators.companiesContractsDeleteRequest(contract)
    );
  }

  function handleUnlinkUser(user) {
    dispatch(usersCompaniesCreators.usersCompaniesDeleteRequest(user));
  }

  function onErrorImage(e) {
    e.target.src = imageCompany;
    setImage(imageCompany);
  }

  useEffect(() => {
    if (company.uuid) {
      formRef.current.setData(company);
      setImage(company.image ? company.image : imageCompany);
    } else {
      setImage(imageCompany);
    }
  }, [company]);

  useEffect(() => {
    formRef.current.setErrors({});
    if (errCompanies) {
      formRef.current.setErrors(errCompanies);
    }
  }, [errCompanies]);

  return (
    <>
      <Header />
      <Container>
        {company?.uuid ? (
          <Content>
            <Card>
              <button
                type="button"
                onClick={() => {
                  if (cardContract) {
                    setCardContract(false);
                  } else {
                    setCardContract(true);
                  }
                }}
              >
                <strong>Contratos</strong>

                <MdKeyboardArrowDown size={30} />
              </button>

              {cardContract && (
                <TableContainer isLength={companiesContracts.length > 1}>
                  <>
                    {companiesContracts.length ? (
                      <div className="table-wrapper">
                        <table>
                          <thead>
                            <tr>
                              <th>Contrato</th>
                              <th>expiração</th>
                              <th>Ações</th>
                            </tr>
                          </thead>

                          <tbody>
                            {companiesContracts.map(contract => (
                              <tr key={contract.uuid}>
                                <td>{contract.contract_key}</td>
                                <td>{contract.expires_in.slice(0, 10)}</td>
                                <td>
                                  <UnlinkButton
                                    onClick={() =>
                                      handleUnlinkContract(contract)
                                    }
                                  >
                                    Deletar
                                  </UnlinkButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>A empresa não tem ligação com os contratos.</p>
                    )}
                  </>
                </TableContainer>
              )}
            </Card>
            <Card>
              <button
                type="button"
                onClick={() => {
                  if (cardUsers) {
                    setCardUsers(false);
                  } else {
                    setCardUsers(true);
                  }
                }}
              >
                <strong>Usuários</strong>

                <MdKeyboardArrowDown size={30} />
              </button>

              {cardUsers && (
                <TableContainer>
                  <>
                    {usersCompanies.length ? (
                      <div className="table-wrapper">
                        <table>
                          <thead>
                            <tr>
                              <th>Nome</th>
                              <th>Email</th>
                              <th>Ações</th>
                            </tr>
                          </thead>

                          <tbody>
                            {usersCompanies.map(user => (
                              <tr key={user.uuid}>
                                <td>{user.user.name}</td>
                                <td>{user.user.email}</td>
                                <td>
                                  <UnlinkButton
                                    onClick={() => handleUnlinkUser(user)}
                                  >
                                    Desvincular
                                  </UnlinkButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>Não há contratos cadastrados na empresa.</p>
                    )}
                  </>
                </TableContainer>
              )}
            </Card>
          </Content>
        ) : null}

        <Unform
          ref={formRef}
          onSubmit={company.uuid ? handleUpdate : handleSubmit}
        >
          <Data>
            <div className="topLine">
              <h1>{company.uuid ? 'Dados da ' : 'Cadastro da '}Empresa</h1>
              {company.uuid && (
                <>
                  <BranchsButton
                    type="button"
                    onClick={() => history.push('/branches')}
                  >
                    Listar Filiais
                  </BranchsButton>
                </>
              )}
            </div>
            <FirstLine>
              <Logo>
                <img src={image} alt="company" onError={onErrorImage} />

                {actionButton !== 'toView' && (
                  <label htmlFor="avatar">
                    <MdCameraAlt />

                    <input
                      type="file"
                      id="avatar"
                      accept="image/png, image/jpeg"
                      onChange={handleAvatarChange}
                    />
                  </label>
                )}
              </Logo>
              <div className="companyData">
                <InputContent>
                  <strong>CNPJ *</strong>

                  <Input
                    name="root_register_number"
                    placeholder="Os 8 primeiros digitos do CNPJ"
                    data-ls-module="charCounter"
                    maxLength="8"
                    disabled={actionButton === 'toView'}
                  />
                </InputContent>
                <InputContent>
                  <strong>Nome *</strong>
                  <Input
                    name="name"
                    placeholder="Nome da Empresa"
                    disabled={actionButton === 'toView'}
                  />
                </InputContent>
              </div>
            </FirstLine>
            <ButtonContent>
              {actionButton === 'toView' ? null : (
                <ButtonIn type="submit">
                  {company.uuid ? 'Salvar' : 'Cadastrar'}
                </ButtonIn>
              )}
            </ButtonContent>
          </Data>
        </Unform>
      </Container>
    </>
  );
};
export default FormCompany;
