import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, TableContainer } from './styles';

const Roles = ({ value, setValue }) => {
  const actionButton = useSelector(state => state.accessGroups.actionButton);
  const accessGroup = useSelector(state => state.accessGroups.accessGroup);

  function handleAddRole(e, role) {
    const { checked } = e.target;

    setValue({
      ...value,
      [role.role_key]: checked,
    });
  }

  function handleSelectAll(e) {
    const { checked } = e.target;

    const auxObj = {};
    accessGroup.roles.forEach(role => {
      auxObj[role.role_key] = checked;
    });

    setValue(auxObj);
  }

  return (
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              <th>Chave</th>
              <th>Nome</th>
            </tr>
          </thead>

          {accessGroup && accessGroup.roles[0] ? (
            <tbody>
              {accessGroup.roles?.map(role => (
                <tr key={role.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddRole(e, role)}
                      checked={!!value[role.role_key]}
                      disabled={actionButton === 'toView'}
                    />
                  </td>
                  <td>{role.role_key}</td>
                  <td>{role.name}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {accessGroup.roles?.map(role => (
                <tr key={role.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddRole(e, role)}
                    />
                  </td>
                  <td>{role.role_key}</td>
                  <td>{role.name}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </TableContainer>
    </Container>
  );
};

export default Roles;

Roles.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setValue: PropTypes.func,
};

Roles.defaultProps = {
  setValue: () => {},
};
