/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  branchesAccessGroupsList: ['branchesAccessGroups'],
  branchesAccessGroupsDeleteRequest: ['accessGroup'],
  branchesAccessGroupsDeleteSuccess: ['accessGroup'],
  branchesAccessGroupsFailure: ['accessGroup'],
  branchesAccessGroupsClear: [],
});

export const INITIAL_STATE = {
  branchesAccessGroups: [],
  loadingBranchesAccessGroups: false,
  errBranchesAccessGroup: {},
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.branchesAccessGroups = action.branchesAccessGroups;
    draft.loadingBranchesAccessGroups = false;
  });

const removeRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingBranchesAccessGroups = true;
  });

const removeSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.branchesAccessGroups = [
      ...draft.branchesAccessGroups.filter(
        branchAccessGroup => branchAccessGroup.uuid !== action.accessGroup.uuid
      ),
    ];
    draft.loadingBranchesAccessGroups = false;
  });

const failure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errBranchesAccessGroup = action.errBranchesAccessGroup;
    draft.loadingBranchesAccessGroups = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.branchesAccessGroups = [];
    draft.errBranchesAccessGroup = {};
    draft.loadingBranchesAccessGroups = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BRANCHES_ACCESS_GROUPS_LIST]: list,
  [Types.BRANCHES_ACCESS_GROUPS_DELETE_REQUEST]: removeRequest,
  [Types.BRANCHES_ACCESS_GROUPS_DELETE_SUCCESS]: removeSuccess,
  [Types.BRANCHES_ACCESS_GROUPS_FAILURE]: failure,
  [Types.BRANCHES_ACCESS_GROUPS_CLEAR]: toClear,
});
