import styled from 'styled-components';

export const Container = styled.section`
  .row {
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 90%;
  }

  .col {
    margin-top: 50px;
  }
`;

export const LinkAccess = styled.a`
  width: 100%;
  padding: 10px 5px 10px 5px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #1f7ecc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  text-decoration: none;

  p {
    color: #1f7ecc;
    transition: background-color 0.2s;
    margin: 0;
  }

  :hover {
    background: #1f7ecc;
    p {
      color: #fff;
    }
  }
`;
