import { call, put } from 'redux-saga/effects';
import { Creators as CompaniesContractsCreators } from '../ducks/companiesContracts';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

export function* remove({ contract }) {
  try {
    yield call(api.delete, `contracts/${contract.uuid}`);

    yield put(
      CompaniesContractsCreators.companiesContractsDeleteSuccess(contract)
    );
    toastSuccess('Contrato deletado!');
  } catch (err) {
    errorHandler(err, true);
    yield put(CompaniesContractsCreators.companiesContractsFailure());
  }
}
