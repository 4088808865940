import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';
import { MdModeEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Creators as UsersCreators } from '~/store/ducks/users';
import { Creators as UsersCompaniesCreators } from '~/store/ducks/usersCompanies';
import { Creators as CompaniesCreators } from '~/store/ducks/companies';
import { Creators as UsersAccessGroupssCreators } from '~/store/ducks/usersAccessGroups';

import SmallModal from '~/components/SmallModal';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';
import { DropdownSelect } from '~/components/DropdownSelect';

import Settings from '../Settings';
import Table from '~/components/Table';
import LoadingComponent from '~/components/LoadingComponent';

import {
  UserData,
  EmailContent,
  ButtonLinkContent,
  CompanyContent,
} from './styles';

const Users = () => {
  const userRegisters = useSelector(state => state.users.users);
  const { loadingUsers } = useSelector(state => state.users.users);
  const companiesList = useSelector(state => state.companies.companies);
  const userCompany = useSelector(state => state.usersCompanies.userCompany);
  const errUsersCompanies = useSelector(
    state => state.usersCompanies.errUsersCompanies
  );
  const userAuth = useSelector(state => state.auth.user);
  const loggedBranch = useSelector(state => state.auth.loggedBranch);

  const [pageLoaded, setPageLoaded] = useState(false);
  const [addAccessLink, setAddAccessLink] = useState(false);
  const [company_key, setCompany_key] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);

  function handleDeleteUser(isUser) {
    dispatch(UsersCreators.usersDeleteRequest(isUser));
  }

  function handleEditUser(isUser, { actionButton }) {
    dispatch(
      UsersCreators.usersShowRequest(isUser.uuid, actionButton, history)
    );
  }

  function handleAccessLinkCompany(accessLinkCompanyData) {
    const isUserCompany = { ...accessLinkCompanyData, company_key };
    dispatch(UsersCompaniesCreators.usersCompaniesCreateRequest(isUserCompany));
  }

  function handleGetAllCompanies() {
    if (userAuth.is_admin) {
      dispatch(CompaniesCreators.companiesGetAllRequest({}));
    }
  }
  function handleModalLinkUser() {
    setAddAccessLink(true);

    handleGetAllCompanies();
  }

  const header = [
    {
      key: 'name',
      name: 'Nome',
    },
    {
      key: 'user_key',
      name: 'E-mail',
    },
  ];

  const headerActions = [
    {
      text: 'Criar um novo usuário',
      action: () => {
        history.push('/users/form');
      },
      canSee: true,
    },
    {
      text: 'Vincular usuário à empresa',
      action: () => {
        handleModalLinkUser();
      },
      canSee: true,
    },
  ];

  const bodyActions = [
    {
      key: 'show',
      action: user => {
        return (
          <MdRemoveRedEye
            className="iconToView"
            size={20}
            onClick={() => handleEditUser(user, { actionButton: 'toView' })}
          />
        );
      },
      canSee: true,
    },
    {
      key: 'edit',
      action: user => {
        return (
          <MdModeEdit
            className="iconToEdit"
            size={20}
            onClick={() => handleEditUser(user, { actionButton: 'toEdit' })}
          />
        );
      },
      canSee: userAuth.is_admin,
    },
    {
      key: 'delete',
      action: user => {
        return (
          <MdDelete
            className="iconDelete"
            size={20}
            onClick={() => handleDeleteUser(user)}
          />
        );
      },
      canSee: userAuth.is_admin,
    },
  ];

  const filterBy = [
    {
      key: 'name',
      name: 'Nome',
      type: 'text',
      options: [],
    },
    {
      key: 'user_key',
      name: 'E-mail',
      type: 'text',
      options: [],
    },
  ];

  useEffect(() => {
    dispatch(UsersCreators.usersGetAllRequest());
    dispatch(UsersCreators.usersClear());
    dispatch(UsersAccessGroupssCreators.usersAccessGroupsClear());
  }, [dispatch]);

  useEffect(() => {
    if (userCompany.uuid) {
      setAddAccessLink(false);
    }
    if (!addAccessLink && formRef.current) {
      dispatch(UsersCompaniesCreators.usersCompaniesClear());
    }
  }, [userCompany, addAccessLink, dispatch]);

  useEffect(() => {
    if (errUsersCompanies && formRef.current) {
      formRef.current.setErrors(errUsersCompanies);
    }
  }, [errUsersCompanies]);

  useEffect(() => {
    if (!userAuth.is_admin) {
      setCompany_key(loggedBranch.company_key);
    }
  }, [userAuth, loggedBranch]);

  useEffect(() => {
    if (!pageLoaded && userRegisters.length) {
      setPageLoaded(true);
    }
  }, [pageLoaded, userRegisters]);

  return (
    <Settings>
      {pageLoaded ? (
        <Table
          registers={userRegisters}
          filterBy={filterBy}
          header={header}
          headerActions={headerActions}
          bodyActions={bodyActions}
          loading={loadingUsers}
        />
      ) : (
        <LoadingComponent />
      )}
      <SmallModal
        show={addAccessLink}
        onHide={() => setAddAccessLink(false)}
        title="Ligar a uma empresa"
        body={
          <Unform ref={formRef} onSubmit={handleAccessLinkCompany}>
            <UserData>
              <EmailContent>
                <strong>E-mail *</strong>

                <Input name="email" placeholder="Digite o E-mail." />
              </EmailContent>
              {userAuth.is_admin && (
                <CompanyContent>
                  <strong>Empresa *</strong>

                  <DropdownSelect
                    size="sm"
                    title="Selecione uma Empresa"
                    items={companiesList}
                    action={e => setCompany_key(e.target.value)}
                    valueSelect={company_key}
                    error={errUsersCompanies.company_key}
                    keyName="company_key"
                    valueName="name"
                  />
                </CompanyContent>
              )}

              <ButtonLinkContent>
                <ButtonIn className="buttonLink" type="submit">
                  Vincular
                </ButtonIn>
              </ButtonLinkContent>
            </UserData>
          </Unform>
        }
      />
    </Settings>
  );
};

export default Users;
