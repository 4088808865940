/* eslint-disable no-param-reassign */

import { createActions, createReducer } from 'reduxsauce';

import produce from 'immer';

export const { Types, Creators } = createActions({
  usersGetAllRequest: [],
  usersGetAllSuccess: ['users'],
  usersCreateRequest: ['user', 'history'],
  usersCreateSuccess: ['user'],
  usersSuccess: [],
  usersCreateFailure: ['errUsers'],
  usersDeleteRequest: ['user'],
  usersDeleteSuccess: ['user'],
  usersUpdateRequest: ['user', 'uuid', 'isPassword'],
  usersUpdateSuccess: ['user'],
  usersUpdateFailure: ['errUsers'],
  usersShowRequest: ['uuid', 'actionButton', 'history'],
  usersShowSuccess: ['user'],
  usersFailure: ['errUsers'],
  usersClear: ['signOut'],
});

export const INITIAL_STATE = {
  users: [],
  user: {},
  uuid: null,
  actionButton: null,
  loadingUsers: false,
  errUsers: {},
};

const getAllRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingUsers = true;
  });

const getAllSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.users = action.users;
    draft.loadingUsers = false;
  });

const createRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.user = action.user;
    draft.loadingUsers = true;
  });

const createSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.user = action.user;
    draft.users = [...draft.users, action.user];
    draft.loadingUsers = false;
  });

const UsersSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingUsers = false;
  });

const createFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errUsers = action.errUsers;
    draft.loadingUsers = false;
  });

const deleteRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingUsers = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const deleteUser = state.users.find(ep => ep.uuid === action.user.uuid);
    const deleteIndex = state.users.indexOf(deleteUser);
    draft.users.splice(deleteIndex, 1);
    draft.loadingUsers = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.actionButton = action.actionButton;
    draft.loadingUsers = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.user = action.user;
    draft.loadingUsers = false;
  });

const usersFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errUsers = action.errUsers;
    draft.loadingUsers = false;
  });

const updateRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.loadingUsers = true;
  });

const updateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.users = [
      action.user,
      ...draft.users.filter(user => user.uuid !== action.user.uuid),
    ];

    draft.loadingUsers = false;
  });

const updateFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errUsers = action.errUsers;
    draft.loadingUsers = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.users = [];
    draft.user = {};
    draft.errUsers = {};
    draft.uuid = null;
    draft.actionButton = null;
    draft.loadingUsers = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USERS_GET_ALL_REQUEST]: getAllRequest,
  [Types.USERS_GET_ALL_SUCCESS]: getAllSuccess,
  [Types.USERS_CREATE_REQUEST]: createRequest,
  [Types.USERS_CREATE_SUCCESS]: createSuccess,
  [Types.USERS_SUCCESS]: UsersSuccess,
  [Types.USERS_CREATE_FAILURE]: createFailure,
  [Types.USERS_DELETE_REQUEST]: deleteRequest,
  [Types.USERS_DELETE_SUCCESS]: deleteSuccess,
  [Types.USERS_SHOW_REQUEST]: showRequest,
  [Types.USERS_SHOW_SUCCESS]: showSuccess,
  [Types.USERS_FAILURE]: usersFailure,
  [Types.USERS_UPDATE_REQUEST]: updateRequest,
  [Types.USERS_UPDATE_SUCCESS]: updateSuccess,
  [Types.USERS_UPDATE_FAILURE]: updateFailure,
  [Types.USERS_CLEAR]: toClear,
});
