import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  button {
    padding: 15px 20px 15px 20px;
    border: 0;
    background: #1fcc85;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.2s;
    margin-right: 5px;

    :hover {
      background: ${shade(-0.2, '#1fcc85')};
    }
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-left: 4px;
  width: 200px;
  height: 44px;
  background: white;
  border: 2px solid #e9e9e9;
  border-radius: 4px;
  ${props =>
    props.isFocused &&
    css`
      border-color: #1f7ecc;
    `}

  svg {
    margin-right: 12px;
  }
  input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: 0;
    color: black;
  }
`;

export const TableBody = styled.section`
  background: #fff;
  height: 75%;
  width: 80%;
  overflow: auto;
  margin-top: 12px;
  border-top: 1px solid #c9d3dd;
  border-left: 1px solid #c9d3dd;
  border-right: 1px solid #c9d3dd;

  table {
    width: 100%;

    thead {
      background: #e4e4e4;
      position: sticky;
      top: 0;
    }

    tr {
      border-bottom: 1px solid #c9d3dd;
    }

    th {
      color: #2c2c2c;
      font-weight: 600;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      padding: 10px 22px;
    }

    td {
      padding: 20px 32px;
      border: 0;
      font-weight: normal;
      text-align: center;
      justify-content: center;

      svg {
        cursor: pointer;
        transition: color 0.2s;
        margin-left: 10px;
      }

      .iconToView {
        &:hover {
          color: #1f7ecc;
        }
      }

      .iconToEdit {
        &:hover {
          color: #1fcc85;
        }
      }

      .iconDelete {
        &:hover {
          color: #c53030;
        }
      }
    }

    .action {
      display: flex;
      flex-direction: row;
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
`;

export const TableFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const TablePagination = styled.div`
  background: #fff;
  width: 80%;
  height: 48px;
  border-bottom: 1px solid #c9d3dd;
  border-left: 1px solid #c9d3dd;
  border-right: 1px solid #c9d3dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`;

export const PaginationContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
