/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  companiesGetAllRequest: [],
  companiesGetAllSuccess: ['companies'],
  companiesGetAllFailure: [],
  companiesCreateRequest: ['company', 'history'],
  companiesSuccess: [],
  companiesCreateFailure: ['errCompanies'],
  companiesDeleteRequest: ['company'],
  companiesDeleteSuccess: ['company'],
  companiesUpdateRequest: ['company', 'uuid', 'history'],
  companiesUpdateFailure: ['errCompanies'],
  companiesShowRequest: ['uuid', 'actionButton'],
  companiesShowSuccess: ['company'],
  companiesClear: ['signOut'],
  companiesFailure: [],
});

export const INITIAL_STATE = {
  companies: [],
  company: {},
  uuid: null,
  actionButton: null,
  loadingCompanies: false,
  errCompanies: {},
};

const getAllRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingCompanies = true;
  });

const getAllSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.companies = action.companies;
    draft.loadingCompanies = false;
  });

const getAllFailure = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingCompanies = false;
  });

const createRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.company = action.company;
    draft.loadingCompanies = true;
  });

const companiesSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingCompanies = false;
  });

const createFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errCompanies = action.errCompanies;
    draft.loadingCompanies = false;
  });

const deleteRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.company = action.company;
    draft.loadingCompanies = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const deleteCompany = state.companies.find(
      cp => cp.uuid === action.company.uuid
    );
    const deleteIndex = state.companies.indexOf(deleteCompany);
    draft.companies.splice(deleteIndex, 1);
    draft.loadingCompanies = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.actionButton = action.actionButton;
    draft.loadingCompanies = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.company = action.company;
    draft.loadingCompanies = false;
  });

const updateRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.loadingCompanies = true;
  });

const updateFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errCompanies = action.errCompanies;
    draft.loadingCompanies = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.companies = [];
    draft.company = {};
    draft.uuid = null;
    draft.errCompanies = {};
    draft.loadingCompanies = false;
  });

const companiesFailure = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingCompanies = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.COMPANIES_GET_ALL_REQUEST]: getAllRequest,
  [Types.COMPANIES_GET_ALL_SUCCESS]: getAllSuccess,
  [Types.COMPANIES_GET_ALL_FAILURE]: getAllFailure,
  [Types.COMPANIES_CREATE_REQUEST]: createRequest,
  [Types.COMPANIES_SUCCESS]: companiesSuccess,
  [Types.COMPANIES_CREATE_FAILURE]: createFailure,
  [Types.COMPANIES_DELETE_REQUEST]: deleteRequest,
  [Types.COMPANIES_DELETE_SUCCESS]: deleteSuccess,
  [Types.COMPANIES_SHOW_REQUEST]: showRequest,
  [Types.COMPANIES_SHOW_SUCCESS]: showSuccess,
  [Types.COMPANIES_UPDATE_REQUEST]: updateRequest,
  [Types.COMPANIES_UPDATE_FAILURE]: updateFailure,
  [Types.COMPANIES_CLEAR]: toClear,
  [Types.COMPANIES_FAILURE]: companiesFailure,
});
