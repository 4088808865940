import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '~/components/Header';

import { Container, Main, SideBar, Content } from './styles';

const Settings = ({ ...rest }) => {
  const history = useHistory();

  const userAuth = useSelector(state => state.auth.user);

  return (
    <Container>
      <Header />

      <Main>
        <SideBar>
          <div>
            <h1>Configurações</h1>
          </div>

          <ul>
            {userAuth.is_admin && (
              <button
                type="button"
                onClick={() => history.push('/applications')}
              >
                Aplicações
              </button>
            )}

            {userAuth.is_admin && (
              <button type="button" onClick={() => history.push('/companies')}>
                Empresas
              </button>
            )}

            {(userAuth.is_admin || userAuth.is_manager) && (
              <button type="button" onClick={() => history.push('/users')}>
                Usuários
              </button>
            )}

            <button type="button" onClick={() => history.push('/contracts')}>
              Contratos
            </button>
          </ul>
        </SideBar>

        <Content {...rest} />
      </Main>
    </Container>
  );
};

export default Settings;
