import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {

    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body,
  html,
  #root {
    height: 100%;
    background-color: #f1f4f9;
    -webkit-font-smoothing: antialiased;
  }

  a:hover {
    text-decoration: none;
  }

  body, input, button {
    font: 14px Roboto, sans-serif;
  }

  button {
    cursor: pointer;

    :focus {
      outline-style: none;
    }
  }

`;
