import { call, put } from 'redux-saga/effects';
import { Creators as BranchesAccessGroupsCreators } from '../ducks/branchesAccessGroups';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

export function* remove({ accessGroup }) {
  try {
    yield call(api.delete, `branch-access-groups/${accessGroup.uuid}`);

    yield put(
      BranchesAccessGroupsCreators.branchesAccessGroupsDeleteSuccess(
        accessGroup
      )
    );
    toastSuccess('Filial desvinculada do grupo de accesso!');
  } catch (err) {
    errorHandler(err, true);
    yield put(BranchesAccessGroupsCreators.branchesAccessGroupsFailure());
  }
}
