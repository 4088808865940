import * as Yup from 'yup';

export default async function (data) {
  const schemaValid = Yup.object().shape({
    name: Yup.string().required('Este campo é obrigatório.'),
    email: Yup.string()
      .email('O formato do campo é E-mail')
      .required('Este campo é obrigatório.'),
    company_key: Yup.string().required('Este campo é obrigatório.'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
