import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { MdModeEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Creators as BranchesCreators } from '~/store/ducks/branches';
import { Creators as StatesCreators } from '~/store/ducks/states';

import Settings from '../Settings';

import { Container, Content, TableContainer, ButtonContent } from './styles';

const Branches = () => {
  const branches = useSelector(state => state.branches.branches);
  const companiesRegisters = useSelector(state => state.companies.companies);
  const errBranches = useSelector(state => state.branches.errBranches);
  const companiesAuth = useSelector(state => state.auth.companies);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!companiesRegisters.length) history.push('/companies');
  }, [companiesRegisters, companiesAuth, history]);

  useEffect(() => {
    if (errBranches) {
      dispatch(BranchesCreators.branchesClear());
    }
    dispatch(StatesCreators.statesToClear());
  }, [dispatch]); //eslint-disable-line

  function handleDeleteBranch(branchDelete) {
    dispatch(BranchesCreators.branchesDeleteRequest(branchDelete));
  }

  function handletoEditBranch(branchSelect, { actionButton }) {
    dispatch(
      BranchesCreators.branchesShowRequest(branchSelect.uuid, actionButton)
    );
    history.push(`/branches/form`);
  }

  return (
    <>
      <Settings>
        <Container>
          <Content>
            <ButtonContent>
              <Link to="/branches/form">
                <button type="button">Criar uma nova filial</button>
              </Link>
            </ButtonContent>
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Nome Fantasia</th>
                    <th>CNPJ</th>
                    <th>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  {branches.map(isBranch => (
                    <tr key={isBranch.id}>
                      <td>{isBranch.first_name}</td>
                      <td>{isBranch.last_name}</td>
                      <td>{isBranch.branch_key}</td>
                      <td className="action">
                        <MdRemoveRedEye
                          className="iconView"
                          size={20}
                          onClick={() =>
                            handletoEditBranch(isBranch, {
                              actionButton: 'toView',
                            })
                          }
                        />
                        <MdModeEdit
                          className="iconToEdit"
                          onClick={() =>
                            handletoEditBranch(isBranch, {
                              actionButton: 'toEdit',
                            })
                          }
                          size={20}
                        />
                        <MdDelete
                          className="iconDelete"
                          size={20}
                          onClick={() => handleDeleteBranch(isBranch)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </Content>
        </Container>
      </Settings>
    </>
  );
};

export default Branches;
