import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MdModeEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Creators as ApplicationsCreators } from '~/store/ducks/applications';
import { Creators as PermissionsCreators } from '~/store/ducks/permissions';

import Settings from '../Settings';
import Table from '~/components/Table';
import LoadingComponent from '~/components/LoadingComponent';

const Applications = () => {
  const applicationRegisters = useSelector(
    state => state.applications.applications
  );
  const { loadingAplications } = useSelector(state => state.applications);
  const application = useSelector(state => state.applications.application);
  const permission = useSelector(state => state.permissions.permission);

  const dispatch = useDispatch();
  const history = useHistory();

  const [pageLoaded, setPageLoaded] = useState(false);

  function handleEditApplication(app, { actionButton }) {
    dispatch(
      ApplicationsCreators.applicationsShowRequest(
        app.uuid,
        actionButton,
        history
      )
    );
    history.push(`/applications/form`);
  }

  function handleDeleteAplication(app) {
    dispatch(ApplicationsCreators.applicationsDeleteRequest(app));
  }

  const header = [
    {
      key: 'name',
      name: 'Nome',
    },
    {
      key: 'caption',
      name: 'Subtítulo',
    },
  ];

  const filterBy = [
    {
      key: 'name',
      name: 'Nome',
      type: 'text',
      options: [],
    },
    {
      key: 'caption',
      name: 'Subtítulo',
      type: 'text',
      options: [],
    },
  ];

  const headerActions = [
    {
      text: 'Criar uma nova aplicação',
      action: () => {
        history.push('/applications/form');
      },
      canSee: true,
    },
  ];

  const bodyActions = [
    {
      key: 'show',
      action: company => {
        return (
          <MdRemoveRedEye
            className="iconToView"
            size={20}
            onClick={() =>
              handleEditApplication(company, { actionButton: 'toView' })
            }
          />
        );
      },
      canSee: true,
    },
    {
      key: 'edit',
      action: company => {
        return (
          <MdModeEdit
            className="iconToEdit"
            size={20}
            onClick={() =>
              handleEditApplication(company, { actionButton: 'toEdit' })
            }
          />
        );
      },
      canSee: true,
    },
    {
      key: 'delete',
      action: company => {
        return (
          <MdDelete
            className="iconDelete"
            size={20}
            onClick={() => handleDeleteAplication(company)}
          />
        );
      },
      canSee: true,
    },
  ];

  useEffect(() => {
    dispatch(ApplicationsCreators.applicationsGetAllRequest());
  }, [dispatch]);

  useEffect(() => {
    if (application) dispatch(ApplicationsCreators.applicationsClear());
    if (permission) dispatch(PermissionsCreators.permissionsClear());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!pageLoaded && applicationRegisters.length) {
      setPageLoaded(true);
    }
  }, [pageLoaded, applicationRegisters]);

  return (
    <Settings>
      {pageLoaded ? (
        <Table
          registers={applicationRegisters}
          filterBy={filterBy}
          header={header}
          headerActions={headerActions}
          bodyActions={bodyActions}
          loading={loadingAplications}
        />
      ) : (
        <LoadingComponent />
      )}
    </Settings>
  );
};
export default Applications;
