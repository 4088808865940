/* eslint-disable camelcase */
import { call, put } from 'redux-saga/effects';
import { Creators as UsersCompaniesCreators } from '../ducks/usersCompanies';
import { Creators as UsersCreators } from '../ducks/users';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

import validateUsersCompanies from '~/validators/usersCompanies';

export function* createUserCompany({ userCompany }) {
  try {
    yield validateUsersCompanies(userCompany);

    const response = yield call(api.post, 'user-companies', userCompany);

    const { user, ...linkUserToCompany } = response.data;
    yield put(
      UsersCompaniesCreators.usersCompaniesCreateSuccess(linkUserToCompany)
    );
    yield put(UsersCreators.usersUpdateSuccess(user));
    toastSuccess(
      `Usuário vinculado a empresa ${linkUserToCompany.company_key} .`
    );
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(UsersCompaniesCreators.usersCompaniesCreateFailure(errors));
  }
}

export function* deleteUserCompany({ userCompany }) {
  try {
    const { uuid, company_key } = userCompany;

    yield call(api.delete, `user-companies/${uuid}`);

    yield put(UsersCompaniesCreators.usersCompaniesDeleteSuccess(userCompany));
    toastSuccess(`Usuário desvinculado da empresa ${company_key}.`);
  } catch (err) {
    const errors = errorHandler(err, true);
    yield put(UsersCompaniesCreators.usersCompaniesFailure(errors));
  }
}
