/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  rolesAccessGroupsList: ['rolesAccessGroups'],
  rolesAccessGroupsDeleteRequest: ['accessGroup'],
  rolesAccessGroupsDeleteSuccess: ['accessGroup'],
  rolesAccessGroupsFailure: ['accessGroup'],
  rolesAccessGroupsClear: [],
});

export const INITIAL_STATE = {
  rolesAccessGroups: [],
  loadingRolesAccessGroups: false,
  errRolesAccessGroup: {},
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.rolesAccessGroups = action.rolesAccessGroups;
    draft.loadingRolesAccessGroups = false;
  });

const removeRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingRolesAccessGroups = true;
  });

const removeSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.rolesAccessGroups = [
      ...draft.rolesAccessGroups.filter(
        roleAccessGroup => roleAccessGroup.uuid !== action.accessGroup.uuid
      ),
    ];
    draft.loadingRolesAccessGroups = false;
  });

const failure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errRolesAccessGroup = action.errRolesAccessGroup;
    draft.loadingRolesAccessGroups = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.rolesAccessGroups = [];
    draft.errRolesAccessGroup = {};
    draft.loadingRolesAccessGroups = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ROLES_ACCESS_GROUPS_LIST]: list,
  [Types.ROLES_ACCESS_GROUPS_DELETE_REQUEST]: removeRequest,
  [Types.ROLES_ACCESS_GROUPS_DELETE_SUCCESS]: removeSuccess,
  [Types.ROLES_ACCESS_GROUPS_FAILURE]: failure,
  [Types.ROLES_ACCESS_GROUPS_CLEAR]: toClear,
});
