/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { Creators as UsersCreators } from '~/store/ducks/users';
import { Creators as CompaniesCreators } from '~/store/ducks/companies';
import { Creators as UsersCompaniesCreators } from '~/store/ducks/usersCompanies';
import { Creators as UsersAccessGroupsCreators } from '~/store/ducks/usersAccessGroups';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';
import { DropdownSelect } from '~/components/DropdownSelect';

import {
  Container,
  UserData,
  NameContent,
  EmailContent,
  PasswordContent,
  CompanyContent,
  ButtonContent,
  Content,
  AccessContent,
  CompaniesContent,
  TableContainer,
  UnlinkButton,
} from './styles';

const FormUsers = () => {
  const errUsers = useSelector(state => state.users.errUsers);
  const user = useSelector(state => state.users.user);
  const userCompanies = useSelector(
    state => state.usersCompanies.userCompanies
  );
  const usersAccessGroups = useSelector(
    state => state.usersAccessGroups.usersAccessGroups
  );
  const companiesList = useSelector(state => state.companies.companies);
  const actionButton = useSelector(state => state.users.actionButton);
  const userCompany = useSelector(state => state.usersCompanies.userCompany);
  const userAuth = useSelector(state => state.auth.user);
  const loggedBranch = useSelector(state => state.auth.loggedBranch);

  const [company_key, setCompany_key] = useState('');
  const [accessGroupsList, setAccessGroupList] = useState([]);
  const [accessGroupCard, setAccessGroupCard] = useState(false);
  const [accessCompaniesList, setAccessCompaniesList] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const formRefPassword = useRef(null);

  function handleSubmit(data) {
    const isUser = { ...data, company_key };
    dispatch(UsersCreators.usersCreateRequest(isUser, history));
  }

  function handleUpdate(data, { isPassword }) {
    let isUser = {};
    if (isPassword) {
      const { email, name } = user;
      const auxIsUser = { ...data, email, name };
      isUser = auxIsUser;
    } else {
      const auxIsUser = { ...data, company_key };
      isUser = auxIsUser;
    }
    dispatch(UsersCreators.usersUpdateRequest(isUser, user.uuid, isPassword));
  }

  function handleDeleteLinkCompany(auxUserCompany) {
    dispatch(
      UsersCompaniesCreators.usersCompaniesDeleteRequest(auxUserCompany)
    );
  }

  function handleDeleteLinkAccessGroup(accessGroup) {
    dispatch(
      UsersAccessGroupsCreators.usersAccessGroupsDeleteRequest(accessGroup)
    );
  }

  useEffect(() => {
    if (errUsers && formRef.current) {
      formRef.current.setErrors(errUsers);
    }
    if (errUsers && formRefPassword.current) {
      formRefPassword.current.setErrors(errUsers);
    }
  }, [errUsers]);

  useEffect(() => {
    if (user.uuid && formRef.current) {
      formRef.current.setData(user);
      if (user && user.user_companies && user.user_companies[0]) {
        const companyKey = user.user_companies[0].company_key;
        setCompany_key(companyKey);
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (userCompany.uuid) {
      const filterUserAccGroup = usersAccessGroups.filter(
        userAccessGroup =>
          userAccessGroup.access_group.contract_key.split('.')[1] !==
          userCompany.company_key
      );

      setAccessGroupList(filterUserAccGroup);
    }
  }, [userCompany, usersAccessGroups]);

  useEffect(() => {
    setAccessGroupList(usersAccessGroups);
  }, [usersAccessGroups]);

  useEffect(() => {
    if (!userAuth.is_admin) {
      setCompany_key(loggedBranch.company_key);
    }
  }, [userAuth, loggedBranch, dispatch]);

  useEffect(() => {
    if (userAuth.is_admin) {
      dispatch(CompaniesCreators.companiesGetAllRequest());
    }
  }, [dispatch, userAuth]);

  return (
    <>
      <Header />
      <Container>
        {user.uuid ? (
          <Content>
            <AccessContent>
              <button
                type="button"
                onClick={() => {
                  if (accessGroupCard) {
                    setAccessGroupCard(false);
                  } else {
                    setAccessGroupCard(true);
                  }
                }}
              >
                <strong>Grupos de acessos</strong>

                <MdKeyboardArrowDown size={30} />
              </button>

              {accessGroupCard && (
                <TableContainer isLength={accessGroupsList.length > 1}>
                  <>
                    {accessGroupsList[0] ? (
                      <div className="table-wrapper">
                        <table>
                          <thead>
                            <tr>
                              <th>Contrato</th>
                              <th>Nome</th>
                              <th>Ações</th>
                            </tr>
                          </thead>

                          <tbody>
                            {accessGroupsList.map(accessGroup => (
                              <tr key={accessGroup.uuid}>
                                <td>{accessGroup.access_group.contract_key}</td>
                                <td>{accessGroup.access_group.name}</td>
                                <td>
                                  <UnlinkButton
                                    onClick={() =>
                                      handleDeleteLinkAccessGroup(accessGroup)
                                    }
                                  >
                                    Desvincular
                                  </UnlinkButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>O usuário não tem ligação com grupos de acesso.</p>
                    )}
                  </>
                </TableContainer>
              )}
            </AccessContent>

            <CompaniesContent>
              <button
                type="button"
                onClick={() => {
                  if (accessCompaniesList) {
                    setAccessCompaniesList(false);
                  } else {
                    setAccessCompaniesList(true);
                  }
                }}
              >
                <strong>Empresas</strong>
                <MdKeyboardArrowDown size={30} />
              </button>

              {accessCompaniesList ? (
                <>
                  <TableContainer isLength={userCompanies.length > 1}>
                    {userCompanies.length ? (
                      <div className="table-wrapper">
                        <table>
                          <thead>
                            <tr>
                              <th>Empresa</th>
                              <th>CNPJ</th>
                              <th>Ações</th>
                            </tr>
                          </thead>

                          <tbody>
                            {userCompanies.map(usCompany => (
                              <tr key={usCompany.uuid}>
                                <td>{usCompany.company.name}</td>
                                <td>{usCompany.company.company_key}</td>
                                <td>
                                  <UnlinkButton
                                    type="button"
                                    onClick={() =>
                                      handleDeleteLinkCompany(usCompany)
                                    }
                                  >
                                    Desvincular
                                  </UnlinkButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>O usuário não tem vínculo com as empresas.</p>
                    )}
                  </TableContainer>
                </>
              ) : null}
            </CompaniesContent>
          </Content>
        ) : null}
        <Unform
          ref={formRef}
          onSubmit={user.uuid ? handleUpdate : handleSubmit}
        >
          <UserData>
            <h1>Dados do Usuário</h1>

            <NameContent>
              <strong>Nome *</strong>
              <Input
                name="name"
                placeholder="Digite nome do usuário."
                disabled={actionButton === 'toView'}
              />
            </NameContent>

            <EmailContent>
              <strong>E-mail *</strong>
              <Input
                name="email"
                placeholder="Digite o E-mail."
                disabled={actionButton === 'toView'}
              />
            </EmailContent>

            {userAuth.is_admin && (
              <>
                {actionButton === 'toEdit' ? null : (
                  <CompanyContent>
                    <strong>Empresa *</strong>
                    <DropdownSelect
                      size="sm"
                      title="Selecione uma Empresa"
                      items={companiesList}
                      action={e => setCompany_key(e.target.value)}
                      valueSelect={company_key}
                      error={errUsers.company_key}
                      disabled={actionButton === 'toView'}
                      keyName="company_key"
                      valueName="name"
                    />
                  </CompanyContent>
                )}
              </>
            )}

            <ButtonContent>
              {actionButton === 'toView' ? null : (
                <ButtonIn type="submit">
                  {user.uuid ? 'Salvar' : 'Cadastrar'}
                </ButtonIn>
              )}
            </ButtonContent>
          </UserData>
        </Unform>

        {user.uuid && actionButton === 'toEdit' ? (
          <Unform
            ref={formRefPassword}
            onSubmit={data => handleUpdate(data, { isPassword: true })}
          >
            <UserData>
              <h1>Alterar Senha</h1>
              <PasswordContent>
                <div>
                  <strong>Nova senha</strong>
                  <Input
                    name="password"
                    type="password"
                    placeholder="Digite a senha."
                    disabled={actionButton === 'toView'}
                  />
                </div>

                <div>
                  <strong>Confirmar a nova senha</strong>
                  <Input
                    name="password_confirmation"
                    type="password"
                    placeholder="Digite a senha novamente."
                    disabled={actionButton === 'toView'}
                  />
                </div>
              </PasswordContent>
              <ButtonContent>
                <ButtonIn type="submit"> Salvar</ButtonIn>
              </ButtonContent>
            </UserData>
          </Unform>
        ) : null}
      </Container>
    </>
  );
};

export default FormUsers;
