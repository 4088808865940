import { call, put } from 'redux-saga/effects';

import { Creators as CompaniesCreators } from '../ducks/companies';
import { Creators as BranchesCreators } from '../ducks/branches';
import { Creators as usersCompaniesCreators } from '../ducks/usersCompanies';
import { Creators as CompaniesContractsCreators } from '../ducks/companiesContracts';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';
import validateCompanies from '~/validators/companies';

export function* getAllCompanies() {
  try {
    const response = yield call(api.get, 'companies');
    const companiesList = response.data;

    yield put(CompaniesCreators.companiesGetAllSuccess(companiesList));
  } catch (err) {
    errorHandler(err);
    yield put(CompaniesCreators.applicationsGetAllFailure());
  }
}

export function* createCompanies({ company, history }) {
  try {
    yield validateCompanies(company);

    yield call(api.post, 'companies', company);

    yield put(CompaniesCreators.companiesSuccess());

    toastSuccess(`Empresa ${company.name} criada com sucesso.`);

    history.goBack('/companies');
  } catch (err) {
    const errors = errorHandler(err);
    yield put(CompaniesCreators.companiesCreateFailure(errors));
  }
}

export function* deleteCompany({ company }) {
  try {
    yield call(api.delete, `companies/${company.uuid}`);

    yield put(CompaniesCreators.companiesDeleteSuccess(company));
    toastSuccess(`Empresa ${company.name} deletada.`);
  } catch (err) {
    errorHandler(err, true);
    yield put(CompaniesCreators.companiesFailure());
  }
}

export function* showCompany({ uuid }) {
  try {
    const response = yield call(api.get, `companies/${uuid}`);

    const { contracts, user_companies, branches, ...company } = response.data;

    yield put(CompaniesCreators.companiesShowSuccess(company));
    yield put(BranchesCreators.branchesList(branches));
    yield put(usersCompaniesCreators.usersCompaniesList(user_companies));
    yield put(CompaniesContractsCreators.companiesContractsList(contracts));
  } catch (err) {
    errorHandler(err, true);
    yield put(CompaniesCreators.applicationsGetAllFailure());
  }
}

export function* upDateCompany({ company, uuid, history }) {
  try {
    yield validateCompanies(company);

    yield call(api.put, `companies/${uuid}`, company);

    yield put(CompaniesCreators.companiesSuccess());

    toastSuccess(`Empresa salva com sucesso!`);

    history.goBack('/companies');
  } catch (err) {
    const errors = errorHandler(err);

    yield put(CompaniesCreators.companiesUpdateFailure(errors));
  }
}
