/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';

import {
  MdKeyboardArrowDown,
  MdDelete,
  MdAdd,
  MdModeEdit,
  MdRemoveRedEye,
} from 'react-icons/md';

import { Creators as CompaniesCreators } from '~/store/ducks/companies';
import { Creators as ContractsCreators } from '~/store/ducks/contracts';
import { Creators as ApplicationsCreators } from '~/store/ducks/applications';
import { Creators as AccessGroupsCreators } from '~/store/ducks/accessGroups';
import { Creators as ContractPermissionsCreators } from '~/store/ducks/contractPermissions';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';
import { DropdownSelect } from '~/components/DropdownSelect';
import Radio from '~/components/Radio';
import SmallModal from '~/components/SmallModal';

import {
  Container,
  Data,
  FirstLine,
  InputContent,
  CompanyContent,
  ButtonContent,
  ApplicationsContent,
  Content,
  AccessContent,
  TableContainer,
  SecondLine,
  TitleContent,
  Error,
} from './styles';

const FormContracts = () => {
  const errContracts = useSelector(state => state.contracts.errContracts);
  const contract = useSelector(state => state.contracts.contract);
  const companiesList = useSelector(state => state.companies.companies);
  const branchesList = useSelector(state => state.branches.branches);
  const actionButton = useSelector(state => state.contracts.actionButton);
  const applicationsList = useSelector(
    state => state.applications.applications
  );
  const permissions = useSelector(state => state.permissions.permissions);
  const accessGroups = useSelector(state => state.accessGroups.accessGroups);
  const contractPermissions = useSelector(
    state => state.contractPermissions.contractPermissions
  );
  const errContractPermissions = useSelector(
    state => state.contractPermissions.errContractPermissions
  );
  const userAuth = useSelector(state => state.auth.user);

  const [company_key, setCompany_key] = useState('');
  const [branch_key, setBranch_key] = useState('');
  const [application_key, setApplication_key] = useState('');
  const [status, setStatus] = useState('');
  const [permission_key, setPermission_key] = useState('');
  const [accessGroupList, setAccessGroupList] = useState(false);
  const [addByProduct, setAddByProduct] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);

  function handleSubmit(data) {
    const newContract = { ...data, company_key, branch_key, application_key };
    dispatch(ContractsCreators.contractsCreateRequest(newContract, history));
  }

  function handleUpdate(data) {
    const auxContract = {
      ...data,
      status,
      permission_keys: contractPermissions.map(
        permission => permission.permission_key
      ),
    };

    dispatch(
      ContractsCreators.contractsUpdateRequest(
        auxContract,
        contract.uuid,
        history
      )
    );
  }

  function handleAddPermission(e) {
    e.preventDefault();
    dispatch(
      ContractPermissionsCreators.contractPermissionsAdd(
        permissions.filter(
          permission => permission.permission_key === permission_key
        )
      )
    );
  }

  function handleRemovePermission(permission) {
    dispatch(ContractPermissionsCreators.contractPermissionsRemove(permission));
  }

  function handleModalByProduct() {
    setAddByProduct(true);
    dispatch(
      ApplicationsCreators.applicationsShowRequest(contract.application.uuid)
    );
  }

  function handleToEditAccessGroup(accessGroup, { auxActionButton }) {
    dispatch(
      AccessGroupsCreators.accessGroupsShowRequest(
        accessGroup.uuid,
        auxActionButton,
        history
      )
    );
  }

  function handleAddAccessGroup() {
    dispatch(
      AccessGroupsCreators.accessGroupsNewRequest(contract.uuid, history)
    );
  }

  function handleDeleteAccessGroup(accessGroup) {
    dispatch(AccessGroupsCreators.accessGroupsDeleteRequest(accessGroup));
  }

  useEffect(() => {
    if (errContracts && formRef.current) {
      formRef.current.setErrors(errContracts);
    }
  }, [errContracts]);

  useEffect(() => {
    if (contract.uuid && formRef.current) {
      const expires = contract.expires_in.slice(0, 10);
      const { licenses } = contract;
      formRef.current.setData({
        expires_in: expires,
        licenses,
      });
      setCompany_key(contract.company_key);
      setApplication_key(contract.application_key);
      setStatus(contract.status.toString());
      setAddByProduct(false);
      setPermission_key('');
    }
  }, [contract, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!contract.uuid && userAuth.is_admin) {
      setBranch_key('');
      setCompany_key('');
      dispatch(ApplicationsCreators.applicationsGetAllRequest({}));
      dispatch(CompaniesCreators.companiesGetAllRequest({}));
    }
    dispatch(AccessGroupsCreators.accessGroupsClear());
  }, [dispatch, contract, userAuth]);

  useEffect(() => {
    dispatch(ContractsCreators.contractsClearErrors());
  }, [addByProduct, dispatch]);

  useEffect(() => {
    if (companiesList) {
      const companyFilter = companiesList.filter(
        company => company.company_key === company_key
      );
      if (companyFilter[0] && companyFilter[0].uuid) {
        dispatch(CompaniesCreators.companiesShowRequest(companyFilter[0].uuid));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company_key, companiesList]);

  useEffect(() => {
    setAddByProduct(false);
  }, [contractPermissions]);

  useEffect(() => {
    setPermission_key('');
    dispatch(ContractPermissionsCreators.contractPermissionsClear());
  }, [addByProduct, dispatch]);

  useEffect(() => {
    if (!userAuth.is_admin && !contract.uuid) history.goBack();
  }, [contract, history, userAuth]);

  return (
    <>
      <Header />
      <Container>
        <Unform
          ref={formRef}
          onSubmit={contract.uuid ? handleUpdate : handleSubmit}
        >
          {contract.uuid ? (
            <Data>
              <TitleContent>
                <h1>Dados do contrato</h1>
                <div>
                  <strong>Número</strong>
                  <p>{contract.contract_key} </p>
                </div>
              </TitleContent>
              <FirstLine>
                <div className="dataRadio">
                  <strong>Status</strong>
                  <Radio
                    type="radio"
                    name="status"
                    value="1"
                    label="Ativo"
                    onChange={e => setStatus(e.target.value)}
                    checked={status === '1'}
                    disabled={actionButton === 'toView'}
                  />

                  <Radio
                    type="radio"
                    name="status"
                    value="2"
                    label="Bloqueado"
                    onChange={e => setStatus(e.target.value)}
                    checked={status === '2'}
                    disabled={actionButton === 'toView'}
                  />

                  <Radio
                    type="radio"
                    name="status"
                    value="3"
                    label="Cancelado"
                    onChange={e => setStatus(e.target.value)}
                    checked={status === '3'}
                    disabled={actionButton === 'toView'}
                  />
                </div>
                <div className="dataContract">
                  <strong>Data Início</strong>
                  <p>{contract.created_at.slice(0, 10)} </p>
                </div>
                <InputContent className="date">
                  <strong>Data da expiração</strong>
                  <Input
                    name="expires_in"
                    type="date"
                    placeholder="Digite a data do vencimento."
                    disabled={actionButton === 'toView'}
                  />
                </InputContent>
                <InputContent className="date">
                  <strong>Licenças</strong>
                  <Input
                    name="licenses"
                    type="text"
                    disabled={actionButton === 'toView'}
                  />
                </InputContent>
              </FirstLine>
              <SecondLine>
                <div className="dataContract">
                  <strong>Empresa</strong>
                  <p>{contract.company.name}</p>
                </div>
                <div className="dataContract">
                  <strong>CNPJ</strong>
                  <p>{contract.company.company_key} </p>
                </div>
                <div className="dataContract">
                  <strong>Aplicação</strong>
                  <p>{contract.application.name}</p>
                </div>
              </SecondLine>
              <TableContainer>
                <>
                  <div className="byProduct">
                    <strong>Subprodutos</strong>
                    {userAuth.is_admin && (
                      <button type="button" onClick={handleModalByProduct}>
                        <MdAdd size={30} />
                      </button>
                    )}
                  </div>
                  {contractPermissions[0] && (
                    <table>
                      <thead>
                        <tr>
                          <th>Chave</th>
                          <th>Nível</th>
                          <th>Descrição</th>
                          {userAuth.is_admin && (
                            <>{actionButton !== 'toView' && <th>Ações</th>}</>
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {contractPermissions.map(permission => (
                          <tr key={permission.id}>
                            <td>{permission.permission_key}</td>
                            <td>
                              {permission.permission
                                ? permission.permission.level
                                : permission.level}
                            </td>
                            <td>
                              {permission.permission
                                ? permission.permission.action
                                : permission.action}
                            </td>
                            {userAuth.is_admin && (
                              <>
                                {actionButton !== 'toView' && (
                                  <td>
                                    <MdDelete
                                      size={20}
                                      onClick={() =>
                                        handleRemovePermission(permission)
                                      }
                                    />
                                  </td>
                                )}
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              </TableContainer>

              <ButtonContent>
                {actionButton === 'toView' ? null : (
                  <ButtonIn type="submit">
                    {contract.uuid ? 'Salvar' : 'Cadastrar'}
                  </ButtonIn>
                )}
              </ButtonContent>
            </Data>
          ) : (
            <Data>
              <h1>Cadastro de contrato</h1>
              <FirstLine>
                <InputContent>
                  <strong>E-mail do Gerente *</strong>
                  <Input
                    name="manager_email"
                    placeholder="Digite o e-mail do gerente."
                    disabled={actionButton === 'toView'}
                  />
                </InputContent>
                <InputContent className="date">
                  <strong>Data da expiração</strong>
                  <Input
                    name="expires_in"
                    type="date"
                    placeholder="Digite a data do vencimento."
                    disabled={actionButton === 'toView'}
                  />
                </InputContent>

                <InputContent className="date">
                  <strong>Licenças</strong>
                  <Input
                    name="licenses"
                    type="text"
                    disabled={actionButton === 'toView'}
                  />
                </InputContent>
              </FirstLine>
              <SecondLine>
                {actionButton === 'toEdit' ? null : (
                  <CompanyContent>
                    <strong>Empresa *</strong>
                    <DropdownSelect
                      size="sm"
                      title="Selecione uma Empresa"
                      items={companiesList}
                      action={e => setCompany_key(e.target.value)}
                      valueSelect={company_key}
                      error={errContracts.company_key}
                      disabled={actionButton === 'toView'}
                      keyName="company_key"
                      valueName="name"
                    />
                  </CompanyContent>
                )}

                {actionButton === 'toEdit' ? null : (
                  <CompanyContent className="branch">
                    <strong>Filial Matriz *</strong>
                    <DropdownSelect
                      size="sm"
                      title="Selecione uma Filial"
                      items={branchesList}
                      action={e => setBranch_key(e.target.value)}
                      valueSelect={branch_key}
                      error={errContracts.branch_key}
                      disabled={actionButton === 'toView'}
                      keyName="branch_key"
                      valueName="last_name"
                    />
                  </CompanyContent>
                )}
              </SecondLine>

              <ApplicationsContent>
                <strong>Aplicação *</strong>
                <DropdownSelect
                  size="sm"
                  title="Selecione uma aplicação"
                  items={applicationsList}
                  action={e => setApplication_key(e.target.value)}
                  valueSelect={application_key}
                  error={errContracts.application_key}
                  disabled={actionButton === 'toView'}
                  keyName="application_key"
                  valueName="name"
                />
              </ApplicationsContent>

              <ButtonContent>
                {actionButton === 'toView' ? null : (
                  <ButtonIn type="submit">
                    {contract.uuid ? 'Salvar' : 'Cadastrar'}
                  </ButtonIn>
                )}
              </ButtonContent>
            </Data>
          )}
        </Unform>

        {contract.uuid && (
          <Content>
            <AccessContent>
              <button
                type="button"
                onClick={() => {
                  if (accessGroupList) {
                    setAccessGroupList(false);
                  } else {
                    setAccessGroupList(true);
                  }
                }}
              >
                <strong>Grupo de Acesso</strong>
                <MdKeyboardArrowDown size={30} />
              </button>

              {accessGroupList && (
                <TableContainer>
                  <>
                    <div className="accessGroup">
                      <button type="button" onClick={handleAddAccessGroup}>
                        <MdAdd size={30} />
                        Grupo de Acesso
                      </button>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Descrição</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {accessGroups.map(accessGroup => (
                          <tr key={accessGroup.id}>
                            <td>{accessGroup.name}</td>
                            <td>{accessGroup.description}</td>
                            <td className="action">
                              <MdRemoveRedEye
                                size={20}
                                onClick={() =>
                                  handleToEditAccessGroup(accessGroup, {
                                    auxActionButton: 'toView',
                                  })
                                }
                              />
                              <MdModeEdit
                                size={20}
                                onClick={() =>
                                  handleToEditAccessGroup(accessGroup, {
                                    auxActionButton: 'toEdit',
                                  })
                                }
                              />
                              <MdDelete
                                size={20}
                                onClick={() =>
                                  handleDeleteAccessGroup(accessGroup)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                </TableContainer>
              )}
              <SmallModal
                show={addByProduct}
                onHide={() => setAddByProduct(false)}
                title="Subproduto"
                body={
                  <form onSubmit={handleAddPermission}>
                    <Data>
                      <FirstLine>
                        <InputContent>
                          <strong>Subproduto *</strong>
                          <DropdownSelect
                            size="sm"
                            title="Selecione um Subproduto"
                            items={permissions.filter(
                              permission =>
                                permission.level === 4 &&
                                permission.application_key ===
                                  contract.application.application_key
                            )}
                            action={e => setPermission_key(e.target.value)}
                            valueSelect={permission_key}
                            error={errContractPermissions.permission_keys}
                            keyName="permission_key"
                            valueName="action"
                          />
                          {errContracts && (
                            <Error>
                              <p>{errContracts.permission_keys}</p>
                            </Error>
                          )}
                        </InputContent>
                      </FirstLine>

                      <ButtonContent disabled={permission_key === ''}>
                        <ButtonIn
                          type="submit"
                          disabled={permission_key === ''}
                        >
                          Adicionar
                        </ButtonIn>
                      </ButtonContent>
                    </Data>
                  </form>
                }
              />
            </AccessContent>
          </Content>
        )}
      </Container>
    </>
  );
};

export default FormContracts;
