import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #c9d3dd;

    > div {
      display: flex;
      align-items: flex-start;
      strong {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #92a6bb;
        text-align: start;
      }
    }

    @media (max-width: 950px) {
      div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Data = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  h1 {
    font-size: 22px;
    color: #65ade8;
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: 950px) {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const TitleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonPermissions = styled.button`
  padding: 15px 30px 15px 30px;
  border: 0;
  font-weight: bold;
  background: #1f7ecc;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.2s;
  margin-left: 5px;

  &:hover {
    background: ${shade(-0.2, '#1F7ECC')};
  }
`;

export const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center auto;
  justify-content: start;
  width: 100%;
  margin-top: 10px;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const SecondtLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ThirdLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center auto;
  justify-content: start;
  width: 100%;
  margin-top: 10px;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-right: 6px;
`;

export const ButtonContent = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  button {
    padding: 15px 30px 15px 30px;
    border: 0;
    font-weight: bold;
    background: #1fcc85;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(-0.2, '#1FCC85')};
    }
  }
`;

export const Avatar = styled.div`
  position: relative;
  align-self: center;
  margin-right: 5px;

  > img {
    width: 120px;
    height: 120px;
    object-fit: contain;
  }

  label {
    position: absolute;
    width: 38px;
    height: 38px;
    background: #1f7ecc;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
    }

    :hover {
      background: ${shade(0.2, '#1f7ecc')};
    }
  }
`;

export const ApplicationData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 950px) {
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
`;
