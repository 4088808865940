import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Creators as AplicationsCreators } from '~/store/ducks/applications';

import Header from '~/components/Header';
import { FlipCard } from '~/components/FlipCard';

import { Container, LinkAccess } from './styles';

const Dashboard = () => {
  const aplications = useSelector(state => state.applications.applications);

  const [pageLoaded, setPageLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AplicationsCreators.applicationsGetAllRequest({}));
  }, [dispatch]);

  useEffect(() => {
    if (!pageLoaded && aplications) {
      setPageLoaded(true);
    }
  }, [aplications, pageLoaded]);

  return (
    <>
      <Header />

      {pageLoaded && (
        <Container>
          <Row xs={1} md={3} lg={3}>
            {aplications.map(app => (
              <Col className="col" key={app.id}>
                <FlipCard
                  title={app.name}
                  image={app.image}
                  styles={{ color: `${app.name_color}` }}
                  subTitle={app.caption}
                  description={app.description}
                  button={
                    <LinkAccess
                      target="_blank"
                      rel="noreferrer"
                      href={
                        app.application_key === 'zapile'
                          ? process.env.REACT_APP_ZAPILE_URL
                          : null
                      }
                    >
                      <p>Acessar</p>
                    </LinkAccess>
                  }
                />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
