/* eslint-disable camelcase */
import { call, put } from 'redux-saga/effects';
import { Creators as ContractsCreators } from '../ducks/contracts';
import { Creators as AccessGroupsCreators } from '../ducks/accessGroups';
import { Creators as ContractPermissionsCreators } from '../ducks/contractPermissions';

import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

import validateContracts from '~/validators/contracts';

export function* getAllContracts() {
  try {
    const response = yield call(api.get, 'contracts');

    const contracts = response.data;

    yield put(ContractsCreators.contractsGetAllSuccess(contracts));
  } catch (err) {
    errorHandler(err);
    yield put(ContractsCreators.contractsFailure());
  }
}

export function* createContract({ contract, history }) {
  try {
    yield validateContracts(contract);

    const response = yield call(api.post, 'contracts', {
      ...contract,
      expires_in: `${contract.expires_in} 23:59:59`,
    });
    const newContract = response.data;

    yield put(ContractsCreators.contractsCreateSuccess(newContract));
    toastSuccess(`Contrato criado com sucesso.`);
    history.goBack();
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(ContractsCreators.contractsCreateFailure(errors));
  }
}

export function* deleteContract({ contract }) {
  try {
    yield call(api.delete, `contracts/${contract.uuid}`);
    yield put(ContractsCreators.contractsDeleteSuccess(contract));
    toastSuccess(`Contrato deletado.`);
  } catch (err) {
    const errors = errorHandler(err, true);

    yield put(ContractsCreators.contractsFailure(errors));
  }
}

export function* showContract({ uuid, history }) {
  try {
    const response = yield call(api.get, `contracts/${uuid}`);
    const { contract_permissions, access_groups, ...contract } = response.data;

    yield put(ContractsCreators.contractsShowSuccess(contract));
    yield put(AccessGroupsCreators.accessGroupsList(access_groups));
    yield put(
      ContractPermissionsCreators.contractPermissionsList(contract_permissions)
    );
    history.push('/contracts/form');
  } catch (err) {
    errorHandler(err, true);
    yield put(ContractsCreators.contractsFailure());
  }
}

export function* updateContract({ contract, uuid, history }) {
  try {
    yield call(api.put, `contracts/${uuid}`, {
      ...contract,
      expires_in: `${contract.expires_in} 23:59:59`,
    });

    yield put(ContractsCreators.contractsUpdateSuccess());
    toastSuccess(`Dados alterados com sucesso!`);
    history.goBack();
  } catch (err) {
    const errors = errorHandler(err);
    yield put(ContractsCreators.contractsUpdateFailure(errors));
  }
}
