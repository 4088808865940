import history from '~/services/history';
import { toastWarning } from './Toast';
import {
  getValidationErrorsApi,
  getValidationErrorsYup,
} from './getValidationErrors';

export default function (err, short) {
  let errors = {};
  if (err.inner) {
    const error = getValidationErrorsYup(err);
    errors = error;
  } else {
    const { error } = err.response.data;
    if (error && error.code === 'E_VALIDATION_FAILED') {
      toastWarning(
        `${error.message}${short ? `. ${error.validations[0].message}` : ''}`
      );
      const errAplicationsApi = getValidationErrorsApi(error);
      errors = errAplicationsApi;
    } else if (error.code === 'E_PASSWORD_MISMATCH') {
      toastWarning('Senha incorreta!');
    } else if (error.code === 'E_UNAUTHORIZED_ACCESS') {
      toastWarning(error.message);
    } else if (
      error.code === 'E_JWT_TOKEN_EXPIRED' ||
      error.code === 'E_INVALID_JWT_TOKEN'
    ) {
      toastWarning(error.message);
      localStorage.clear();
      history.push('/');
    } else if (error.code === 'E_HAS_RELATIONSHIP') {
      toastWarning(error.message);
    } else {
      toastWarning(error.message);
    }
  }
  return errors;
}
