import styled, { keyframes, css } from 'styled-components';

import { shade } from 'polished';

import Tooltip from '~/components/Tooltip';

const appearFromLeft = keyframes` from {
    opacity: 0;
    transform: translateX(0px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }

`;

export const Styles = styled.section`
  .row {
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .login-box {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryFontColor};
    padding: 10% 5%;
    animation: ${appearFromLeft} 1s;
    a {
      display: block;
      text-decoration: none;
      color: #f4ede8;
      font-size: 13px;
      transition: color 0.2s;
      text-align: right;
      justify-content: center;
      align-items: center;
      &:hover {
        color: ${shade(0.2, '#1f7ecc')};
      }
    }
  }
  .input-group {
    padding: 4% 0;
    position: relative;
    right: 5px;
  }
  .input-group-prepend {
    padding: 3% 0;
    margin-right: 5px;
  }
  .btn {
    margin: 5% 0;
  }
`;

export const Form = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    margin-top: 30px;
    height: 50px;
    border: 1px solid #fff;
    background: #1f7ecc;
    color: #fff;
    transition: background 0.2s;
    border-radius: 8px;
    &:hover {
      background: ${shade(0.2, '#1f7ecc')};
    }
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #c4c4c4;
  ${props =>
    props.isFocused &&
    css`
      border-color: #fff;
    `} ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

   svg {
    margin-right: 12px;
  }
  input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: 0;
    color: #ffff;
    &::placeholder {
      color: ${shade(0.2, '#fff')};
      opacity: 0.3;
    }
  }
`;

export const ContentPassword = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #c4c4c4;
  ${props =>
    props.isFocused &&
    css`
      border-color: #fff;
    `} ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

 svg {
    margin-right: 12px;
  }
  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #ffff;
    height: 100%;
    &::placeholder {
      color: ${shade(0.2, '#fff')};
      opacity: 0.3;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 12px;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    text-align: center;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
