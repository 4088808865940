import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, TableContainer } from './styles';

const Branches = ({ value, setValue }) => {
  const accessGroupBranches = useSelector(
    state => state.accessGroups.accessGroup.branches
  );
  const accessGroup = useSelector(state => state.accessGroups.accessGroup);
  const actionButton = useSelector(state => state.accessGroups.actionButton);

  function handleAddPermission(e, branch) {
    const { checked } = e.target;

    setValue({
      ...value,
      [branch.branch_key]: checked,
    });
  }

  function handleSelectAll(e) {
    const { checked } = e.target;

    const auxObj = {};
    accessGroupBranches.forEach(b => {
      auxObj[b.branch_key] = checked;
    });

    setValue(auxObj);
  }

  return (
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              <th>Empresa</th>
              <th>CNPJ</th>
            </tr>
          </thead>
          {accessGroupBranches && accessGroupBranches[0] ? (
            <tbody>
              {accessGroupBranches.map(branch => (
                <tr key={branch.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddPermission(e, branch)}
                      checked={!!value[branch.branch_key]}
                      disabled={actionButton === 'toView'}
                    />
                  </td>
                  <td> {branch.first_name}</td>
                  <td>{branch.branch_key}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {accessGroup.branches?.map(branch => (
                <tr key={branch.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddPermission(e, branch)}
                    />
                  </td>
                  <td> {branch.first_name}</td>
                  <td>{branch.branch_key}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </TableContainer>
    </Container>
  );
};

export default Branches;

Branches.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setValue: PropTypes.func,
};

Branches.defaultProps = {
  setValue: () => {},
};
