import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';

import { MdCameraAlt } from 'react-icons/md';

import { Creators as ApplicationsCreators } from '~/store/ducks/applications';

import convertImage from '~/utils/convertImage';
import { toastWarning } from '~/utils/Toast';
import imageAppllication from '~/assets/imageApplication';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';

import {
  Container,
  Data,
  FirstLine,
  InputContent,
  SecondtLine,
  ButtonContent,
  ThirdLine,
  ButtonPermissions,
  Avatar,
  ApplicationData,
  TitleContent,
} from './styles';

const Form = () => {
  const errAplications = useSelector(
    state => state.applications.errAplications
  );
  const application = useSelector(state => state.applications.application);
  const actionButton = useSelector(state => state.applications.actionButton);

  const [image, setImage] = useState(imageAppllication);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleUpdate(data) {
    const updateApplication = { ...data, image };
    dispatch(
      ApplicationsCreators.applicationsUpdateRequest(
        updateApplication,
        application.uuid,
        history
      )
    );
  }

  function handleSubmit(data) {
    const newApplication = { ...data, image };
    dispatch(
      ApplicationsCreators.applicationsCreateRequest(newApplication, history)
    );
  }

  async function handleAvatarChange(e) {
    const base64Data = await convertImage(e.target.files[0]).catch(err =>
      alert(JSON.stringify(err && err.message))
    );

    if (base64Data) {
      const validatorImage = base64Data.startsWith('data:image');
      if (!validatorImage) {
        toastWarning('O formato do arquivo é inválido. Selecione uma imagem.');
      }
    }
    setImage(base64Data || image);
  }

  function onErrorImage(e) {
    e.target.src = imageAppllication;
    setImage(imageAppllication);
  }

  useEffect(() => {
    if (application.uuid) {
      formRef.current.setData(application);
      setImage(application.image);
    }
  }, [application]);

  useEffect(() => {
    formRef.current.setErrors({});
    if (errAplications) {
      formRef.current.setErrors(errAplications);
    }
  }, [errAplications]);

  return (
    <>
      <Header />
      <Container>
        <Unform
          ref={formRef}
          onSubmit={application.uuid ? handleUpdate : handleSubmit}
        >
          <Data>
            <TitleContent>
              <h1>
                {application.uuid ? 'Dados da ' : 'Cadastro da '}Aplicação
              </h1>

              {application.uuid && (
                <div>
                  <ButtonPermissions
                    type="button"
                    onClick={() => history.push('/roles')}
                  >
                    Listar Papéis
                  </ButtonPermissions>
                  <ButtonPermissions
                    type="button"
                    onClick={() => history.push('/permissions')}
                  >
                    Listar Permissões
                  </ButtonPermissions>
                </div>
              )}
            </TitleContent>
            <FirstLine>
              <Avatar>
                <img src={image} alt="application" onError={onErrorImage} />

                {actionButton !== 'toView' && (
                  <label htmlFor="avatar">
                    <MdCameraAlt />

                    <input
                      type="file"
                      id="avatar"
                      accept="image/png, image/jpeg"
                      onChange={handleAvatarChange}
                    />
                  </label>
                )}
              </Avatar>
              <ApplicationData>
                <div>
                  <InputContent>
                    <strong>Chave *</strong>
                    <Input
                      name="application_key"
                      placeholder="Digite a chave da aplicação"
                      disabled={actionButton === 'toView'}
                    />
                  </InputContent>
                  <InputContent>
                    <strong>Nome *</strong>
                    <Input
                      name="name"
                      placeholder="Digite o nome da aplicação"
                      disabled={actionButton === 'toView'}
                    />
                  </InputContent>
                  <InputContent>
                    <strong>Subtítulo *</strong>
                    <Input
                      name="caption"
                      placeholder="Digite o subtítulo da aplicação"
                      disabled={actionButton === 'toView'}
                    />
                  </InputContent>
                </div>
                <div>
                  <InputContent>
                    <strong>Descrição *</strong>
                    <Input
                      name="description"
                      placeholder="Digite a descrição da aplicação"
                      data-ls-module="charCounter"
                      maxLength="255"
                      disabled={actionButton === 'toView'}
                    />
                  </InputContent>
                </div>
              </ApplicationData>
            </FirstLine>
            <SecondtLine>
              <InputContent>
                <strong>Cor *</strong>
                <Input
                  name="name_color"
                  placeholder="Digite a cor da aplicação ex: #3A1AF1"
                  data-ls-module="charCounter"
                  maxLength="7"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>

              <InputContent>
                <strong>Número Sequencial *</strong>
                <Input
                  name="sequential_number"
                  placeholder="Digite o Número Sequencial"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
            </SecondtLine>
            <ThirdLine />

            <ButtonContent>
              {actionButton === 'toView' ? null : (
                <ButtonIn type="submit">
                  {application.uuid ? 'Salvar' : 'Cadastrar'}
                </ButtonIn>
              )}
            </ButtonContent>
          </Data>
        </Unform>
      </Container>
    </>
  );
};
export default Form;
