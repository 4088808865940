import * as Yup from 'yup';

export default async function (data) {
  const schemaValid = Yup.object().shape({
    root_register_number: Yup.string()
      .min(8, 'O campo só permite 8 digitos.')
      .required('Este campo é obrigatório.'),
    name: Yup.string().required('Este campo é obrigatório.'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
