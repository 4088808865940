import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  margin-top: 0;

  header {
    display: flex;
    align-items: center;

    width: 100%;
    height: 120px;
    background: #cad9ef;

    div {
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;

      svg {
        width: 30px;
        height: 30px;
        color: #1f7ecc;
        transition: color 0.2s;

        :hover {
          color: ${shade(-0.2, '#1f7ecc')};
        }
      }
    }
  }

  form {
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: -100px auto 0;

    div {
      strong {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #92a6bb;
        text-align: start;
      }
    }

    h1 {
      font-size: 22px;
      color: #65ade8;
      text-align: center;
      justify-content: flex-start;
      font-weight: bold;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  align-items: flex-start;
`;

export const ButtonContent = styled.div`
  width: 340px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  button {
    width: 100%;
    padding: 15px 30px 15px 30px;
    border: 0;
    font-weight: bold;
    background: #1fcc85;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(-0.2, '#1FCC85')};
    }
  }
`;

export const Avatar = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  background: #fff;
  border-radius: 50%;
  padding: 8px;

  > img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
    object-fit: contain;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #1f7ecc;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
    }

    :hover {
      background: ${shade(0.2, '#1f7ecc')};
    }
  }
`;
