import styled from 'styled-components';

export const Styles = styled.section`
  .card {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2), 0 5px 30px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    min-width: 100%;
  }

  .card-container {
    display: flex;
    flex: 1;
    perspective: 700px;
    justify-content: center;
    width: 100%;
  }

  .card-flip {
    display: flex;
    position: relative;
    flex: 1;
    grid-template-areas: frontAndBack;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
  }

  .card-flip div {
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }

  .front {
    grid-area: frontAndBack;
  }

  .back {
    position: absolute;
    height: 100%;
    background-color: #f7f7f7;
    grid-area: frontAndBack;
    transform: rotateY(-180deg);
  }

  .card-container:hover .card-flip {
    transform: rotateY(180deg);
  }

  .card-img {
    padding: 8px 5px;
    height: 8rem;
    object-fit: contain;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-text {
      color: ${props => props.theme.primaryFontColor};
    }
  }
`;
