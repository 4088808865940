import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import SignIn from '~/pages/SignIn';
import Company from '~/pages/Company';
import Recover from '~/pages/Recover';
import Dashboard from '~/pages/Dashboard';
import Settings from '~/pages/Settings';
import Applications from '~/pages/Applications';
import ApplicationsForm from '~/pages/Applications/Form';
import Permissions from '~/pages/Permissions';
import PermissionsForm from '~/pages/Permissions/Form';
import Companies from '~/pages/Companies';
import CompanyForm from '~/pages/Companies/Form';
import Branches from '~/pages/Branches';
import BranchForm from '~/pages/Branches/Form';
import Users from '~/pages/Users';
import UsersForm from '~/pages/Users/Form';
import Contracts from '~/pages/Contracts';
import ContractsForm from '~/pages/Contracts/Form';
import AccessGroups from '~/pages/AccessGroups';
import Profile from '~/pages/Profile';
import Roles from '~/pages/Roles';
import RolesForm from '~/pages/Roles/Form';
import Route from './Routes';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/recover" component={Recover} />
        <Route path="/company" component={Company} company />

        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/settings" component={Settings} isPrivate isManager />
        <Route
          path="/applications"
          exact
          component={Applications}
          isPrivate
          isAdmin
        />
        <Route
          path="/applications/form"
          component={ApplicationsForm}
          isPrivate
          isAdmin
        />
        <Route
          path="/permissions"
          exact
          component={Permissions}
          isPrivate
          isAdmin
        />
        <Route
          path="/permissions/form"
          component={PermissionsForm}
          isPrivate
          isAdmin
        />
        <Route
          path="/companies"
          exact
          component={Companies}
          isPrivate
          isAdmin
        />
        <Route
          path="/companies/form"
          component={CompanyForm}
          isPrivate
          isAdmin
        />
        <Route path="/branches" exact component={Branches} isPrivate isAdmin />
        <Route path="/branches/form" component={BranchForm} isPrivate isAdmin />
        <Route path="/users" exact component={Users} isPrivate isManager />
        <Route path="/users/form" component={UsersForm} isPrivate isManager />
        <Route
          path="/contracts"
          exact
          component={Contracts}
          isPrivate
          isManager
        />
        <Route
          path="/contracts/form"
          component={ContractsForm}
          isPrivate
          isManager
        />
        <Route
          path="/access-groups"
          exact
          component={AccessGroups}
          isPrivate
          isManager
        />
        <Route path="/profile" exact component={Profile} isPrivate />
        <Route path="/roles" exact component={Roles} isPrivate isAdmin />
        <Route path="/roles/form" component={RolesForm} isPrivate isAdmin />

        <Route path="/*" component={() => <h1>404</h1>} isPrivate />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
