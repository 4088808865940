import styled from 'styled-components';
import { Button as bootstrapButton } from 'react-bootstrap/';

export const Button = styled(bootstrapButton)`
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 10px;

  font-size: ${props =>
    props.size === 'sm'
      ? props.theme.sm
      : props.size === 'md'
      ? props.theme.md
      : props.size === 'lg'
      ? props.theme.lg
      : ''};

  background-color: ${props =>
    props.type === 'primary-filled'
      ? props.theme.primaryColor
      : props.type === 'primary-outline'
      ? '#FFF'
      : ''};

  border-color: ${props =>
    props.type === 'primary-filled'
      ? '#FFF'
      : props.type === 'primary-outline'
      ? props.theme.primaryColor
      : ''};

  color: ${props =>
    props.type === 'primary-filled'
      ? '#FFF'
      : props.type === 'primary-outline'
      ? props.theme.primaryColor
      : ''};
`;
