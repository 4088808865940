import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const ButtonIn = ({ children, ...rest }) => {
  return (
    <Container type="button" {...rest}>
      {children}
    </Container>
  );
};

export default ButtonIn;

ButtonIn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
