import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  border: 2px solid ${props => (props.isErrored ? '#c53030' : '#e9e9e9')};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .select {
    height: 40px;
    flex: 1;
    border: 0;
    outline: 0;
  }

  .select:focus {
    outline: none;
    box-shadow: none;
  }

  ${props =>
    props.isFocused &&
    css`
      border-color: #1f7ecc;
    `}
`;

export const Error = styled(Tooltip)`
  height: 20px;
  width: 30px;
  align-items: center;
  margin-right: 5px;
  margin-left: 16px;

  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    text-align: center;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
