import { call, put } from 'redux-saga/effects';
import { Creators as RolesAccessGroupsCreators } from '../ducks/rolesAccessGroups';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

export function* remove({ accessGroup }) {
  try {
    yield call(api.delete, `role-access-groups/${accessGroup.uuid}`);

    yield put(
      RolesAccessGroupsCreators.rolesAccessGroupsDeleteSuccess(accessGroup)
    );
    toastSuccess('Papel desvinculado do grupo de accesso!');
  } catch (err) {
    errorHandler(err, true);
    yield put(RolesAccessGroupsCreators.rolesAccessGroupsFailure());
  }
}
