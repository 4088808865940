import { call, put } from 'redux-saga/effects';

import { Creators as StatesCreators } from '../ducks/states';

import api from '~/services/api';

export function* getAllStates() {
  try {
    const response = yield call(api.get, `states`);

    const states = response.data;
    yield put(StatesCreators.statesGetAllSuccess(states));
  } catch (err) {
    yield put(StatesCreators.statesGetAllFailure());
  }
}

export function* getAllCities({ id }) {
  try {
    const response = yield call(api.get, `states/${id}`);

    const { cities } = response.data;
    yield put(StatesCreators.statesGetAllCitiesSuccess(cities));
  } catch (err) {
    yield put(StatesCreators.statesFailure());
  }
}
