/* eslint-disable camelcase */
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';

import { MdCameraAlt, MdKeyboardArrowDown } from 'react-icons/md';

import { Creators as BranchesCreators } from '~/store/ducks/branches';
import { Creators as StatesCreators } from '~/store/ducks/states';
import { Creators as BranchesAccessGroupsCreators } from '~/store/ducks/branchesAccessGroups';

import convertImage from '~/utils/convertImage';
import { toastWarning } from '~/utils/Toast';
import imageCompany from '~/assets/imageCompany';

import SearchCnpjService from '~/services/searchCnpj';
import SearchZipCodeService from '~/services/searchZipCode';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';
import { DropdownSelect } from '~/components/DropdownSelect';

import {
  Container,
  Data,
  Logo,
  InputContent,
  FirstLine,
  CompanyData,
  SecondLine,
  ThirdLine,
  FourthLine,
  ButtonContent,
  Card,
  TableContainer,
  UnlinkButton,
} from './styles';

const FormBranch = () => {
  const errBranches = useSelector(state => state.branches.errBranches);
  const branch = useSelector(state => state.branches.branch);
  const company = useSelector(state => state.companies.company);
  const actionButton = useSelector(state => state.branches.actionButton);
  const stateList = useSelector(state => state.states.states);
  const citiesList = useSelector(state => state.states.cities);
  const branchesAccessGroups = useSelector(
    state => state.branchesAccessGroups.branchesAccessGroups
  );

  const [state_id, setState_id] = useState('');
  const [city_id, setCity_id] = useState('');
  const [card, setCard] = useState(false);
  // const [loadingZipCode, setLoadingZipCode] = useState(false);
  // const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [imageLogo, setImageLogo] = useState('');

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleUpdate(data) {
    const { company_key } = company;

    const branchdData = {
      ...data,
      company_key,
      state_id,
      city_id,
      image: imageLogo,
    };
    dispatch(
      BranchesCreators.branchesUpdateRequest(branchdData, branch.uuid, history)
    );
  }

  function handleSubmit(data) {
    const { company_key, image } = company;
    const branchData = { ...data, company_key, state_id, city_id, image };
    dispatch(BranchesCreators.branchesCreateRequest(branchData, history));
  }
  function handleSelectState(e) {
    dispatch(StatesCreators.statesGetAllCitiesRequest(e.target.value));
    setState_id(e.target.value);
    setCity_id('');
  }

  async function handleAvatarChange(e) {
    const base64Data = await convertImage(e.target.files[0]).catch(err =>
      alert(JSON.stringify(err && err.message))
    );

    if (base64Data) {
      const validatorImage = base64Data.startsWith('data:image');
      if (!validatorImage) {
        toastWarning('O formato do arquivo é inválido. Selecione uma imagem.');
      }
    }
    setImageLogo(base64Data || imageLogo);
  }

  function handleUnlinkBranch(accessGroup) {
    dispatch(
      BranchesAccessGroupsCreators.branchesAccessGroupsDeleteRequest(
        accessGroup
      )
    );
  }

  function onErrorImage(e) {
    e.target.src = imageCompany;
    setImageLogo(company.image);
  }

  async function handleSearchZipCode() {
    await SearchZipCodeService({
      // setLoadingZipCode,
      formRef,
      states: stateList,
      setState_id,
      setCity_id,
    });
  }

  async function handleSearchCnpj() {
    await SearchCnpjService({
      // setLoadingCnpj,
      formRef,
      handleSearchZipCode,
    });
  }

  useEffect(() => {
    if (branch.uuid) {
      dispatch(StatesCreators.statesGetAllCitiesRequest(branch.state_id));
      formRef.current.setData(branch);
      setState_id(branch.state_id);
      setCity_id(branch.city_id);
      setImageLogo(branch.image ? branch.image : company.image);
    } else {
      setImageLogo(company.image);
    }
  }, [branch, company, dispatch]);

  useEffect(() => {
    formRef.current.setErrors({});
    if (errBranches) {
      formRef.current.setErrors(errBranches);
    }
  }, [errBranches]);

  useEffect(() => {
    if (!company.uuid) history.push('/companies');
  }, [company, history]);

  useEffect(() => {
    dispatch(StatesCreators.statesGetAllRequest());
  }, [dispatch]);

  return (
    <>
      <Header />
      <Container>
        {branch.uuid && (
          <Card>
            <button
              type="button"
              onClick={() => {
                if (card) {
                  setCard(false);
                } else {
                  setCard(true);
                }
              }}
            >
              <strong>Grupos de Acceso</strong>
              <MdKeyboardArrowDown size={30} />
            </button>

            {card ? (
              <>
                <TableContainer isLength={branchesAccessGroups.length > 1}>
                  {branchesAccessGroups.length ? (
                    <table>
                      <div className="table-wrapper">
                        <thead>
                          <tr>
                            <th>Contrato</th>
                            <th>Nome</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          {branchesAccessGroups.map(accessGroup => (
                            <tr key={accessGroup.uuid}>
                              <td>{accessGroup.access_group.contract_key}</td>
                              <td>{accessGroup.access_group.name}</td>
                              <td>
                                <UnlinkButton
                                  type="button"
                                  onClick={() =>
                                    handleUnlinkBranch(accessGroup)
                                  }
                                >
                                  Desvincular
                                </UnlinkButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </div>
                    </table>
                  ) : (
                    <p>A filial não tem vínculo com os grupos de accesso.</p>
                  )}
                </TableContainer>
              </>
            ) : null}
          </Card>
        )}

        <Unform
          ref={formRef}
          onSubmit={branch.uuid ? handleUpdate : handleSubmit}
        >
          <Data>
            <div className="topLine">
              <h1>{branch.uuid ? 'Dados da ' : 'Cadastro da '}Filial</h1>
            </div>
            <FirstLine>
              <Logo>
                <img src={imageLogo} alt="branch" onError={onErrorImage} />

                {actionButton !== 'toView' && (
                  <label htmlFor="avatar">
                    <MdCameraAlt />

                    <input
                      type="file"
                      id="avatar"
                      accept="image/png, image/jpeg"
                      onChange={handleAvatarChange}
                    />
                  </label>
                )}
              </Logo>
              <CompanyData className="inputData">
                <div>
                  <InputContent className="cnpj">
                    <strong>CNPJ *</strong>
                    <Input
                      name="register_number"
                      placeholder="CNPJ da empresa"
                      data-ls-module="charCounter"
                      disabled={actionButton === 'toView'}
                    />
                    <button type="button" onClick={handleSearchCnpj}>
                      Buscar
                    </button>
                  </InputContent>
                  <InputContent>
                    <strong>Razão Social *</strong>
                    <Input
                      name="first_name"
                      placeholder="Nome da empresa"
                      disabled={actionButton === 'toView'}
                    />
                  </InputContent>
                </div>
                <div>
                  <InputContent>
                    <strong>Nome Fantasia *</strong>
                    <Input
                      name="last_name"
                      placeholder="Nome fantasia da empresa"
                      disabled={actionButton === 'toView'}
                    />
                  </InputContent>
                  <InputContent className="registerState">
                    <strong>Inscrição Estadual</strong>
                    <Input
                      name="register_state"
                      data-ls-module="charCounter"
                      maxLength="9"
                      disabled={actionButton === 'toView'}
                    />
                  </InputContent>
                </div>
              </CompanyData>
            </FirstLine>

            <SecondLine>
              <InputContent className="email">
                <strong>E-mail *</strong>
                <Input name="email" disabled={actionButton === 'toView'} />
              </InputContent>
              <InputContent className="ddd">
                <strong>DDD *</strong>
                <Input
                  name="ddd1"
                  data-ls-module="charCounter"
                  maxLength="2"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent className="telephone">
                <strong>Telefone *</strong>
                <Input
                  name="telephone1"
                  data-ls-module="charCounter"
                  maxLength="9"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent className="ddd">
                <strong>DDD</strong>
                <Input
                  name="ddd2"
                  data-ls-module="charCounter"
                  maxLength="2"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent className="telephone">
                <strong>Telefone</strong>
                <Input
                  name="telephone2"
                  data-ls-module="charCounter"
                  maxLength="9"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
            </SecondLine>

            <ThirdLine>
              <InputContent className="cep">
                <strong>CEP *</strong>
                <Input name="zip_code" disabled={actionButton === 'toView'} />
              </InputContent>
              <InputContent className="address">
                <strong>Endereço *</strong>
                <Input name="address" disabled={actionButton === 'toView'} />
              </InputContent>
              <InputContent className="number">
                <strong>Número</strong>
                <Input
                  name="number"
                  data-ls-module="charCounter"
                  maxLength="6"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
            </ThirdLine>

            <FourthLine>
              <InputContent>
                <strong>Bairro *</strong>
                <Input
                  name="neighborhood"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent>
                <strong>Complemento</strong>
                <Input
                  className="complement"
                  name="complement"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent className="state">
                <strong>Estado *</strong>

                <DropdownSelect
                  size="sm"
                  title="Selecione Estado"
                  items={stateList}
                  action={handleSelectState}
                  error={errBranches?.state_id}
                  valueSelect={state_id}
                  disabled={actionButton === 'toView'}
                  keyName="id"
                  valueName="name"
                />
              </InputContent>
              <InputContent className="state">
                <strong>Cidade *</strong>
                <DropdownSelect
                  size="sm"
                  title="Selecione Cidade"
                  items={citiesList}
                  action={e => setCity_id(e.target.value)}
                  valueSelect={city_id}
                  error={errBranches?.city_id}
                  disabled={actionButton === 'toView'}
                  keyName="id"
                  valueName="name"
                />
              </InputContent>
            </FourthLine>

            <ButtonContent>
              {actionButton === 'toView' ? null : (
                <ButtonIn type="submit">
                  {branch.uuid ? 'Salvar' : 'Cadastrar'}
                </ButtonIn>
              )}
            </ButtonContent>
          </Data>
        </Unform>
      </Container>
    </>
  );
};
export default FormBranch;
