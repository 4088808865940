import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import { FiAlertCircle } from 'react-icons/fi';

import { ContentInput, Error } from './styles';

export const DropdownFilterable = ({
  name,
  placeholder,
  filterRegister,
  setRegisterSelected,
  selectedRegister,
  filterFieldValue,
  options,
  error,
}) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div style={{ border: '1px solid white', height: '30px' }}>
      <button
        class="customToggle"
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
        style={{
          height: '30px',
          width: '100%',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '0',
          background: 'transparent',
        }}
      >
        {children}
        &#x25bc;
      </button>
    </div>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={{
            ...style,
            maxHeight: '250px',
            maxWidth: '400px',
            overflow: 'auto',
          }}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            placeholder="Filtrar..."
            onChange={e => filterRegister(e.target.value)}
            value={filterFieldValue}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              child => !selectedRegister || child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
      <Dropdown style={{ marginRight: '8px', width: '150px' }}>
        <Dropdown.Toggle as={CustomToggle}>{placeholder}</Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {options.map(option => (
            <Dropdown.Item
              key={option.name}
              onClick={e => {
                setRegisterSelected(e.currentTarget.textContent);
              }}
            >
              {option.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <ContentInput isFocused isErrored={!!error}>
        <input
          name={name}
          placeholder={placeholder}
          value={selectedRegister}
          disabled
        />
      </ContentInput>
      {error && (
        <Error title={error}>
          <FiAlertCircle size={16} color="#c53030" />
        </Error>
      )}
    </div>
  );
};

DropdownFilterable.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  filterRegister: PropTypes.func.isRequired,
  setRegisterSelected: PropTypes.func.isRequired,
  selectedRegister: PropTypes.string.isRequired,
  filterFieldValue: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
};

DropdownFilterable.defaultProps = {
  name: '',
  placeholder: '',
  filterRegister: () => {},
  setRegisterSelected: () => {},
  selectedRegister: '',
  filterFieldValue: '',
  options: [],
  error: '',
};
