import * as Yup from 'yup';

export default async function (data) {
  const regexColor = /^#[0-9A-F-a-f]{6}$/;
  const regexCharacters = /^[a-z0-90]+$/;

  const schemaValid = Yup.object().shape({
    application_key: Yup.string()
      .matches(
        regexCharacters,
        'O campo não aceita caracteres especiais e letras maiúsculas.'
      )
      .required('Este campo é obrigatório.'),
    name: Yup.string().required('Este campo é obrigatório.'),
    caption: Yup.string().required('Este campo é obrigatório.'),
    description: Yup.string().required('Este campo é obrigatório.'),
    name_color: Yup.string()
      .matches(regexColor, 'Campo com formato inválido. ex: #3A1AF1.')
      .max(7, 'O campo não pode ter mais do que 7 caracteres')
      .required('Este campo é obrigatório.'),
    image: Yup.string()
      .matches(/^data:image\//g, 'Campo com formato inválido.')
      .required('Este campo é obrigatório.'),
    sequential_number: Yup.number()
      .positive('O número tem que ser maior que 0.')
      .typeError('Este campo é obrigatório e numérico.')
      .required('Este campo é obrigatório.'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
