import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';

import { MdKeyboardArrowDown } from 'react-icons/md';

import { Creators as RolesCreators } from '~/store/ducks/roles';
import { Creators as RolesAccessGroupsCreators } from '~/store/ducks/rolesAccessGroups';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';

import {
  Container,
  Data,
  InputContent,
  ButtonContent,
  Title,
  Line,
  Card,
  TableContainer,
  UnlinkButton,
} from './styles';

const Form = () => {
  const application = useSelector(state => state.applications.application);
  const role = useSelector(state => state.roles.role);
  const actionButton = useSelector(state => state.roles.actionButton);
  const errRoles = useSelector(state => state.roles.errRoles);
  const roleAccessGroups = useSelector(
    state => state.rolesAccessGroups.rolesAccessGroups
  );

  const [inputRole, setInputRole] = useState('');
  const [card, setCard] = useState(false);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleUpdate(data) {
    const updateRole = {
      ...data,
      application_key: application.application_key,
    };

    dispatch(RolesCreators.rolesUpdateRequest(role.uuid, updateRole, history));
  }

  function handleSubmit(data) {
    const roleData = {
      ...data,
      application_key: application.application_key,
    };

    dispatch(RolesCreators.rolesCreateRequest(roleData, history));
  }

  function handleUnlinkRole(accessGroup) {
    dispatch(
      RolesAccessGroupsCreators.rolesAccessGroupsDeleteRequest(accessGroup)
    );
  }

  useEffect(() => {
    if (role.uuid) {
      setInputRole(role.role);
      formRef.current.setData(role);
    }
  }, [role]);

  useEffect(() => {
    formRef.current.setErrors({});
    if (errRoles) {
      formRef.current.setErrors(errRoles);
    }
  }, [errRoles]);

  useEffect(() => {
    if (!application.uuid) history.goBack('/');
  }, [application, history]);

  return (
    <>
      <Header />
      <Container>
        {role.uuid && (
          <Card>
            <button
              type="button"
              onClick={() => {
                if (card) {
                  setCard(false);
                } else {
                  setCard(true);
                }
              }}
            >
              <strong>Grupos de Acceso</strong>
              <MdKeyboardArrowDown size={30} />
            </button>

            {card ? (
              <>
                <TableContainer isLength={roleAccessGroups.length > 1}>
                  {roleAccessGroups.length ? (
                    <div className="table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>Contrato</th>
                            <th>Nome</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          {roleAccessGroups.map(r => (
                            <tr key={r.uuid}>
                              <td>{r.access_group.contract_key}</td>
                              <td>{r.access_group.name}</td>
                              <td>
                                <UnlinkButton
                                  type="button"
                                  onClick={() => handleUnlinkRole(r)}
                                >
                                  Desvincular
                                </UnlinkButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p>O papel não tem vínculo com os grupos de accesso.</p>
                  )}
                </TableContainer>
              </>
            ) : null}
          </Card>
        )}

        <Unform
          ref={formRef}
          onSubmit={role.uuid ? handleUpdate : handleSubmit}
        >
          <Data>
            <div className="topLine">
              <h1>{role.uuid ? 'Dados do ' : 'Cadastro de '}Papel</h1>
              <Title>
                <strong>Chave do papel: </strong>

                <p>{`${application.application_key}.${inputRole}`}</p>
              </Title>
            </div>

            <Line>
              <InputContent>
                <strong>Nome: </strong>

                <Input
                  name="name"
                  placeholder="Nome do papel"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent>
                <strong>Papel: </strong>

                <Input
                  name="role"
                  onChange={e => setInputRole(e.target.value)}
                  placeholder="Papel. ex: admin"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
            </Line>

            <ButtonContent>
              {actionButton === 'toView' ? null : (
                <ButtonIn type="submit">
                  {role.uuid ? 'Salvar' : 'Cadastrar'}
                </ButtonIn>
              )}
            </ButtonContent>
          </Data>
        </Unform>
      </Container>
    </>
  );
};
export default Form;
