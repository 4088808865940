import React from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import { Typography } from '../Typography';

import { Styles } from './styles';

export const FlipCard = ({
  title,
  subTitle,
  description,
  image,
  color,
  styles,
  button,
}) => {
  return (
    <Styles>
      <div className="card-container">
        <div className="card-flip">
          <Card className="front">
            <Card.Img src={image} />

            <Card.Body>
              <Card.Title>
                <Typography size="xxl" color={color} style={styles}>
                  {title}
                </Typography>
              </Card.Title>

              <Typography color="secondary" size="lg">
                {subTitle}
              </Typography>
            </Card.Body>
          </Card>

          <Card className="back">
            <Card.Body>
              <Typography size="sm">{description}</Typography>

              {button}
            </Card.Body>
          </Card>
        </div>
      </div>
    </Styles>
  );
};

export default FlipCard;

FlipCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.object]).isRequired,
  button: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

FlipCard.defaultProps = {
  subTitle: '',
  description: '',
  color: '',
  image: '',
};
