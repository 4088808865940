import * as Yup from 'yup';

export default async function (data) {
  const regexRoute = /^[A-Za-z0-9-/?&:]+$/;

  const schemaValid = Yup.object().shape({
    level: Yup.string().required('Este campo é obrigatório'),
    method: Yup.string().required('Este campo é obrigatório.'),
    route: Yup.string()
      .matches(regexRoute, 'O formato do campo é inválido.')
      .required('Este campo é obrigatório.'),
    action: Yup.string().required('Este campo é obrigatório.'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
