import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 40px;
  margin-right: 40px;

  h1 {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.section`
  background: #fff;
  height: 100%;
  margin-top: 30px;
  border-radius: 12px;
  width: 70%;

  table {
    width: 100%;

    thead {
      background: #c9d3dd;
    }

    tr {
      margin: 20px;
      border: 1px solid #c9d3dd;
      height: 100%;
    }

    .action {
      width: 160px;
      display: flex;
      flex-direction: row;
    }

    th {
      color: #999;
      font-weight: bold;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;

      text-align: center;
      justify-content: center;
      padding: 10px 22px;
    }

    td {
      padding: 20px 32px;
      border: 0;
      font-weight: normal;
      text-align: center;
      justify-content: center;

      svg {
        cursor: pointer;
        transition: color 0.2s;
        margin-left: 10px;

        &:hover {
          color: #1f7ecc;
        }
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const ButtonContent = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  a {
    > button {
      padding: 15px 20px 15px 20px;
      border: 0;
      background: #1fcc85;
      border-radius: 4px;
      color: #fff;
      font-weight: bold;
      transition: background-color 0.2s;
      margin-right: 5px;

      :hover {
        background: ${shade(-0.2, '#1fcc85')};
      }
    }
  }
`;
