import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

export const ContentInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #c4c4c4;
  color: white;
  ${props =>
    props.isFocused &&
    css`
      border-color: #fff;
    `} ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

   svg {
    margin-right: 12px;
  }
  input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: 0;
    color: #ffffff;
    &::placeholder {
      color: white;
      opacity: 0.3;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  width: 30px;
  align-items: center;
  margin-right: 5px;
  margin-left: 16px;

  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    text-align: center;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
