import styled from 'styled-components';

export const Typography = styled.h1`
  margin: 0;
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props =>
    props.thin ? '100' : props.light ? '300' : props.bold ? '700' : 'regular'};
  font-style: ${props => (props.italic ? 'italic' : '')};

  font-size: ${props =>
    props.size === 'xs'
      ? props.theme.xs
      : props.size === 'sm'
      ? props.theme.sm
      : props.size === 'md'
      ? props.theme.md
      : props.size === 'lg'
      ? props.theme.lg
      : props.size === 'xl'
      ? props.theme.xl
      : ''};

  color: ${props =>
    props.color === 'primary'
      ? props.theme.primaryFontColor
      : props.color === 'secondary'
      ? props.theme.secondaryFontColor
      : props.color === 'facin'
      ? props.theme.facin
      : props.color === 'xmle'
      ? props.theme.xmle
      : props.color === 'cifa'
      ? props.theme.cifa
      : props.color === 'boleta'
      ? props.theme.boleta
      : props.color === 'support'
      ? props.theme.support
      : props.color === 'magento'
      ? props.theme.magento
      : ''};

  &:hover {
    color: ${props => (props.link ? 'black' : '')};
    cursor: pointer;
  }
`;
