import { call, put } from 'redux-saga/effects';

import { Creators as PermissionsCreators } from '../ducks/permissions';
import { Creators as PermissionsAccessGroupsCreators } from '../ducks/permissionsAccessGroups';
import { Creators as ContractPermissionsCreators } from '../ducks/contractPermissions';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';
import validatePermissions from '~/validators/permissions';

export function* create({ permission, history }) {
  try {
    yield validatePermissions(permission);

    const response = yield call(api.post, 'permissions', permission);

    const createPermission = response.data;
    yield put(PermissionsCreators.permissionsCreateSuccess(createPermission));
    toastSuccess(`Permissão criada com sucesso.`);
    history.goBack('/');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(PermissionsCreators.permissionsFailure(errors));
  }
}

export function* showById({ uuid, history }) {
  try {
    const response = yield call(api.get, `permissions/${uuid}`);

    const {
      permission_access_groups,
      contract_permissions,
      ...permission
    } = response.data;

    yield put(PermissionsCreators.permissionsShowSuccess(permission));
    yield put(
      PermissionsAccessGroupsCreators.permissionsAccessGroupsList(
        permission_access_groups
      )
    );
    yield put(
      ContractPermissionsCreators.contractPermissionsList(contract_permissions)
    );
    history.push('/permissions/form');
  } catch (err) {
    errorHandler(err, true);
    yield put(PermissionsCreators.permissionsFailure());
  }
}

export function* update({ uuid, permission, history }) {
  try {
    yield validatePermissions(permission);

    const response = yield call(api.put, `permissions/${uuid}`, permission);

    const updatePermission = response.data;
    yield put(PermissionsCreators.permissionsUpdateSuccess(updatePermission));
    toastSuccess('Permissão salvo com sucesso!');
    history.goBack('/');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(PermissionsCreators.permissionsFailure(errors));
  }
}

export function* remove({ permission }) {
  try {
    yield call(api.delete, `permissions/${permission.uuid}`);

    yield put(PermissionsCreators.permissionsDeleteSuccess(permission));
    toastSuccess(`Permissão ${permission.application_key} deletada.`);
  } catch (err) {
    errorHandler(err, true);
    yield put(PermissionsCreators.permissionsFailure({}));
  }
}
