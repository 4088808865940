import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  .row {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }
`;

export const SideBar = styled.div`
  border-top: 1px solid #f1f4f9;
  display: flex;
  flex-direction: column;
  width: 250px;
  background: #1f7ecc;
  max-height: 100%;
  border-radius: 0 4px 4px 0;

  h1 {
    margin-top: 20px;
    font-size: 19px;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    list-style: none;
    margin: 0;
    background: ${shade(0.1, '#1f7ecc')};

    button {
      margin-top: 10px;
      width: 100%;
      height: 40px;
      background: ${shade(0.1, '#1f7ecc')};
      border: 0;
      color: #ffff;
      font-weight: 500;
      font-size: 14px;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#ffff')};
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #96d9ff;
    border-radius: 4px;
  }
`;
