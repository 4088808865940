import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { MdModeEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Creators as PermissionsCreators } from '~/store/ducks/permissions';
import { Creators as ContractPermissionsCreators } from '~/store/ducks/contractPermissions';

import Header from '~/components/Header';

import { Container, Content, TableContainer, ButtonContent } from './styles';

const Permissions = () => {
  const application = useSelector(state => state.applications.application);
  const permissions = useSelector(state => state.permissions.permissions);

  const dispatch = useDispatch();
  const history = useHistory();

  function handleEditPermission(permission, { actionButton }) {
    dispatch(
      PermissionsCreators.permissionsShowRequest(
        permission.uuid,
        actionButton,
        history
      )
    );
  }

  function handleRemovePermission(permission) {
    dispatch(PermissionsCreators.permissionsDeleteRequest(permission));
  }

  useEffect(() => {
    dispatch(PermissionsCreators.permissionsClear());
    dispatch(ContractPermissionsCreators.contractPermissionsClear(true));
  }, [dispatch]);

  useEffect(() => {
    if (!application.uuid) history.push('/applications');
  }, [application, history]);

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="topLine">
            <Link to="/permissions/form">
              <ButtonContent type="button">
                Criar uma nova Permissão
              </ButtonContent>
            </Link>
          </div>

          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th>Ação</th>
                  <th>Nível</th>
                  <th>Método</th>
                  <th>Rota</th>
                  <th className="action">Ações</th>
                </tr>
              </thead>

              <tbody>
                {permissions?.map(permission => (
                  <tr key={permission.id}>
                    <td>{permission.action}</td>
                    <td> {permission.level} </td>
                    <td className="title">{permission.method}</td>
                    <td>{permission.route}</td>
                    <td className="action">
                      <MdRemoveRedEye
                        className="iconToView"
                        size={20}
                        onClick={() =>
                          handleEditPermission(permission, {
                            actionButton: 'toView',
                          })
                        }
                      />

                      <MdModeEdit
                        className="iconToEdit"
                        onClick={() =>
                          handleEditPermission(permission, {
                            actionButton: 'toEdit',
                          })
                        }
                        size={20}
                      />

                      <MdDelete
                        className="iconDelete"
                        size={20}
                        onClick={() => handleRemovePermission(permission)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Content>
      </Container>
    </>
  );
};

export default Permissions;
