/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  contractPermissionsList: ['contractPermissions'],
  contractPermissionsAdd: ['permission'],
  contractPermissionsRemove: ['permission'],
  contractPermissionsFailure: ['errContractPermissions'],
  contractPermissionsClear: ['permission'],
});

export const INITIAL_STATE = {
  contractPermissions: [],
  loadingContractPermissions: false,
  errContractPermissions: {},
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.contractPermissions = action.contractPermissions;
    draft.loadingContractPermissions = false;
  });

const add = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.contractPermissions = [
      action.permission[0],
      ...draft.contractPermissions.filter(
        permission =>
          permission.permission_key !== action.permission[0].permission_key
      ),
    ];
    draft.loadingContractPermissions = false;
  });

const remove = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.contractPermissions = draft.contractPermissions.filter(
      permission => permission.uuid !== action.permission.uuid
    );
    draft.loadingContractPermissions = false;
  });

const failure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errContractPermissions = action.errContractPermissions;
    draft.loadingContractPermissions = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.permission) draft.contractPermissions = [];
    draft.errContractPermissions = {};
    draft.loadingContractPermissions = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CONTRACT_PERMISSIONS_LIST]: list,
  [Types.CONTRACT_PERMISSIONS_ADD]: add,
  [Types.CONTRACT_PERMISSIONS_REMOVE]: remove,
  [Types.CONTRACT_PERMISSIONS_FAILURE]: failure,
  [Types.CONTRACT_PERMISSIONS_CLEAR]: toClear,
});
