import { combineReducers } from 'redux';

import { reducer as auth } from './auth';
import { reducer as applications } from './applications';
import { reducer as permissions } from './permissions';
import { reducer as companies } from './companies';
import { reducer as branches } from './branches';
import { reducer as states } from './states';
import { reducer as users } from './users';
import { reducer as usersCompanies } from './usersCompanies';
import { reducer as contracts } from './contracts';
import { reducer as contractPermissions } from './contractPermissions';
import { reducer as accessGroups } from './accessGroups';
import { reducer as usersAccessGroups } from './usersAccessGroups';
import { reducer as roles } from './roles';
import { reducer as rolesAccessGroups } from './rolesAccessGroups';
import { reducer as permissionsAccessGroups } from './permissionsAccessGroups';
import { reducer as branchesAccessGroups } from './branchesAccessGroups';
import { reducer as companiesContracts } from './companiesContracts';

export default combineReducers({
  auth,
  applications,
  permissions,
  companies,
  branches,
  states,
  users,
  usersCompanies,
  contracts,
  contractPermissions,
  accessGroups,
  usersAccessGroups,
  roles,
  rolesAccessGroups,
  permissionsAccessGroups,
  branchesAccessGroups,
  companiesContracts,
});
