/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  firstSignInRequest: ['email', 'password', 'history'],
  firstSignInSuccess: ['user', 'email', 'password', 'companies', 'branches'],
  firstSignInFailure: ['errAuth'],
  signInRequest: ['email', 'password', 'branchKey', 'history'],
  signInSuccess: ['token', 'loggedBranch', 'user'],
  signInFailure: ['errAuth'],
  signOut: [],
  changeCompanyRequest: ['branchKey', 'history'],
  changeCompanySuccess: ['token', 'loggedBranch'],
  changeCompanyFailure: [],
  authToClear: [],
  authForgotPasswordRequest: ['email', 'history'],
  authForgotPasswordSuccess: [],
  authFailure: ['errAuth'],
  authUpdateUser: ['user'],
});

export const INITIAL_STATE = {
  email: null,
  password: null,
  loadingAuth: false,
  user: {},
  companies: [],
  branches: [],
  brancheKey: '',
  token: {},
  loggedBranch: {},
  signed: false,
  firstSigned: false,
  errAuth: {},
};

const firstSignInRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.email = action.email;
    draft.password = action.password;
    draft.loadingAuth = true;
    draft.firstSigned = false;
  });

const firstSignInSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.user = action.user;
    draft.email = action.email;
    draft.password = action.password;
    draft.companies = action.companies;
    draft.branches = action.branches;
    draft.loadingAuth = false;
    draft.firstSigned = true;
  });

const firstSignInFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errAuth = action.errAuth;
    draft.loadingAuth = false;
    draft.firstSigned = false;
  });

const signInRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.email = action.email;
    draft.password = action.password;
    draft.brancheKey = action.brancheKey;
    draft.loadingAuth = true;
  });

const signInSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.token = action.token;
    draft.loggedBranch = action.loggedBranch;
    draft.user = action.user;
    draft.loadingAuth = false;
    draft.signed = true;
    draft.password = null;
  });

const signInFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.signed = false;
    draft.errAuth = action.errAuth;
    draft.loadingAuth = false;
  });

const signOut = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.email = null;
    draft.password = null;
    draft.loadingAuth = false;
    draft.user = {};
    draft.companies = [];
    draft.branches = [];
    draft.brancheKey = '';
    draft.token = {};
    draft.loggedBranch = {};
    draft.signed = false;
    draft.firstSigned = false;
    draft.errAuth = {};
  });

const changeCompanyRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAuth = true;
  });

const changeCompanySuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.token = action.token;
    draft.loggedBranch = action.loggedBranch;
    draft.loadingAuth = false;
  });

const changeCompanyFailure = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAuth = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.errAuth = {};
  });

const forgotPasswordRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAuth = true;
  });

const forgotPasswordSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingAuth = false;
  });

const authFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errAuth = action.errAuth;
    draft.loadingAuth = false;
  });

const updateUser = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (draft.user.uuid === action.user.uuid)
      draft.user = { ...action.user, is_manager: draft.user.is_manager };
    draft.loadingAuth = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRST_SIGN_IN_REQUEST]: firstSignInRequest,
  [Types.FIRST_SIGN_IN_SUCCESS]: firstSignInSuccess,
  [Types.FIRST_SIGN_IN_FAILURE]: firstSignInFailure,
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,
  [Types.SIGN_OUT]: signOut,
  [Types.CHANGE_COMPANY_REQUEST]: changeCompanyRequest,
  [Types.CHANGE_COMPANY_SUCCESS]: changeCompanySuccess,
  [Types.CHANGE_COMPANY_FAILURE]: changeCompanyFailure,
  [Types.AUTH_TO_CLEAR]: toClear,
  [Types.AUTH_FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.AUTH_FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.AUTH_FAILURE]: authFailure,
  [Types.AUTH_UPDATE_USER]: updateUser,
});
