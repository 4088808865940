import { call, put } from 'redux-saga/effects';

import { Creators as PermissionsAccessGroupsCreators } from '../ducks/permissionsAccessGroups';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

export function* remove({ accessGroup }) {
  try {
    yield call(api.delete, `permission-access-groups/${accessGroup.uuid}`);

    yield put(
      PermissionsAccessGroupsCreators.permissionsAccessGroupsDeleteSuccess(
        accessGroup
      )
    );
    toastSuccess('Permissão desvinculada do grupo de accesso!');
  } catch (err) {
    errorHandler(err, true);
    yield put(PermissionsAccessGroupsCreators.permissionsAccessGroupsFailure());
  }
}
