import * as Yup from 'yup';

export default async function (data) {
  const schemaValid = Yup.object().shape({
    application_key: Yup.string().required('Este campo é obrigatório'),
    role: Yup.string().required('Este campo é obrigatório'),
    name: Yup.string().required('Este campo é obrigatório'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
