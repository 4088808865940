import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MdModeEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Creators as CompaniesCreators } from '~/store/ducks/companies';

import Settings from '../Settings';
import Table from '~/components/Table';
import LoadingComponent from '~/components/LoadingComponent';

const Companies = () => {
  const companiesRegisters = useSelector(state => state.companies.companies);
  const { loadingCompanies } = useSelector(state => state.companies);
  const userAuth = useSelector(state => state.auth.user);

  const dispatch = useDispatch();
  const history = useHistory();

  const [pageLoaded, setPageLoaded] = useState(false);

  function handleDeleteCompany(company) {
    dispatch(CompaniesCreators.companiesDeleteRequest(company));
  }

  function handleEditCompany(company, { actionButton }) {
    dispatch(
      CompaniesCreators.companiesShowRequest(company.uuid, actionButton)
    );
    history.push(`/companies/form`);
  }

  const header = [
    {
      key: 'name',
      name: 'Empresa',
    },
    {
      key: 'root_register_number',
      name: 'CNPJ',
    },
  ];

  const filterBy = [
    {
      key: 'name',
      name: 'Nome',
      type: 'text',
      options: [],
    },
    {
      key: 'root_register_number',
      name: 'CNPJ',
      type: 'text',
      options: [],
    },
  ];

  const headerActions = [
    {
      text: 'Criar uma nova empresa',
      action: () => {
        history.push('/companies/form');
      },
      canSee: true,
    },
  ];

  const bodyActions = [
    {
      key: 'show',
      action: company => {
        return (
          <MdRemoveRedEye
            className="iconToView"
            size={20}
            onClick={() =>
              handleEditCompany(company, { actionButton: 'toView' })
            }
          />
        );
      },
      canSee: true,
    },
    {
      key: 'edit',
      action: company => {
        return (
          <MdModeEdit
            className="iconToEdit"
            size={20}
            onClick={() =>
              handleEditCompany(company, { actionButton: 'toEdit' })
            }
          />
        );
      },
      canSee: true,
    },
    {
      key: 'delete',
      action: company => {
        return (
          <MdDelete
            className="iconDelete"
            size={20}
            onClick={() => handleDeleteCompany(company)}
          />
        );
      },
      canSee: true,
    },
  ];

  useEffect(() => {
    dispatch(CompaniesCreators.companiesGetAllRequest());
    dispatch(CompaniesCreators.companiesClear());
  }, [dispatch, userAuth]);

  useEffect(() => {
    if (!pageLoaded && companiesRegisters.length) {
      setPageLoaded(true);
    }
  }, [pageLoaded, companiesRegisters]);

  return (
    <Settings>
      {pageLoaded ? (
        <Table
          registers={companiesRegisters}
          filterBy={filterBy}
          header={header}
          headerActions={headerActions}
          bodyActions={bodyActions}
          loading={loadingCompanies}
        />
      ) : (
        <LoadingComponent />
      )}
    </Settings>
  );
};
export default Companies;
