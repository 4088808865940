import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import { BsArrowLeftShort } from 'react-icons/bs';

import { Creators as AuthCreators } from '~/store/ducks/auth';

import api from '~/services/api';

import { DropdownFilterable } from '~/components/DropdownFilterable';
import { Button } from '~/components/Button';
import { Typography } from '~/components/Typography';

import { toastWarning } from '~/utils/Toast';

import { Styles } from './styles';

const SelectCompany = () => {
  const companies = useSelector(state => state.auth.companies);
  const signed = useSelector(state => state.auth.signed);
  const branches = useSelector(state => state.auth.branches);
  const email = useSelector(state => state.auth.email);
  const password = useSelector(state => state.auth.password);
  const loadingAuth = useSelector(state => state.auth.loadingAuth);
  const errAuth = useSelector(state => state.auth.errAuth);

  const [companySelect, setCompanySelect] = useState('');
  const [companiesList, setCompaniesList] = useState([]);
  const [companyFilterFieldValue, setCompanyFilterFieldValue] = useState('');

  const [branchSelect, setBranchSelect] = useState('');
  const [branchesList, setBranchesList] = useState([]);
  const [branchFilterFieldValue, setBranchFilterFieldValue] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  function validateFields() {
    if (companySelect && branchSelect) {
      return true;
    }
    if (companySelect === '' && branchSelect === '') {
      toastWarning('É necessário selecionar a Empresa e a Filial.');
      return false;
    }
    if (companySelect === '') {
      toastWarning('É necessário selecionar a Empresa.');
      return false;
    }
    toastWarning('É necessário selecionar a Filial.');
    return false;
  }

  function handleChangeCompany() {
    if (validateFields()) {
      const branchKey = branchSelect.slice(0, 14);
      dispatch(AuthCreators.changeCompanyRequest(branchKey, history));
    }
  }

  function handleSignIn() {
    if (validateFields()) {
      const branchKey = branchSelect.slice(0, 14);
      dispatch(AuthCreators.signInRequest(email, password, branchKey, history));
    }
  }

  function handlebackSigIn() {
    if (signed) {
      history.push('/dashboard');
    } else {
      dispatch(AuthCreators.signOut());
      history.push('/');
    }
  }

  function handleFilterCompany(value) {
    const parsedCompanies = companies.map(company => {
      return {
        id: company.company_key,
        name: `${company.company_key} - ${company.name}`,
      };
    });
    const filteredCompanies = parsedCompanies.filter(company =>
      company.name.toLowerCase().includes(value.toLowerCase())
    );

    setCompaniesList(filteredCompanies);
    setCompanyFilterFieldValue(value);
  }

  function handleFilterBranch(value) {
    const companyKey = companySelect.slice(0, 8);
    const parsedBranches = branches
      .filter(branch => branch.company_key === companyKey)
      .map(branch => {
        return {
          id: branch.branch_key,
          name: `${branch.branch_key} - ${branch.first_name}`,
        };
      });
    const filteredBranches = parsedBranches.filter(branch =>
      branch.name.toLowerCase().includes(value.toLowerCase())
    );

    setBranchesList(filteredBranches);
    setBranchFilterFieldValue(value);
  }

  useEffect(() => {
    if (companySelect === 'Selecionar Empresa') setBranchSelect('');
    setBranchSelect('');
  }, [companySelect]);

  useEffect(() => {
    function listCompany() {
      const parsedCompanies = companies.map(company => {
        return {
          id: company.company_key,
          name: `${company.company_key} - ${company.name}`,
        };
      });
      setCompaniesList(parsedCompanies);
    }

    listCompany();
  }, [companies]);

  useEffect(() => {
    function listBranch() {
      const companyKey = companySelect.slice(0, 8);

      const parsedBranches = branches
        .filter(branch => branch.company_key === companyKey)
        .map(branch => {
          return {
            id: branch.branch_key,
            name: `${branch.branch_key} - ${branch.first_name}`,
          };
        });

      setBranchesList(parsedBranches);
    }
    if (companySelect !== '') listBranch();
  }, [companySelect, branches]);

  useEffect(() => {
    dispatch(AuthCreators.authToClear());
  }, [dispatch]);

  return (
    <Container fluid>
      <Styles>
        <Row>
          <Col sm={6} md={5} lg={4} xl={3}>
            <div className="login-box">
              <Typography bold size="xxl" color="primary">
                C O D E
              </Typography>

              <Typography size="xs" color="primary" className="mt-4 mb-4">
                Selecione sua empresa no menu abaixo:
              </Typography>

              <DropdownFilterable
                name="company_key"
                placeholder="Empresa"
                filterRegister={handleFilterCompany}
                setRegisterSelected={setCompanySelect}
                selectedRegister={companySelect}
                filterFieldValue={companyFilterFieldValue}
                options={companiesList}
                error={errAuth.company_key}
              />

              <DropdownFilterable
                name="branch_key"
                placeholder="Filial"
                filterRegister={handleFilterBranch}
                setRegisterSelected={setBranchSelect}
                selectedRegister={branchSelect}
                filterFieldValue={branchFilterFieldValue}
                options={branchesList}
                error={errAuth.branch_key}
              />

              <Button
                type="primary-filled"
                size="md"
                block
                onClick={
                  api.defaults.headers.Authorization
                    ? handleChangeCompany
                    : handleSignIn
                }
              >
                {loadingAuth ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Continuar'
                )}
              </Button>

              <Typography
                thin
                link
                color="primary"
                size="xs"
                onClick={handlebackSigIn}
              >
                <BsArrowLeftShort size={16} />
                Voltar
              </Typography>
            </div>
          </Col>
        </Row>
      </Styles>
    </Container>
  );
};

export default SelectCompany;
