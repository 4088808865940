/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  branchesList: ['branches'],
  branchesCreateRequest: ['branch', 'history'],
  branchesCreateSuccess: ['branch'],
  branchesSuccess: [],
  branchesCreateFailure: ['errBranches'],
  branchesDeleteRequest: ['branch'],
  branchesDeleteSuccess: ['branch'],
  branchesUpdateRequest: ['branch', 'uuid', 'history'],
  branchesUpdateSuccess: ['branch'],
  branchesUpdateFailure: ['errBranches'],
  branchesShowRequest: ['uuid', 'actionButton'],
  branchesShowSuccess: ['branch'],
  branchesFailure: ['errBranches'],
  branchesClear: ['signOut'],
});

export const INITIAL_STATE = {
  branches: [],
  branch: {},
  uuid: null,
  actionButton: null,
  loadingBranches: false,
  errBranches: {},
};

const branchesList = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.branches = action.branches;
    draft.loadingBranches = false;
  });

const createRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.branch = action.branch;
    draft.loadingBranches = true;
  });

const createSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.branch = action.branch;
    draft.branches = [action.branch, ...draft.branches];
    draft.loadingBranches = false;
  });

const branchesSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingBranches = false;
  });

const createFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errBranches = action.errBranches;
    draft.loadingBranches = false;
  });

const deleteRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingBranches = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const deleteBranch = state.branches.find(
      ep => ep.uuid === action.branch.uuid
    );
    const deleteIndex = state.branches.indexOf(deleteBranch);
    draft.branches.splice(deleteIndex, 1);
    draft.loadingBranches = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.actionButton = action.actionButton;
    draft.loadingBranches = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.branch = action.branch;
    draft.loadingBranches = false;
  });

const branchesFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errBranches = action.errBranches;
    draft.loadingBranches = false;
  });

const updateRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.loadingBranches = true;
  });

const updateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const checkBranch = state.branches.find(
      isBranch => isBranch.uuid === action.branch.uuid
    );
    const branchIndex = state.branches.indexOf(checkBranch);
    draft.branches.splice(branchIndex, 1);
    draft.branches = [action.branch, ...draft.branches];
    draft.loadingBranches = false;
  });

const updateFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errBranches = action.errBranches;
    draft.loadingBranches = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.branches = [];
    draft.branch = {};
    draft.errBranches = {};
    draft.uuid = null;
    draft.actionButton = null;
    draft.loadingBranches = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BRANCHES_LIST]: branchesList,
  [Types.BRANCHES_CREATE_REQUEST]: createRequest,
  [Types.BRANCHES_CREATE_SUCCESS]: createSuccess,
  [Types.BRANCHES_SUCCESS]: branchesSuccess,
  [Types.BRANCHES_CREATE_FAILURE]: createFailure,
  [Types.BRANCHES_DELETE_REQUEST]: deleteRequest,
  [Types.BRANCHES_DELETE_SUCCESS]: deleteSuccess,
  [Types.BRANCHES_SHOW_REQUEST]: showRequest,
  [Types.BRANCHES_SHOW_SUCCESS]: showSuccess,
  [Types.BRANCHES_FAILURE]: branchesFailure,
  [Types.BRANCHES_UPDATE_REQUEST]: updateRequest,
  [Types.BRANCHES_UPDATE_SUCCESS]: updateSuccess,
  [Types.BRANCHES_UPDATE_FAILURE]: updateFailure,
  [Types.BRANCHES_CLEAR]: toClear,
});
