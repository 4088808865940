import jsonp from 'jsonp';

import { numberUnmask } from '~/utils/mask';
import { setFormData } from '~/utils/form';
import { toastWarning } from '~/utils/Toast';

async function SearchCnpjService({
  setLoadingCnpj,
  formRef,
  handleSearchZipCode,
}) {
  setLoadingCnpj(true);

  const cpf_cnpj =
    formRef.current && formRef.current.getFieldValue('register_number');
  const cnpjUnmasked = numberUnmask(cpf_cnpj);

  if (cnpjUnmasked && cnpjUnmasked.length === 14) {
    await new Promise(resolve =>
      jsonp(
        `https://www.receitaws.com.br/v1/cnpj/${cnpjUnmasked}`,
        (error, res) => {
          if (error) {
            const errorParsed = {
              error,
              title: 'Erro ao buscar CNPJ',
              local: 'SearchCnpjService',
            };

            toastWarning(errorParsed.title);
          } else if (!res.erro && !res.message) {
            const auxTel = res.telefone.split('/');

            const formData = {
              first_name: res.nome,
              last_name: res.fantasia,
              email: res.email,
              ddd1: numberUnmask(auxTel[0]).slice(0, 2),
              telephone1: numberUnmask(auxTel[0]).slice(2),
              zip_code: numberUnmask(res.cep),
            };

            if (auxTel.length > 1) {
              formData.ddd2 = numberUnmask(auxTel[1]).slice(0, 2);
              formData.telephone2 = numberUnmask(auxTel[1]).slice(2);
            }

            setFormData({ formRef, formData });

            if (res.cep) handleSearchZipCode();
          } else {
            const errorParsed = {
              error: {
                title: 'CNPJ inválido',
                message: 'Favor verificar o CNPJ',
              },
            };

            toastWarning(errorParsed.error.title);
          }
          resolve();
        }
      )
    );
  } else {
    const errorParsed = {
      error: {
        title: 'Quantidade inválida de caracteres',
        message: 'Somente é possível buscar CNPJ e com 14 caracteres',
      },
    };

    toastWarning(errorParsed.error.title);
  }

  setLoadingCnpj(false);
}

export default SearchCnpjService;
