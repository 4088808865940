/* eslint-disable camelcase */
import { call, put } from 'redux-saga/effects';
import { Creators as AccessGroupsCreators } from '../ducks/accessGroups';

import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';
import validateAccessGroupUpdate from '~/validators/accessGroupsUpdate';
import validateAccessGroupCreate from '~/validators/accessGroupsCreate';

export function* create({ accessGroup, history }) {
  try {
    yield validateAccessGroupCreate(accessGroup);
    const response = yield call(api.post, `access-groups`, accessGroup);

    const newAccessGroup = response.data;

    yield put(AccessGroupsCreators.accessGroupsCreateSuccess(newAccessGroup));
    toastSuccess('Grupo de acesso criado com successo!');
    history.goBack('/');
  } catch (err) {
    const errors = errorHandler(err);
    yield put(AccessGroupsCreators.accessGroupsFailure(errors));
  }
}

export function* show({ uuid, history }) {
  try {
    const response = yield call(api.get, `access-groups/${uuid}`);

    const accessGroup = response.data;

    yield put(AccessGroupsCreators.accessGroupsShowSuccess(accessGroup));
    history.push('/access-groups');
  } catch (err) {
    errorHandler(err, true);
    yield put(AccessGroupsCreators.accessGroupsFailure());
  }
}

export function* update({ updateAccessGroup, uuid, history }) {
  try {
    yield validateAccessGroupUpdate(updateAccessGroup);
    const { data } = yield call(
      api.put,
      `access-groups/${uuid}`,
      updateAccessGroup
    );

    yield put(AccessGroupsCreators.accessGroupsUpdateSuccess(data));
    toastSuccess('Grupo de acesso salvo com successo!');
    history.goBack('/');
  } catch (err) {
    const errors = errorHandler(err);
    yield put(AccessGroupsCreators.accessGroupsFailure(errors));
  }
}

export function* getNewAccessGroup({ uuid, history }) {
  try {
    const response = yield call(api.get, `new-access-group/${uuid}`);

    const newAccessGroup = response.data;
    yield put(AccessGroupsCreators.accessGroupsNewSuccess(newAccessGroup));
    history.push('/access-groups');
  } catch (err) {
    const errors = errorHandler(err);
    yield put(AccessGroupsCreators.accessGroupsFailure(errors));
  }
}

export function* deleteAccessGroup({ accessGroup }) {
  try {
    yield call(api.delete, `access-groups/${accessGroup.uuid}`);

    yield put(AccessGroupsCreators.accessGroupsDeleteSuccess(accessGroup));
    toastSuccess('Grupo de acesso deletado com successo!');
  } catch (err) {
    errorHandler(err, true);

    yield put(AccessGroupsCreators.accessGroupsFailure());
  }
}
