import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, TableContainer } from './styles';

const Permissions = ({ value, setValue }) => {
  const accessGroupPermissions = useSelector(
    state => state.accessGroups.accessGroup.permissions
  );
  const actionButton = useSelector(state => state.accessGroups.actionButton);
  const accessGroup = useSelector(state => state.accessGroups.accessGroup);

  function handleAddPermission(e, permission) {
    const { checked } = e.target;

    setValue({
      ...value,
      [permission.permission_key]: checked,
    });
  }

  function handleSelectAll(e) {
    const { checked } = e.target;

    const auxObj = {};
    accessGroupPermissions.forEach(permission => {
      auxObj[permission.permission_key] = checked;
    });

    setValue(auxObj);
  }

  return (
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              <th>Nível</th>
              <th>Descrição</th>
            </tr>
          </thead>

          {accessGroupPermissions && accessGroupPermissions[0] ? (
            <tbody>
              {accessGroupPermissions.map(permission => (
                <tr key={permission.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddPermission(e, permission)}
                      checked={!!value[permission.permission_key]}
                      disabled={actionButton === 'toView'}
                    />
                  </td>
                  <td>{permission.level}</td>
                  <td>{permission.action}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {accessGroup.permission?.map(permission => (
                <tr key={permission.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => handleAddPermission(e, permission)}
                    />
                  </td>
                  <td>{permission.level}</td>
                  <td>{permission.action}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </TableContainer>
    </Container>
  );
};

export default Permissions;

Permissions.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setValue: PropTypes.func,
};

Permissions.defaultProps = {
  setValue: () => {},
};
