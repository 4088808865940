export const theme = {
  // Colors
  primaryColor: '#1F7ECC',
  primaryFontColor: '#FFF',
  secondaryFontColor: '#999',
  fontFamily: `'Roboto', sans-serif`,

  // Font Sizing
  xs: '80%',
  sm: '90%',
  md: '100%',
  lg: '125%',
  xl: '181%',

  // Font-Colors
  facin: '#4FACC1',
  xmle: '#F50057',
  cifa: '#00B0FF',
  boleta: '#6C63FF',
  support: '#00BFA6',
  magento: '#F9A826',
};
