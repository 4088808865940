import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

export const DropdownSelect = ({
  title,
  notShowTitle,
  action,
  error,
  valueSelect,
  keyName,
  valueName,
  disabled,
  items,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container isErrored={!!error} isFocused={isFocused}>
      <Form.Control
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className="select"
        as="select"
        size="sm"
        onChange={action}
        disabled={disabled}
        value={valueSelect}
        custom
      >
        {!notShowTitle && <option>{title}</option>}
        {items.map(item => (
          <option value={item[keyName]} key={item[keyName]}>
            {item[valueName]}
          </option>
        ))}
      </Form.Control>

      {error && (
        <Error title={error}>
          <FiAlertCircle size={20} color="#c53030" />
        </Error>
      )}
    </Container>
  );
};

DropdownSelect.propTypes = {
  title: PropTypes.string.isRequired,
  notShowTitle: PropTypes.bool,
  action: PropTypes.func.isRequired,
  error: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.array]),
  disabled: PropTypes.bool,
  valueSelect: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired,
};

DropdownSelect.defaultProps = {
  notShowTitle: false,
  error: '',
  items: [],
  valueSelect: '',
  disabled: false,
};
