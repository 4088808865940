import styled, { keyframes, css } from 'styled-components';

import { shade } from 'polished';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(500px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Styles = styled.div`
  .btn {
    margin: 10% 0;
  }
  .row {
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .login-box {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryFontColor};
    padding: 10% 5%;

    animation: ${appearFromRight} 1s;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #c4c4c4;
  color: white;
  ${props =>
    props.isFocused &&
    css`
      border-color: #fff;
    `} ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

   svg {
    margin-right: 12px;
  }
  input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: 0;
    color: #ffff;
    &::placeholder {
      color: ${shade(0.2, '#fff')};
      opacity: 0.3;
    }
  }
`;

export const DropdownContent = styled.div`
  margin: 20px 0 20px;
  background: #fff;
  border-radius: 4px;
`;
