/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  contractsGetAllRequest: [],
  contractsGetAllSuccess: ['contracts'],
  contractsCreateRequest: ['contract', 'history'],
  contractsCreateSuccess: ['contract'],
  contractsSuccess: [],
  contractsCreateFailure: ['errContracts'],
  contractsDeleteRequest: ['contract'],
  contractsDeleteSuccess: ['contract'],
  contractsUpdateRequest: ['contract', 'uuid', 'history'],
  contractsUpdateSuccess: [],
  contractsUpdateFailure: ['errContracts'],
  contractsShowRequest: ['uuid', 'actionButton', 'history'],
  contractsShowSuccess: ['contract'],
  contractsFailure: ['errContracts'],
  contractsClear: ['signOut'],
  contractsClearErrors: [],
});

export const INITIAL_STATE = {
  contracts: [],
  contract: {},
  uuid: null,
  actionButton: null,
  loadingContracts: false,
  errContracts: {},
};

const getAllRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingContracts = true;
  });

const getAllSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.contracts = action.contracts;
    draft.loadingContracts = false;
  });

const createRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.contract = action.contract;
    draft.loadingContracts = true;
  });

const createSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.contract = action.contract;
    draft.contracts = [...draft.contracts, action.contract];
    draft.loadingContracts = false;
  });

const contractSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingContracts = false;
  });

const createFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errContracts = action.errContracts;
    draft.loadingContracts = false;
  });

const deleteRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingContracts = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const deleteContract = state.contracts.find(
      isContract => isContract.uuid === action.contract.uuid
    );
    const deleteIndex = state.contracts.indexOf(deleteContract);
    draft.contracts.splice(deleteIndex, 1);
    draft.loadingContracts = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.actionButton = action.actionButton;
    draft.loadingContracts = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.contract = action.contract;
    draft.loadingContracts = false;
  });

const contractFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errContracts = action.errContracts;
    draft.loadingContracts = false;
  });

const updateRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.uuid = action.uuid;
    draft.loadingContracts = true;
  });

const updateSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingContracts = false;
  });

const updateFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errContracts = action.errContracts;
    draft.loadingContracts = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.contracts = [];
    draft.contract = {};
    draft.errContracts = {};
    draft.uuid = null;
    draft.actionButton = null;
    draft.loadingContracts = false;
  });

const clearErrors = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.errContracts = {};
    draft.loadingContracts = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CONTRACTS_GET_ALL_REQUEST]: getAllRequest,
  [Types.CONTRACTS_GET_ALL_SUCCESS]: getAllSuccess,
  [Types.CONTRACTS_CREATE_REQUEST]: createRequest,
  [Types.CONTRACTS_CREATE_SUCCESS]: createSuccess,
  [Types.CONTRACTS_SUCCESS]: contractSuccess,
  [Types.CONTRACTS_CREATE_FAILURE]: createFailure,
  [Types.CONTRACTS_DELETE_REQUEST]: deleteRequest,
  [Types.CONTRACTS_DELETE_SUCCESS]: deleteSuccess,
  [Types.CONTRACTS_SHOW_REQUEST]: showRequest,
  [Types.CONTRACTS_SHOW_SUCCESS]: showSuccess,
  [Types.CONTRACTS_FAILURE]: contractFailure,
  [Types.CONTRACTS_UPDATE_REQUEST]: updateRequest,
  [Types.CONTRACTS_UPDATE_SUCCESS]: updateSuccess,
  [Types.CONTRACTS_UPDATE_FAILURE]: updateFailure,
  [Types.CONTRACTS_CLEAR]: toClear,
  [Types.CONTRACTS_CLEAR_ERRORS]: clearErrors,
});
