import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 40px;
  margin-right: 40px;

  h1 {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const IconsButton = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  width: 80px;
  align-items: center;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const TableContainer = styled.section`
  background: #fff;
  height: 100%;
  margin-top: 30px;
  border-radius: 12px;
  width: 70%;

  table {
    width: 100%;

    thead {
      background: #c9d3dd;
    }

    tr {
      margin: 20px;
      border: 1px solid #c9d3dd;
    }

    th {
      color: #92a6bb;
      font-weight: bold;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      padding: 10px 22px;
    }

    td {
      padding: 20px 32px;
      border: 0;
      font-weight: normal;
      text-align: center;
      justify-content: center;

      svg {
        cursor: pointer;
        transition: color 0.2s;
        margin-left: 10px;
      }

      .iconToView {
        &:hover {
          color: #1f7ecc;
        }
      }

      .iconToEdit {
        &:hover {
          color: #1fcc85;
        }
      }

      .iconDelete {
        &:hover {
          color: #c53030;
        }
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const ButtonContent = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ButtonCreateUser = styled.button`
  padding: 15px 20px 15px 20px;
  border: 0;
  background: #1fcc85;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.2s;
  margin-right: 5px;

  :hover {
    background: ${shade(-0.2, '#1fcc85')};
  }
`;

export const ButtonLinkUser = styled.button`
  padding: 15px 20px 15px 20px;
  border: 0;
  background: #3292e0;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.2s;
  margin-left: 5px;

  :hover {
    background: ${shade(-0.4, '#3292e0')};
  }
`;

export const UserData = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  h1 {
    font-size: 22px;
    color: #65ade8;
  }

  @media (max-width: 950px) {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const EmailContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const CompanyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  div {
    width: 100%;
  }
`;

export const ButtonLinkContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  > button {
    padding: 15px 20px 15px 20px;
    border: 0;
    background: #1fcc85;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.2s;

    :hover {
      background: ${shade(-0.2, '#1fcc85')};
    }
  }
`;
