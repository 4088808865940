/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  rolesList: ['roles'],
  rolesCreateRequest: ['role', 'history'],
  rolesCreateSuccess: ['role'],
  rolesFailure: ['errRoles'],
  rolesShowRequest: ['uuid', 'actionButton', 'history'],
  rolesShowSuccess: ['role'],
  rolesClear: ['signOut'],
  rolesUpdateRequest: ['uuid', 'role', 'history'],
  rolesUpdateSuccess: ['role'],
  rolesDeleteRequest: ['role'],
  rolesDeleteSuccess: ['role'],
});

export const INITIAL_STATE = {
  roles: [],
  role: {},
  actionButton: null,
  loadingRoles: false,
  errRoles: {},
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.roles = action.roles;
    draft.loadingRoles = false;
  });

const createRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingRoles = true;
  });

const createSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.roles = [action.role, ...draft.roles];
    draft.loadingRoles = false;
  });

const rolesFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errRoles = action.errRoles;
    draft.loadingRoles = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.actionButton = action.actionButton;
    draft.loadingRoles = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.role = action.role;
    draft.loadingRoles = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.roles = [];
    draft.role = {};
    draft.actionButton = null;
    draft.errRoles = {};
    draft.loadingRoles = false;
  });

const updateRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingRoles = true;
  });

const updateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.roles = [
      action.role,
      ...draft.roles.filter(role => role.uuid !== action.role.uuid),
    ];
    draft.loadingRoles = false;
  });

const deleteRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingRoles = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.roles = draft.roles.filter(role => role.uuid !== action.role.uuid);
    draft.loadingRoles = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ROLES_LIST]: list,
  [Types.ROLES_CREATE_REQUEST]: createRequest,
  [Types.ROLES_CREATE_SUCCESS]: createSuccess,
  [Types.ROLES_FAILURE]: rolesFailure,
  [Types.ROLES_SHOW_REQUEST]: showRequest,
  [Types.ROLES_SHOW_SUCCESS]: showSuccess,
  [Types.ROLES_CLEAR]: toClear,
  [Types.ROLES_UPDATE_REQUEST]: updateRequest,
  [Types.ROLES_UPDATE_SUCCESS]: updateSuccess,
  [Types.ROLES_DELETE_REQUEST]: deleteRequest,
  [Types.ROLES_DELETE_SUCCESS]: deleteSuccess,
});
