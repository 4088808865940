import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { MdModeEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Creators as RolesCreators } from '~/store/ducks/roles';
import { Creators as RolesAccessGroupsCreators } from '~/store/ducks/rolesAccessGroups';
import { Creators as PermissionsAccessGroupsCreators } from '~/store/ducks/permissionsAccessGroups';

import Header from '~/components/Header';

import { Container, Content, TableContainer, ButtonContent } from './styles';

const Roles = () => {
  const application = useSelector(state => state.applications.application);
  const roles = useSelector(state => state.roles.roles);

  const dispatch = useDispatch();
  const history = useHistory();

  function handleEditRole(role, { actionButton }) {
    dispatch(RolesCreators.rolesShowRequest(role.uuid, actionButton, history));
  }

  function handleRemoveRole(role) {
    dispatch(RolesCreators.rolesDeleteRequest(role));
  }

  useEffect(() => {
    dispatch(RolesCreators.rolesClear());
    dispatch(RolesAccessGroupsCreators.rolesAccessGroupsClear());
    dispatch(PermissionsAccessGroupsCreators.permissionsAccessGroupsClear());
  }, [dispatch]);

  useEffect(() => {
    if (!application.uuid) history.push('/applications');
  }, [application, history]);

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="topLine">
            <Link to="/roles/form">
              <ButtonContent type="button">Criar um novo Papel</ButtonContent>
            </Link>
          </div>

          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th>Papel</th>
                  <th>Chave</th>
                  <th>Aplicação</th>
                  <th className="action">Ações</th>
                </tr>
              </thead>

              <tbody>
                {roles.map(role => (
                  <tr key={role.id}>
                    <td>{role.name}</td>
                    <td> {role.role_key} </td>
                    <td>{role.application_key}</td>
                    <td className="action">
                      <MdRemoveRedEye
                        className="iconToView"
                        size={20}
                        onClick={() =>
                          handleEditRole(role, {
                            actionButton: 'toView',
                          })
                        }
                      />

                      <MdModeEdit
                        className="iconToEdit"
                        onClick={() =>
                          handleEditRole(role, {
                            actionButton: 'toEdit',
                          })
                        }
                        size={20}
                      />

                      <MdDelete
                        className="iconDelete"
                        size={20}
                        onClick={() => handleRemoveRole(role)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Content>
      </Container>
    </>
  );
};

export default Roles;
