import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form as Unform } from '@unform/web';

import { FiAlertCircle } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Creators as PermissionsCreators } from '~/store/ducks/permissions';
import { Creators as PermissionsAccessGroupsCreators } from '~/store/ducks/permissionsAccessGroups';

import Header from '~/components/Header';
import Input from '~/components/Input';
import ButtonIn from '~/components/ButtonIn';
import Radio from '~/components/Radio';

import {
  Container,
  Data,
  InputContent,
  ButtonContent,
  TitlePermission,
  ContentRadio,
  Line,
  Error,
  Card,
  TableContainer,
  UnlinkButton,
  Content,
} from './styles';

const Form = () => {
  const application = useSelector(state => state.applications.application);
  const permission = useSelector(state => state.permissions.permission);
  const actionButton = useSelector(state => state.permissions.actionButton);
  const errPermissions = useSelector(state => state.permissions.errPermissions);
  const permissionsAccessGroups = useSelector(
    state => state.permissionsAccessGroups.permissionsAccessGroups
  );
  const contractPermissions = useSelector(
    state => state.contractPermissions.contractPermissions
  );

  const [inputRoute, setInputRoute] = useState('');
  const [method, setMethod] = useState('');
  const [level, setLevel] = useState('');
  const [cardAccessGroup, setCardAccessGroup] = useState(false);
  const [cardContract, setCardContract] = useState(false);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleUpdate(data) {
    const updatePermission = {
      ...data,
      level,
      method,
      application_key: application.application_key,
    };

    dispatch(
      PermissionsCreators.permissionsUpdateRequest(
        permission.uuid,
        updatePermission,
        history
      )
    );
  }

  function handleSubmit(data) {
    const newPermission = {
      ...data,
      level,
      method,
      application_key: application.application_key,
    };

    dispatch(
      PermissionsCreators.permissionsCreateRequest(newPermission, history)
    );
  }

  function handleUnlinkAccessGroup(accessGroup) {
    dispatch(
      PermissionsAccessGroupsCreators.permissionsAccessGroupsDeleteRequest(
        accessGroup
      )
    );
  }

  useEffect(() => {
    if (permission.uuid) {
      setLevel(permission.level.toString());
      setMethod(permission.method);
      setInputRoute(permission.route);
      formRef.current.setData(permission);
    }
  }, [permission]);

  useEffect(() => {
    formRef.current.setErrors({});
    if (errPermissions) {
      formRef.current.setErrors(errPermissions);
    }
  }, [errPermissions]);

  useEffect(() => {
    if (!application.uuid) history.goBack('/');
  }, [application, history]);

  return (
    <>
      <Header />
      <Container>
        {permission.uuid && (
          <Content>
            {level === '4' && (
              <Card>
                <button
                  type="button"
                  onClick={() => {
                    if (cardContract) {
                      setCardContract(false);
                    } else {
                      setCardContract(true);
                    }
                  }}
                >
                  <strong>Contratos</strong>
                  <MdKeyboardArrowDown size={30} />
                </button>

                {cardContract ? (
                  <>
                    <TableContainer isLength={contractPermissions.length > 1}>
                      {contractPermissions.length ? (
                        <div className="table-wrapper">
                          <table>
                            <thead>
                              <tr>
                                <th>Contrato</th>
                                <th>expiração</th>
                              </tr>
                            </thead>

                            <tbody>
                              {contractPermissions.map(p => (
                                <tr key={p.uuid}>
                                  <td>{p.contract_key}</td>
                                  <td>{p.contract?.expires_in.slice(0, 10)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>A permissão não tem vínculo com os contratos.</p>
                      )}
                    </TableContainer>
                  </>
                ) : null}
              </Card>
            )}

            <Card>
              <button
                type="button"
                onClick={() => {
                  if (cardAccessGroup) {
                    setCardAccessGroup(false);
                  } else {
                    setCardAccessGroup(true);
                  }
                }}
              >
                <strong>Grupos de Acceso</strong>
                <MdKeyboardArrowDown size={30} />
              </button>

              {cardAccessGroup ? (
                <>
                  <TableContainer isLength={permissionsAccessGroups.length > 1}>
                    {permissionsAccessGroups.length ? (
                      <div className="table-wrapper">
                        <table>
                          <thead>
                            <tr>
                              <th>Contrato</th>
                              <th>Nome</th>
                              <th>Ações</th>
                            </tr>
                          </thead>

                          <tbody>
                            {permissionsAccessGroups.map(p => (
                              <tr key={p.uuid}>
                                <td>{p.access_group.contract_key}</td>
                                <td>{p.access_group.name}</td>
                                <td>
                                  <UnlinkButton
                                    type="button"
                                    onClick={() => handleUnlinkAccessGroup(p)}
                                  >
                                    Desvincular
                                  </UnlinkButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>
                        A permissão não tem vínculo com os grupos de accesso.
                      </p>
                    )}
                  </TableContainer>
                </>
              ) : null}
            </Card>
          </Content>
        )}
        <Unform
          ref={formRef}
          onSubmit={permission.uuid ? handleUpdate : handleSubmit}
        >
          <Data>
            <div className="topLine">
              <h1>{permission.uuid ? 'Dados da ' : 'Cadastro de '}Permissão</h1>
              <TitlePermission>
                <strong>Chave da permissão: </strong>

                <p>{`${application.application_key}.${inputRoute}.${method}`}</p>
              </TitlePermission>
            </div>
            <ContentRadio>
              <div>
                <strong>Nível: </strong>

                <Radio
                  type="radio"
                  name="level"
                  value="1"
                  label="Administrador"
                  onChange={e => setLevel(e.target.value)}
                  checked={level === '1'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="level"
                  value="2"
                  label="Gerente"
                  onChange={e => setLevel(e.target.value)}
                  checked={level === '2'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="level"
                  value="3"
                  label="Colaborador"
                  onChange={e => setLevel(e.target.value)}
                  checked={level === '3'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="level"
                  value="4"
                  label="Subprodutos"
                  onChange={e => setLevel(e.target.value)}
                  checked={level === '4'}
                  disabled={actionButton === 'toView'}
                />

                {errPermissions.level && (
                  <Error title={errPermissions.level}>
                    <FiAlertCircle size={20} color="#c53030" />
                  </Error>
                )}
              </div>

              <div>
                <strong>Método: </strong>

                <Radio
                  type="radio"
                  name="method"
                  value="post"
                  label="Criar"
                  onChange={e => setMethod(e.target.value)}
                  checked={method === 'post'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="method"
                  value="get"
                  label="Listar"
                  onChange={e => setMethod(e.target.value)}
                  checked={method === 'get'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="method"
                  value="put"
                  label="Alterar"
                  onChange={e => setMethod(e.target.value)}
                  checked={method === 'put'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="method"
                  value="delete"
                  label="Deletar"
                  onChange={e => setMethod(e.target.value)}
                  checked={method === 'delete'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="method"
                  value="patch"
                  label="Parte"
                  onChange={e => setMethod(e.target.value)}
                  checked={method === 'patch'}
                  disabled={actionButton === 'toView'}
                />

                <Radio
                  type="radio"
                  name="method"
                  value="all"
                  label="Todos"
                  onChange={e => setMethod(e.target.value)}
                  checked={method === 'all'}
                  disabled={actionButton === 'toView'}
                />

                {errPermissions.method && (
                  <Error title={errPermissions.method}>
                    <FiAlertCircle size={20} color="#c53030" />
                  </Error>
                )}
              </div>
            </ContentRadio>
            <Line>
              <InputContent>
                <strong>Rota: </strong>

                <Input
                  name="route"
                  placeholder="Rota"
                  onChange={e => setInputRoute(e.target.value)}
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
              <InputContent>
                <strong>Ação: </strong>

                <Input
                  name="action"
                  placeholder="Ação"
                  disabled={actionButton === 'toView'}
                />
              </InputContent>
            </Line>

            <ButtonContent>
              {actionButton === 'toView' ? null : (
                <ButtonIn type="submit">
                  {permission.uuid ? 'Salvar' : 'Cadastrar'}
                </ButtonIn>
              )}
            </ButtonContent>
          </Data>
        </Unform>
      </Container>
    </>
  );
};
export default Form;
