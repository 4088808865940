import { call, put } from 'redux-saga/effects';

import { Creators as RolesCreators } from '../ducks/roles';
import { Creators as RolesAccessGroupsCreators } from '../ducks/rolesAccessGroups';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';
import validateRoles from '~/validators/roles';

export function* create({ role, history }) {
  try {
    yield validateRoles(role);
    const response = yield call(api.post, 'roles', role);

    const newRole = response.data;
    yield put(RolesCreators.rolesCreateSuccess(newRole));
    toastSuccess(`Papel criado com sucesso.`);
    history.goBack('/');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(RolesCreators.rolesFailure(errors));
  }
}

export function* showById({ uuid, history }) {
  try {
    const response = yield call(api.get, `roles/${uuid}`);

    const { role_access_groups, ...role } = response.data;

    yield put(RolesCreators.rolesShowSuccess(role));
    yield put(
      RolesAccessGroupsCreators.rolesAccessGroupsList(role_access_groups)
    );
    history.push('/roles/form');
  } catch (err) {
    errorHandler(err, true);
    yield put(RolesCreators.rolesFailure());
  }
}

export function* update({ uuid, role, history }) {
  try {
    yield validateRoles(role);

    const response = yield call(api.put, `roles/${uuid}`, role);

    const updateRole = response.data;
    yield put(RolesCreators.rolesUpdateSuccess(updateRole));
    toastSuccess('Papel salvo com sucesso!');
    history.goBack('/');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(RolesCreators.rolesFailure(errors));
  }
}

export function* remove({ role }) {
  try {
    yield call(api.delete, `roles/${role.uuid}`);

    yield put(RolesCreators.rolesDeleteSuccess(role));
    toastSuccess(`Papel ${role.name} deletado.`);
  } catch (err) {
    errorHandler(err, true);
    yield put(RolesCreators.rolesFailure({}));
  }
}
