import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import { Container } from './styles';

const SmallModal = ({ title, body, ...rest }) => {
  return (
    <Container>
      <Modal
        {...rest}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{title}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{body}</div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default SmallModal;

SmallModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
