/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  statesGetAllRequest: [],
  statesGetAllSuccess: ['states'],
  statesGetAllFailure: ['errStates'],
  statesToClear: [],
  statesGetAllCitiesRequest: ['id'],
  statesGetAllCitiesSuccess: ['cities'],
  statesFailure: ['errCity'],
});

export const INITIAL_STATE = {
  states: [],
  cities: [],
  id: '',
  loadingStates: false,
  errStates: {},
};

const getAllRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingStates = true;
  });

const getAllSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.states = action.states;
    draft.loadingStates = false;
  });

const getAllFailure = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingStates = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.states = [];
    draft.cities = [];
    draft.state = {};
    draft.loadingStates = false;
  });

const getAllCitiesRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingStates = true;
  });

const getAllCitiesSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.cities = action.cities;
    draft.loadingStates = false;
  });

const stateFailure = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingStates = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.STATES_GET_ALL_REQUEST]: getAllRequest,
  [Types.STATES_GET_ALL_SUCCESS]: getAllSuccess,
  [Types.STATES_GET_ALL_FAILURE]: getAllFailure,
  [Types.STATES_TO_CLEAR]: toClear,
  [Types.STATES_GET_ALL_CITIES_REQUEST]: getAllCitiesRequest,
  [Types.STATES_GET_ALL_CITIES_SUCCESS]: getAllCitiesSuccess,
  [Types.STATES_FAILURE]: stateFailure,
});
