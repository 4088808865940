import * as Yup from 'yup';

export function getValidationErrorsYup(err) {
  if (err instanceof Yup.ValidationError) {
    const validationErrors = {};

    err.inner.forEach(error => {
      validationErrors[error.path] = error.message;
    });

    return validationErrors;
  }

  return null;
}

export function getValidationErrorsApi(err) {
  const validationErrors = {};

  err.validations.forEach(error => {
    validationErrors[error.field] = error.message;
  });

  return validationErrors;
}
