/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  permissionsAccessGroupsList: ['permissionsAccessGroups'],
  permissionsAccessGroupsDeleteRequest: ['accessGroup'],
  permissionsAccessGroupsDeleteSuccess: ['accessGroup'],
  permissionsAccessGroupsFailure: ['accessGroup'],
  permissionsAccessGroupsClear: [],
});

export const INITIAL_STATE = {
  permissionsAccessGroups: [],
  loadingPermissionsAccessGroups: false,
  errPermissionsAccessGroup: {},
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permissionsAccessGroups = action.permissionsAccessGroups;
    draft.loadingpermissionsAccessGroups = false;
  });

const removeRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingpermissionsAccessGroups = true;
  });

const removeSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permissionsAccessGroups = [
      ...draft.permissionsAccessGroups.filter(
        permissionAccessGroup =>
          permissionAccessGroup.uuid !== action.accessGroup.uuid
      ),
    ];
    draft.loadingpermissionsAccessGroups = false;
  });

const failure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errPermissionsAccessGroup = action.errPermissionsAccessGroup;
    draft.loadingpermissionsAccessGroups = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.permissionsAccessGroups = [];
    draft.errPermissionsAccessGroup = {};
    draft.loadingpermissionsAccessGroups = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PERMISSIONS_ACCESS_GROUPS_LIST]: list,
  [Types.PERMISSIONS_ACCESS_GROUPS_DELETE_REQUEST]: removeRequest,
  [Types.PERMISSIONS_ACCESS_GROUPS_DELETE_SUCCESS]: removeSuccess,
  [Types.PERMISSIONS_ACCESS_GROUPS_FAILURE]: failure,
  [Types.PERMISSIONS_ACCESS_GROUPS_CLEAR]: toClear,
});
