import * as Yup from 'yup';

export default async function (data) {
  const schemaValid = Yup.object().shape({
    manager_email: Yup.string()
      .email('O formato do campo é e-mail')
      .required('Este campo é obrigatório'),
    application_key: Yup.string().required('Este campo é obrigatório'),
    company_key: Yup.string().required('Este campo é obrigatório'),
    branch_key: Yup.string().required('Este campo é obrigatório'),
    expires_in: Yup.string().required('Este campo é obrigatório'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
