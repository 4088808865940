import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Tooltip from '~/components/Tooltip';

export const Styles = styled.section`
  .row {
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .login-box {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryFontColor};
    border-radius: 3%;
    padding: 10% 5%;
  }
  .title {
    padding: 5%;
  }
  .subtitle {
    padding-top: 2%;
  }
  .input-group {
    padding: 8% 5% 8% 2%;
  }

  .input-group-prepend {
    padding-top: 3%;
    margin-right: 1%;
  }
  .btn {
    margin-bottom: 5%;
  }
`;

export const Form = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    margin-top: 30px;
    height: 50px;
    border: 1px solid #fff;
    background: #1f7ecc;
    color: #fff;
    transition: background 0.2s;
    border-radius: 8px;

    &:hover {
      background: ${shade(0.2, '#1f7ecc')};
    }
  }
`;
export const ContentInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #c4c4c4;

  ${props =>
    props.isFocused &&
    css`
      border-color: #fff;
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

    svg {
    margin-right: 12px;
  }

  input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: 0;
    color: #ffff;

    &::placeholder {
      color: ${shade(0.2, '#1f7ecc')};
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 12px;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    text-align: center;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
