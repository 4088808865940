import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MdModeEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Creators as ContractsCreators } from '~/store/ducks/contracts';
import { Creators as ContractPermissionsCreators } from '~/store/ducks/contractPermissions';

import Table from '~/components/Table';
import Settings from '../Settings';
import LoadingComponent from '~/components/LoadingComponent';

const Contracts = () => {
  const contractRegisters = useSelector(state => state.contracts.contracts);
  const { loadingContracts } = useSelector(state => state.contracts);
  const userCompany = useSelector(state => state.usersCompanies.userCompany);
  const userAuth = useSelector(state => state.auth.user);

  const [addAccessLink, setAddAccessLink] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);

  function handleEditContract(isContract, { actionButton }) {
    dispatch(
      ContractsCreators.contractsShowRequest(
        isContract.uuid,
        actionButton,
        history
      )
    );
  }

  function handleDeleteContract(isContract) {
    dispatch(ContractsCreators.contractsDeleteRequest(isContract));
  }

  const header = [
    {
      key: 'contract_key',
      name: 'Contrato',
    },
    {
      key: 'application.name',
      name: 'Aplicação',
    },
    {
      key: 'company.name',
      name: 'Empresa',
    },
  ];

  const filterBy = [
    {
      key: 'contract_key',
      name: 'Contrato',
      type: 'text',
      options: [],
    },
    {
      key: 'application.name',
      name: 'Aplicação',
      type: 'text',
      options: [],
    },
    {
      key: 'company.name',
      name: 'Empresa',
      type: 'text',
      options: [],
    },
    {
      key: 'status',
      name: 'Status',
      type: 'selector',
      options: [
        {
          key: '1',
          name: 'Ativo',
        },
        {
          key: '2',
          name: 'Bloqueado',
        },
        {
          key: '3',
          name: 'Cancelado',
        },
      ],
    },
    {
      key: 'expires_in',
      name: 'Expira em',
      type: 'selector',
      options: [
        {
          key: '<',
          name: 'Vencidos',
        },
        {
          key: '>=',
          name: 'Não Vencidos',
        },
      ],
      customFilter: search => {
        if (search === '<') {
          return contractRegisters.filter(
            register => new Date(register.expires_in) < new Date()
          );
        } else {
          return contractRegisters.filter(
            register => new Date(register.expires_in) >= new Date()
          );
        }
      },
    },
  ];

  const bodyActions = [
    {
      key: 'show',
      action: contract => {
        return (
          <MdRemoveRedEye
            className="iconToView"
            size={20}
            onClick={() =>
              handleEditContract(contract, {
                actionButton: 'toView',
              })
            }
          />
        );
      },
      canSee: true,
    },
    {
      key: 'edit',
      action: contract => {
        return (
          <MdModeEdit
            className="iconToEdit"
            size={20}
            onClick={() =>
              handleEditContract(contract, {
                actionButton: 'toEdit',
              })
            }
          />
        );
      },
      canSee: userAuth.is_admin,
    },
    {
      key: 'delete',
      action: contract => {
        return (
          <MdDelete
            className="iconDelete"
            size={20}
            onClick={() => handleDeleteContract(contract)}
          />
        );
      },
      canSee: userAuth.is_admin,
    },
  ];

  const headerActions = [
    {
      text: 'Criar um novo contrato',
      action: () => {
        history.push('/contracts/form');
      },
      canSee: userAuth.is_admin,
    },
  ];

  useEffect(() => {
    dispatch(ContractsCreators.contractsGetAllRequest());
    dispatch(ContractsCreators.contractsClear());
    dispatch(ContractPermissionsCreators.contractPermissionsClear(true));
  }, [dispatch]);

  useEffect(() => {
    if (userCompany.uuid) {
      setAddAccessLink(false);
    }

    if (!addAccessLink && formRef.current) {
      dispatch(ContractsCreators.usersCompaniesClear());
    }
  }, [userCompany, addAccessLink, dispatch]);

  useEffect(() => {
    if (!pageLoaded && contractRegisters.length) {
      setPageLoaded(true);
    }
  }, [pageLoaded, contractRegisters]);

  return (
    <Settings>
      {pageLoaded ? (
        <Table
          registers={contractRegisters}
          filterBy={filterBy}
          header={header}
          headerActions={headerActions}
          bodyActions={bodyActions}
          loading={loadingContracts}
        />
      ) : (
        <LoadingComponent />
      )}
    </Settings>
  );
};

export default Contracts;
