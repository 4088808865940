export default function (image) {
  if (!image) return new Promise(resolve => resolve());
  const reader = new FileReader();

  reader.readAsDataURL(image);

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onabort = () => resolve();
    reader.onerror = () => reject(reader.error);
  });
}
