/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  usersAccessGroupsList: ['usersAccessGroups'],
  usersAccessGroupsDeleteRequest: ['accessGroup'],
  usersAccessGroupsDeleteSuccess: ['accessGroup'],
  usersAccessGroupsFailure: ['accessGroup'],
  usersAccessGroupsClear: [],
});

export const INITIAL_STATE = {
  usersAccessGroups: [],
  loadingUsersAccessGroups: false,
  errUsersAccessGroup: {},
};

const usersAccessGroupsList = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.usersAccessGroups = action.usersAccessGroups;
    draft.loadingUsersAccessGroups = false;
  });

const deleteRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingUsersAccessGroups = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.usersAccessGroups = [
      ...draft.usersAccessGroups.filter(
        userAccessGroup => userAccessGroup.uuid !== action.accessGroup.uuid
      ),
    ];
    draft.loadingUsersAccessGroups = false;
  });

const failure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errUsersAccessGroup = action.errUsersAccessGroup;
    draft.loadingUsersAccessGroups = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.usersAccessGroups = [];
    draft.errUsersAccessGroup = {};
    draft.loadingUsersAccessGroups = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USERS_ACCESS_GROUPS_LIST]: usersAccessGroupsList,
  [Types.USERS_ACCESS_GROUPS_DELETE_REQUEST]: deleteRequest,
  [Types.USERS_ACCESS_GROUPS_DELETE_SUCCESS]: deleteSuccess,
  [Types.USERS_ACCESS_GROUPS_FAILURE]: failure,
  [Types.USERS_ACCESS_GROUPS_CLEAR]: toClear,
});
