/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  companiesContractsList: ['companiesContracts'],
  companiesContractsDeleteRequest: ['contract'],
  companiesContractsDeleteSuccess: ['contract'],
  companiesContractsFailure: ['contract'],
  companiesContractsClear: [],
});

export const INITIAL_STATE = {
  companiesContracts: [],
  loadingCompaniesContracts: false,
  errCompaniesContracts: {},
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.companiesContracts = action.companiesContracts;
    draft.loadingCompaniesContracts = false;
  });

const removeRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingCompaniesContracts = true;
  });

const removeSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.companiesContracts = [
      ...draft.companiesContracts.filter(
        companyContract => companyContract.uuid !== action.contract.uuid
      ),
    ];
    draft.loadingCompaniesContracts = false;
  });

const failure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errCompaniesContracts = action.errCompaniesContracts;
    draft.loadingCompaniesContracts = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.companiesContracts = [];
    draft.errCompaniesContracts = {};
    draft.loadingCompaniesContracts = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.COMPANIES_CONTRACTS_LIST]: list,
  [Types.COMPANIES_CONTRACTS_DELETE_REQUEST]: removeRequest,
  [Types.COMPANIES_CONTRACTS_DELETE_SUCCESS]: removeSuccess,
  [Types.COMPANIES_CONTRACTS_FAILURE]: failure,
  [Types.COMPANIES_CONTRACTS_CLEAR]: toClear,
});
