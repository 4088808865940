import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.section`
  height: 42px;

  .navbar {
    padding: 0% 1%;
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryFontColor};
    a {
      color: ${props => props.theme.primaryFontColor};
    }
    .navbar-brand {
      color: #fff;
      font-size: ${props => props.theme.xl};
    }
  }

  .home {
    align-items: center;
    padding-left: 2%;
  }

  .notification-settings {
    padding: 0% 1%;
    margin-right: 1%;
    white-space: nowrap;
    border-right: 2px solid #fff;
  }
  .user-panel {
    display: flex;
    flex-direction: column;
    margin-right: 1%;
  }

  .d-none {
    background: #fff;
    border-radius: 50%;
    padding: 2px;
    > img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: contain;
    }
  }
`;

export const ComeBack = styled.div``;

export const ButtonSignOut = styled.button`
  border: 0;
  background: #c53030;
  color: #f2f2f2;
  font-weight: bold;
  border-radius: 4px;
  padding: 4px;
  width: 90px;
  margin: 4px;
  transition: background-color 0.2s;

  :hover {
    background: ${shade(0.2, '#c53030')};
  }
`;

export const ButtonCompany = styled.button`
  border: 0;
  background: #fff;
  color: #1f7ecc;
  font-weight: bold;
  border-radius: 4px;
  padding: 4px;
  margin: 4px;
  transition: background-color 0.2s;

  :hover {
    background: ${shade(0.2, '#fff')};
  }
`;
