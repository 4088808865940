import { call, put } from 'redux-saga/effects';

import { Creators as ApplicationsCreators } from '../ducks/applications';
import { Creators as PermissionsCreators } from '../ducks/permissions';
import { Creators as RolesCreators } from '../ducks/roles';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';
import validateApplications from '~/validators/applications';

export function* getAllAplications() {
  try {
    const response = yield call(api.get, 'applications');
    const applicationsList = response.data;

    yield put(ApplicationsCreators.applicationsGetAllSuccess(applicationsList));
  } catch (err) {
    errorHandler(err);
    yield put(ApplicationsCreators.applicationsGetAllFailure());
  }
}

export function* createAplication({ application, history }) {
  try {
    yield validateApplications(application);

    yield call(api.post, 'applications', application);

    yield put(ApplicationsCreators.applicationsSuccess());
    toastSuccess(`Aplicação ${application.name} criada com sucesso.`);
    history.goBack('/aplications');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(ApplicationsCreators.applicationsUpdateFailure(errors));
  }
}

export function* deleteAplication({ application }) {
  try {
    yield call(api.delete, `applications/${application.uuid}`);

    toastSuccess(`Aplicação ${application.name} deletada.`);
    yield put(ApplicationsCreators.applicationsDeleteSuccess(application));
  } catch (err) {
    errorHandler(err, true);
    yield put(ApplicationsCreators.applicationsGetAllFailure());
  }
}

export function* showAplication({ uuid, history }) {
  try {
    const response = yield call(api.get, `applications/${uuid}`);

    const { roles, permissions, ...application } = response.data;

    yield put(ApplicationsCreators.applicationsShowSuccess(application));
    yield put(PermissionsCreators.permissionsList(permissions));
    yield put(RolesCreators.rolesList(roles));
  } catch (err) {
    errorHandler(err, true);
    yield put(ApplicationsCreators.applicationsGetAllFailure());
    history.goBack();
  }
}

export function* upDateAplication({ application, uuid, history }) {
  try {
    yield validateApplications(application);

    yield call(api.put, `applications/${uuid}`, application);

    yield put(ApplicationsCreators.applicationsSuccess());
    toastSuccess(`Aplicação salva com sucesso!`);
    history.goBack();
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(ApplicationsCreators.applicationsUpdateFailure(errors));
  }
}
