import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

import { LoadingContainer } from './styles';

function LoadingComponent() {
  return (
    <LoadingContainer>
      <Spinner animation="border" role="status" />
    </LoadingContainer>
  );
}

export default LoadingComponent;
