import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

const RouteWrapper = ({
  isPrivate,
  isAdmin,
  isManager,
  company,
  component: Component,
  ...rest
}) => {
  const firstSigned = useSelector(state => state.auth.firstSigned);
  const signed = useSelector(state => state.auth.signed);
  const userAuth = useSelector(state => state.auth.user);

  if (!signed && firstSigned && isPrivate) {
    return <Redirect to="/company" />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (!firstSigned && company) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate && !company) {
    return <Redirect to="/dashboard" />;
  }

  if (!userAuth.is_admin && isPrivate && isAdmin) {
    return <Redirect to="/" />;
  }

  if (!userAuth.is_manager && !userAuth.is_admin && isPrivate && isManager) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default RouteWrapper;

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isManager: PropTypes.bool,
  company: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAdmin: false,
  isManager: false,
  company: false,
};
