import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import { FiUser, FiKey, FiChevronRight, FiAlertCircle } from 'react-icons/fi';

import { Creators as AuthCreators } from '~/store/ducks/auth';

import { Typography } from '~/components/Typography';

import { Styles, Form, ContentInput, ContentPassword, Error } from './styles';

const SignIn = () => {
  const loadingAuth = useSelector(state => state.auth.loadingAuth);
  const errAuth = useSelector(state => state.auth.errAuth);

  const [isFocusedEmail, setIsFocusedEmail] = useState(false);
  const [isFocusedPassword, setIsFocusedPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    dispatch(AuthCreators.signOut());
  }, [dispatch]);

  function handleContinue(e) {
    e.preventDefault();
    dispatch(AuthCreators.firstSignInRequest(email, password, history));
  }

  return (
    <Container fluid>
      <Styles>
        <Row>
          <Col sm={6} md={5} lg={4} xl={3}>
            <div className="login-box">
              <Typography bold size="xxl" color="primary">
                C O D E
              </Typography>
              <Form onSubmit={handleContinue}>
                <ContentInput
                  isFocused={isFocusedEmail}
                  isErrored={!!errAuth.email}
                >
                  <FiUser size={16} />
                  <input
                    onFocus={() => setIsFocusedEmail(true)}
                    onBlur={() => setIsFocusedEmail(false)}
                    name="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  {errAuth.email && (
                    <Error title={errAuth.email}>
                      <FiAlertCircle size={16} color="#c53030" />
                    </Error>
                  )}
                </ContentInput>
                <ContentPassword
                  isFocused={isFocusedPassword}
                  isErrored={!!errAuth.password}
                >
                  <FiKey size={16} />
                  <input
                    onFocus={() => setIsFocusedPassword(true)}
                    onBlur={() => setIsFocusedPassword(false)}
                    name="password"
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  {errAuth.password && (
                    <Error title={errAuth.password}>
                      <FiAlertCircle size={16} color="#c53030" />
                    </Error>
                  )}
                </ContentPassword>

                <button type="submit">
                  {loadingAuth ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    'Continuar'
                  )}
                </button>
              </Form>

              <a href="/recover">
                Esqueci minha senha <FiChevronRight size={12} />
              </a>
            </div>
          </Col>
        </Row>
      </Styles>
    </Container>
  );
};

export default SignIn;
