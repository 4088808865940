import * as Yup from 'yup';

/* const ValidateRegisterNumber = register_number => {
  let weights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let sum = 0;
  for (let i = 0; i < weights.length; i += 1) {
    sum += Number(register_number[i], 10) * weights[i];
  }

  let remainder = sum % 11;
  let firstDigit;
  if (remainder < 2) firstDigit = 0;
  else firstDigit = 11 - remainder;

  weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  sum = 0;
  for (let i = 0; i < weights.length; i += 1) {
    sum += Number(register_number[i]) * weights[i];
  }
  remainder = sum % 11;

  let secondDigit;
  if (remainder < 2) secondDigit = 0;
  else secondDigit = 11 - remainder;

  if (
    !(
      firstDigit === Number(register_number[12]) &&
      secondDigit === Number(register_number[13])
    )
  ) {
    return false;
  }

  return true;
};
 */

export default async function (data) {
  const schemaValid = Yup.object().shape({
    register_number: Yup.string()
      // .test('cnpj', 'CNPJ não existe.', ValidateRegisterNumber)
      // .min(8, 'O campo só permite 8 digitos.')
      .required('Este campo é obrigatório.'),
    first_name: Yup.string().required('Este campo é obrigatório.'),
    last_name: Yup.string().required('Este campo é obrigatório.'),
    email: Yup.string()
      .email('O formato do campo é E-mail.')
      .required('Este campo é obrigatório.'),
    ddd1: Yup.string().required('Este campo é obrigatório.'),
    telephone1: Yup.string().required('Este campo é obrigatório.'),
    zip_code: Yup.string()
      .min(8, 'O campo só permite 8 digitos.')
      .required('Este campo é obrigatório.'),
    state_id: Yup.string().required('Este campo é obrigatório.'),
    city_id: Yup.string().required('Este campo é obrigatório.'),
    neighborhood: Yup.string().required('Este campo é obrigatório.'),
    address: Yup.string().required('Este campo é obrigatório.'),
  });
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
