import { call, put } from 'redux-saga/effects';

import { Creators as BranchesCreators } from '../ducks/branches';
import { Creators as BranchesAccessGroupsCreators } from '../ducks/branchesAccessGroups';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';
import validateBranches from '~/validators/branches';

export function* createBranch({ branch, history }) {
  try {
    yield validateBranches(branch);

    const response = yield call(api.post, 'branches', branch);

    const branchCreate = response.data;

    toastSuccess(`Filial ${branch.last_name} criada com sucesso.`);
    yield put(BranchesCreators.branchesCreateSuccess(branchCreate));

    history.goBack('/branches');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(BranchesCreators.branchesCreateFailure(errors));
  }
}

export function* deleteBranch({ branch }) {
  try {
    yield call(api.delete, `branches/${branch.uuid}`);

    yield put(BranchesCreators.branchesDeleteSuccess(branch));

    toastSuccess(`Filial ${branch.last_name} deletada.`);
  } catch (err) {
    errorHandler(err, true);
    yield put(BranchesCreators.branchesFailure());
  }
}

export function* showBranch({ uuid }) {
  try {
    const response = yield call(api.get, `branches/${uuid}`);

    const { branch_access_groups, ...branch } = response.data;

    yield put(BranchesCreators.branchesShowSuccess(branch));
    yield put(
      BranchesAccessGroupsCreators.branchesAccessGroupsList(
        branch_access_groups
      )
    );
  } catch (err) {
    errorHandler(err, true);
    yield put(BranchesCreators.branchesFailure());
  }
}

export function* upDateBranch({ branch, uuid, history }) {
  try {
    yield validateBranches(branch);

    const response = yield call(api.put, `branches/${uuid}`, branch);

    const branchUpdate = response.data;

    yield put(BranchesCreators.branchesUpdateSuccess(branchUpdate));
    toastSuccess(`Filial salva com sucesso!`);
    history.goBack('/branches');
  } catch (err) {
    const errors = errorHandler(err, false);
    yield put(BranchesCreators.branchesUpdateFailure(errors));
  }
}
