import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;

  > p {
    margin-left: 3px;
    text-align: center;
    justify-content: center;
    margin-bottom: 0;
  }
`;
