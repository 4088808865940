/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  permissionsList: ['permissions'],
  permissionsCreateRequest: ['permission', 'history'],
  permissionsCreateSuccess: ['permission'],
  permissionsSuccess: [],
  permissionsFailure: ['errPermissions'],
  permissionsShowRequest: ['uuid', 'actionButton', 'history'],
  permissionsShowSuccess: ['permission'],
  permissionsClear: ['signOut'],
  permissionsUpdateRequest: ['uuid', 'permission', 'history'],
  permissionsUpdateSuccess: ['permission'],
  permissionsDeleteRequest: ['permission'],
  permissionsDeleteSuccess: ['permission'],
});

export const INITIAL_STATE = {
  permissions: [],
  permission: {},
  actionButton: null,
  loadingPermissions: false,
  errPermissions: {},
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permissions = action.permissions;
    draft.loadingPermissions = false;
  });

const createRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permission = action.permission;
    draft.loadingPermissions = true;
  });

const createSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permissions = [action.permission, ...draft.permissions];
    draft.loadingPermissions = false;
  });

const permissionsSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingPermissions = false;
  });

const permissionsFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errPermissions = action.errPermissions;
    draft.loadingPermissions = false;
  });

const showRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.actionButton = action.actionButton;
    draft.loadingPermissions = true;
  });

const showSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permission = action.permission;
    draft.loadingPermissions = false;
  });

const toClear = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    if (action.signOut) draft.permissions = [];
    draft.permission = {};
    draft.actionButton = null;
    draft.errPermissions = {};
    draft.loadingPermissions = false;
  });

const updateRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingPermissions = true;
  });

const updateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permissions = [
      action.permission,
      ...draft.permissions.filter(
        permission => permission.uuid !== action.permission.uuid
      ),
    ];
    draft.loadingPermissions = false;
  });

const deleteRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loadingPermissions = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.permissions = draft.permissions.filter(
      permission => permission.uuid !== action.permission.uuid
    );
    draft.loadingPermissions = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PERMISSIONS_LIST]: list,
  [Types.PERMISSIONS_CREATE_REQUEST]: createRequest,
  [Types.PERMISSIONS_CREATE_SUCCESS]: createSuccess,
  [Types.PERMISSIONS_SUCCESS]: permissionsSuccess,
  [Types.PERMISSIONS_FAILURE]: permissionsFailure,
  [Types.PERMISSIONS_SHOW_REQUEST]: showRequest,
  [Types.PERMISSIONS_SHOW_SUCCESS]: showSuccess,
  [Types.PERMISSIONS_CLEAR]: toClear,
  [Types.PERMISSIONS_UPDATE_REQUEST]: updateRequest,
  [Types.PERMISSIONS_UPDATE_SUCCESS]: updateSuccess,
  [Types.PERMISSIONS_DELETE_REQUEST]: deleteRequest,
  [Types.PERMISSIONS_DELETE_SUCCESS]: deleteSuccess,
});
