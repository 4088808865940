import * as Yup from 'yup';

export default async function (data) {
  const schemaValid = Yup.object().shape(
    {
      name: Yup.string().required('Este campo é obrigatório'),
      description: Yup.string().required('Este campo é obrigatório'),
      permission_keys: Yup.string().when(
        ['role_keys', 'branch_keys', 'user_keys'],
        {
          is: role_keys => !role_keys,
          then: Yup.string().required(
            'Selecione uma PERMISSÃO ou PAPEL para continuar.'
          ),
        }
      ),
      user_keys: Yup.string().when(
        ['permission_keys', 'role_keys', 'branch_keys'],
        {
          is: (permission_keys, role_keys, branch_keys) =>
            !permission_keys ||
            permission_keys ||
            !role_keys ||
            role_keys ||
            branch_keys,
          then: Yup.string().required('Selecione um USUÁRIO para continuar'),
        }
      ),
      branch_keys: Yup.string().when(
        ['permission_keys', ' role_keys', 'user_keys'],
        {
          is: (permission_keys, role_keys, user_keys) =>
            !permission_keys ||
            permission_keys ||
            !role_keys ||
            role_keys ||
            user_keys,
          then: Yup.string().required('Selecione uma FILIAL para continuar'),
        }
      ),
      role_keys: Yup.string().when(
        ['permission_keys', 'branch_keys', 'user_keys'],
        {
          is: permission_keys => !permission_keys,
          then: Yup.string().required(
            'Selecione uma PERMISSÃO ou PAPEL para continuar.'
          ),
        }
      ),
    },
    [
      ['permission_keys', 'role_keys'],
      ['permission_keys', 'branch_keys'],
      ['permission_keys', 'user_keys'],
      ['role_keys', 'user_keys'],
      ['role_keys', 'branch_keys'],
      ['user_keys', 'branch_keys'],
    ]
  );
  await schemaValid.validate(data, {
    abortEarly: false,
  });
}
