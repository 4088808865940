import styled from 'styled-components';
import { shade } from 'polished';

import Tooltip from '~/components/Tooltip';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;

  margin-top: 40px;

  h1 {
    font-size: 14px;
    text-align: start;
    justify-content: center;
    font-weight: bold;
  }

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;

    > div {
      display: flex;
      align-items: flex-start;
      strong {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #92a6bb;
        text-align: start;
      }
    }

    @media (max-width: 950px) {
      div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-top: 40px;

  > .nav-tabs {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const Tab = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 150px;
  height: 50px;
  background: ${props => (props.checked ? '#e2eaf2' : '#1f7ecc')};
  color: ${props => (props.checked ? '#1f7ecc' : '#fff')};
  color: ${props => props.isErrored && '#c53030'};
  border: 0;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  border-top: ${props => (props.checked ? '3px solid' : '0')};
  border-color: ${props => props.checked && '#1f7ecc'};
  border-color: ${props => props.isErrored && '#c53030'};
  transition: background-color 0.2s ease 0.1s;

  > p {
    margin: 0;
  }

  :hover {
    background: ${props =>
      props.checked ? shade(0.1, '#e2eaf2') : shade(-0.2, '#1f7ecc')};
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e2eaf2;
  width: 70%;
  height: 100%;
`;

export const Data = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background: #fff;
  border: 1px solid #c9d3dd;
  width: 70%;

  h1 {
    font-size: 22px;
    color: #65ade8;
    text-align: center;
  }

  @media (max-width: 950px) {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
export const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center auto;
  justify-content: start;
  width: 100%;
  margin-top: 10px;

  input {
    height: 20px;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 5px 0;
`;

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  > button {
    padding: 15px 20px 15px 20px;
    border: 0;
    background: #1fcc85;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.2s;
    margin-right: 5px;

    :hover {
      background: ${shade(-0.2, '#1fcc85')};
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    text-align: center;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
