import { call, put } from 'redux-saga/effects';
import { Creators as UsersAccessGroupsCreators } from '../ducks/usersAccessGroups';
import api from '~/services/api';

import { toastSuccess } from '~/utils/Toast';
import errorHandler from '~/utils/errorHandler';

export function* deleteUser({ accessGroup }) {
  try {
    yield call(api.delete, `user-access-groups/${accessGroup.uuid}`);

    yield put(
      UsersAccessGroupsCreators.usersAccessGroupsDeleteSuccess(accessGroup)
    );
    toastSuccess('Usuário desvinculado do grupo de accesso!');
  } catch (err) {
    errorHandler(err, true);
    yield put(UsersAccessGroupsCreators.usersAccessGroupsFailure());
  }
}
