/* eslint-disable no-param-reassign */
import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  usersCompaniesList: ['userCompanies'],
  usersCompaniesCreateRequest: ['userCompany'],
  usersCompaniesCreateSuccess: ['userCompany'],
  usersCompaniesCreateFailure: ['errUsersCompanies'],
  usersCompaniesDeleteRequest: ['userCompany'],
  usersCompaniesDeleteSuccess: ['userCompany'],
  usersCompaniesFailure: ['errUsersCompanies'],
  usersCompaniesClear: [],
});

export const INITIAL_STATE = {
  userCompanies: [],
  userCompany: {},
  loadingUsersCompanies: false,
  errUsersCompanies: {},
};

const usersCompaniesList = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.userCompanies = action.userCompanies;
    draft.loadingUsersCompanies = false;
  });

const createRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.userCompany = action.userCompany;
    draft.loadingUsersCompanies = true;
  });

const createSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.userCompany = action.userCompany;
    draft.loadingUsersCompanies = false;
  });

const createFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errUsersCompanies = action.errUsersCompanies;
    draft.loadingUsersCompanies = false;
  });

const deleteRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.userCompany = action.userCompany;
    draft.loadingUsersCompanies = true;
  });

const deleteSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.userCompanies = draft.userCompanies.filter(
      userCompany => userCompany.uuid !== action.userCompany.uuid
    );
    draft.userCompany = action.userCompany;
    draft.loadingUsersCompanies = false;
  });

const usersFailure = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.errUsersCompanies = action.errUsersCompanies;

    draft.loadingUsersCompanies = false;
  });

const toClear = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.userCompany = {};
    draft.errUsersCompanies = {};
    draft.loadingUsersCompanies = false;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USERS_COMPANIES_LIST]: usersCompaniesList,
  [Types.USERS_COMPANIES_CREATE_REQUEST]: createRequest,
  [Types.USERS_COMPANIES_CREATE_SUCCESS]: createSuccess,
  [Types.USERS_COMPANIES_CREATE_FAILURE]: createFailure,
  [Types.USERS_COMPANIES_DELETE_REQUEST]: deleteRequest,
  [Types.USERS_COMPANIES_DELETE_SUCCESS]: deleteSuccess,
  [Types.USERS_COMPANIES_FAILURE]: usersFailure,
  [Types.USERS_COMPANIES_CLEAR]: toClear,
});
