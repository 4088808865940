import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import { FiUser, FiAlertCircle, FiChevronLeft } from 'react-icons/fi';

import { Creators as AuthCreators } from '~/store/ducks/auth';

import { Typography } from '~/components/Typography';

import { Styles, Form, ContentInput, Error } from './styles';

export default function Recover() {
  const loadingAuth = useSelector(state => state.auth.loadingAuth);
  const errAuth = useSelector(state => state.auth.errAuth);

  const [isFocusedEmail, setIsFocusedEmail] = useState(false);
  const [email, setEmail] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthCreators.authToClear());
  }, [dispatch]);

  function handleBackSignIn() {
    history.push('/');
  }

  function handleForgotPassword(e) {
    e.preventDefault();
    dispatch(AuthCreators.authForgotPasswordRequest(email, history));
  }

  return (
    <Container fluid>
      <Styles>
        <Row>
          <Col sm={6} md={5} lg={4} xl={3}>
            <div className="login-box">
              <Typography bold size="xxl" color="primary">
                C O D E
              </Typography>
              <Typography className="title" color="primary" size="sm">
                Esqueci minha Senha
              </Typography>
              <Typography className="subtitle" color="primary" size="xs">
                Confirme seu e-mail para continuar:
              </Typography>
              <Form onSubmit={handleForgotPassword}>
                <ContentInput
                  isFocused={isFocusedEmail}
                  isErrored={!!errAuth.email}
                >
                  <FiUser size={16} />
                  <input
                    onFocus={() => setIsFocusedEmail(true)}
                    onBlur={() => setIsFocusedEmail(false)}
                    name="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  {errAuth.email && (
                    <Error title={errAuth.email}>
                      <FiAlertCircle size={16} color="#c53030" />
                    </Error>
                  )}
                </ContentInput>
                <button type="submit">
                  {loadingAuth ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    'Continuar'
                  )}
                </button>
              </Form>

              <Typography
                thin
                link
                color="primary"
                size="xs"
                onClick={handleBackSignIn}
              >
                <FiChevronLeft size={12} />
                Voltar
              </Typography>
            </div>
          </Col>
        </Row>
      </Styles>
    </Container>
  );
}
